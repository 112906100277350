<app-card
  [className]="'card--summary'"
  [type]="CardType.CONTACT"
  [project]="project"
>
</app-card>

<app-card [className]="'card--summary'" *ngIf="chatEnabled">
  <div class="card--form">
    <div class="card__head">
      <div class="card--summary__title">
        <h3>{{ 'project.home.contact.title' | translate }}</h3>
      </div>
    </div>
    <form [formGroup]="contactForm" (submit)="send()">
      <app-form-group [form]="contactForm" errorPath="name">
        <label class="label" for="name">{{ 'contact.name' | translate }}</label>
        <input
          type="text"
          class="input-control"
          formControlName="name"
          id="name"
          placeholder="{{ 'contact.name.placeholder' | translate }}"
        />
      </app-form-group>

      <app-form-group [form]="contactForm" errorPath="subject">
        <label class="label" for="subject">{{
          'contact.subject' | translate
        }}</label>
        <input
          type="text"
          class="input-control"
          formControlName="subject"
          id="subject"
          placeholder="{{ 'contact.subject.placeholder' | translate }}"
        />
      </app-form-group>

      <app-form-group [form]="contactForm" errorPath="message">
        <label class="label" for="message">{{
          'contact.message' | translate
        }}</label>
        <textarea
          class="input-control"
          rows="7"
          formControlName="message"
          id="message"
          placeholder="{{ 'contact.message.placeholder' | translate }}"
        ></textarea>
      </app-form-group>

      <app-form-group class="file">
        <label class="label">{{ 'contact.attachment' | translate }}</label>
        <app-file-input
          (fileChanged)="setUploadedFile($event)"
        ></app-file-input>
      </app-form-group>

      <button
        type="button"
        (click)="send()"
        [attr.disabled]="loading ? true : null"
      >
        {{ 'contact.submit' | translate }}
        <app-loader [loading]="loading"></app-loader>
      </button>
    </form>
  </div>
</app-card>
