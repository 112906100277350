<app-card
  [className]="'card--conversation'"
  (click)="openConversation(conversation)"
  *ngFor="let conversation of conversations; let index = index"
>
  <div class="card--conversation__meta">
    <span
      >#{{ index + 1 }}:
      {{
        conversation.type === ConversationType.TICKET
          ? conversation.ticket.subject
          : conversation.smartReportingNotice.theme.title
      }}</span
    >
    <span *ngIf="!isToday(conversation.lastInteractionDate)" class="date">{{
      conversation.lastInteractionDate | date : 'd-M-Y'
    }}</span>
    <span *ngIf="isToday(conversation.lastInteractionDate)" class="date">{{
      conversation.lastInteractionDate | date : 'shortTime'
    }}</span>
  </div>
  <div class="card--conversation__text">
    <div
      *ngIf="conversation.type === ConversationType.TICKET"
      [innerHTML]="
        conversation.ticket.firstMessage.attachment
          ? ('ticket.content.image' | translate)
          : conversation.ticket.firstMessage.content.replaceAll('href=', '')
      "
    ></div>
    <div
      *ngIf="conversation.type === ConversationType.SMART_REPORTING_NOTICE"
      [innerHTML]="
        conversation.smartReportingNotice.responses?.length > 0
          ? conversation.smartReportingNotice.responses[
              conversation.smartReportingNotice.responses.length - 1
            ].message
          : conversation.smartReportingNotice.description
      "
    ></div>
    <span [inlineSVG]="'/assets/icons/card/chevron-right.svg'"></span>
  </div>
</app-card>
<app-loader [loading]="true" [hidden]="!loading"></app-loader>
<div class="empty-list" *ngIf="!loading && conversations.length === 0">
  <span inlineSVG="/assets/icons/chat.svg"></span>
  <h2>{{ 'project.tab.conversations' | translate }}</h2>
  <p>{{ 'conversations.empty.desc' | translate }}</p>
</div>
