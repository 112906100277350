import { NgModule } from '@angular/core';
import {
  CommonModule,
  DatePipe as AngularDatePipe,
  DecimalPipe,
} from '@angular/common';
import { DateTypePipe } from './date-type.pipe';
import { ContentTextPipe } from './content-text.pipe';
import { LinkyPipe } from 'angular-linky';
import { MonthStringPipe } from './month-string.pipe';
import { DatePipe } from './date.pipe';
import { DistancePipe } from './distance.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    DateTypePipe,
    ContentTextPipe,
    MonthStringPipe,
    DatePipe,
    DistancePipe,
  ],
  exports: [
    DateTypePipe,
    ContentTextPipe,
    MonthStringPipe,
    DatePipe,
    DistancePipe,
  ],
  providers: [
    DatePipe,
    AngularDatePipe,
    DecimalPipe,
    LinkyPipe,
    MonthStringPipe,
  ],
})
export class PipesModule {}
