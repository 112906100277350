import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Project } from 'src/app/interfaces/project';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { LocationService } from 'src/app/services/location.service';
import { SmartReportingMapComponent } from '../../components/map/map.component';
import { SmartReportingTheme } from 'src/app/interfaces/smart-reporting-theme';
import { SmartReportingService } from 'src/app/services/smart-reporting.service';
import { ErrorService } from 'src/app/services/error.service';
import { UploadService } from 'src/app/services/upload.service';
import { SmartReportingNotice } from 'src/app/interfaces/smart-reporting-notice';
import { TicketService } from 'src/app/services/ticket.service';
import { ToastService } from 'src/app/services/toast.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';
import { MapboxService } from 'src/app/services/mapbox.service';
import { LngLat } from 'mapbox-gl';
import { from, lastValueFrom, ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-smart-reporting-create-page',
  templateUrl: './create.page.html',
})
export class SmartReportingCreatePage implements OnInit, OnDestroy {
  public project: Project;
  public mapComponent: SmartReportingMapComponent;
  public form: FormGroup;
  public userLocation: number[];
  public userAccuracy: number;
  public markerLocation: number[];
  public step = 1;
  public uploadedFile: string | File;
  public themes: SmartReportingTheme[] = null;
  public loading = false;
  public showNext = true;
  public errorLocation = false;
  public responseTime = 1;
  public created: SmartReportingNotice;
  @ViewChild(SmartReportingMapComponent)
  public map: SmartReportingMapComponent;
  @Output() createdNotice: EventEmitter<void> = new EventEmitter<void>();
  private destroyed$ = new ReplaySubject<void>();

  constructor(
    private fb: FormBuilder,
    private locationService: LocationService,
    private errorService: ErrorService,
    private ticketService: TicketService,
    private uploadService: UploadService,
    private smartReportingThemeService: SmartReportingService,
    private toastService: ToastService,
    private cd: ChangeDetectorRef,
    private keyboardService: KeyboardService,
    private navCtrl: NavController,
    private router: Router,
    private route: ActivatedRoute,
    private app: CurrentWhiteLabelApplication,
    private mapboxService: MapboxService
  ) {}

  ionViewDidEnter() {
    this.errorLocation = false;
  }

  public createForm() {
    this.form = this.fb.group({
      step1: this.fb.group({
        zip: [null],
        city: [null],
        street: [null],
        houseNumber: [null],
      }),
      step2: this.fb.group({
        theme: [null, Validators.required],
        description: [null, [Validators.required, Validators.maxLength(1000)]],
      }),
      step3: this.fb.group({
        name: [null, [Validators.required, Validators.maxLength(250)]],
        phoneNumber: [
          null,
          Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/),
        ],
        email: [null, [Validators.email, Validators.required]],
      }),
    });

    this.form
      .get('step1')
      .get('zip')
      .valueChanges.pipe(debounceTime(700))
      .subscribe((_) => this.updateLocation());

    this.form
      .get('step1')
      .get('city')
      .valueChanges.pipe(debounceTime(700))
      .subscribe((_) => this.updateLocation());

    this.form
      .get('step1')
      .get('street')
      .valueChanges.pipe(debounceTime(700))
      .subscribe((_) => this.updateLocation());

    this.form
      .get('step1')
      .get('houseNumber')
      .valueChanges.pipe(debounceTime(700))
      .subscribe((_) => this.updateLocation());
  }

  public async updateLocation() {
    const data = this.form.value.step1;
    const zip = data.zip;
    const city = data.city;
    const street = data.street;
    const houseNumber = data.houseNumber;

    if (!zip || !city || !street || !houseNumber) {
      return;
    }

    try {
      this.errorLocation = false;

      const response = await this.mapboxService.geocode(
        street + ' ' + houseNumber + ', ' + zip + ' ' + city
      );

      if (response.features.length > 0) {
        const location = response.features[0].geometry.coordinates;

        this.markerLocation = [location[1], location[0]];
        this.emitLocation();
      }
    } catch (error) {
      this.errorLocation = true;
    }
  }

  public async ngOnInit(): Promise<void> {
    if (this.router.getCurrentNavigation()?.extras?.state) {
      this.project = this.router.getCurrentNavigation().extras.state.project;
    } else {
      this.router.navigate(
        ['projects', this.route.snapshot.paramMap.get('slug')],
        { replaceUrl: true }
      );
      return;
    }

    this.createForm();

    this.fetchCurrentLocation();
    this.loadThemes();

    this.form
      .get('step3')
      .get('name')
      .patchValue(await this.ticketService.getSavedName());
    this.form
      .get('step3')
      .get('email')
      .patchValue(await this.ticketService.getSavedEmail());
    this.form
      .get('step3')
      .get('phoneNumber')
      .patchValue(await this.ticketService.getSavedPhoneNumber());

    this.keyboardService.showAccessoryBar();
    this.keyboardService.registerWillShowListener(() => {
      this.showNext = false;
      this.cd.detectChanges();
    });
    this.keyboardService.registerDidHideListener(() => {
      this.showNext = true;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.keyboardService.hideAccessoryBar();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public next() {
    const step2 = this.form.get('step2') as FormGroup;
    if (this.step === 3) {
      this.submit();
      return;
    } else if (this.step === 2) {
      this.errorService.markFormGroupTouchedAndDirty(step2);

      if (step2.valid) {
        this.step++;
      }
    } else {
      this.step++;
    }
  }

  public async determineFormFromLocation(location: number[], override = true) {
    let response;
    try {
      response = await lastValueFrom(
        from(
          this.mapboxService.reverseGeocode(
            new LngLat(location[1], location[0])
          )
        ).pipe(takeUntil(this.destroyed$))
      );
    } catch (error) {
      this.errorLocation = true;
    } finally {
      if (override) {
        this.markerLocation = location;
        this.emitLocation();
      }
    }

    if (!response || response.features.length == 0) {
      return;
    }

    const grabValue = (key) =>
      response.features.find((f) => f.place_type[0] === key)?.text ?? '';

    const streetName = grabValue('address');
    const houseNumber = response.features[0].address ?? '';
    const city = grabValue('place');
    const zipCode = grabValue('postcode').replace(' ', '');

    const data = this.form.value.step1;
    if (
      override ||
      (!data.zip && !data.city && !data.street && !data.houseNumber)
    ) {
      if (!override) {
        this.markerLocation = location;
        this.emitLocation();
      }

      this.form.get('step1').patchValue(
        {
          zip: `${zipCode}`.trim(),
          city: `${city}`.trim(),
          street: `${streetName}`.trim(),
          houseNumber: `${houseNumber}`.trim(),
        },
        { emitEvent: false }
      );
    }
  }

  public setUploadedFile(file: string | File) {
    this.uploadedFile = file;
  }

  public async submit() {
    this.errorService.markFormGroupTouchedAndDirty(this.form);

    if (this.form.valid) {
      this.loading = true;

      try {
        const data = this.form.value;
        let attachment = null;

        if (this.uploadedFile != null) {
          const uploadResult: any = await this.uploadService.upload(
            'smart-reporting-notice-attachment',
            this.uploadedFile
          );

          attachment = uploadResult.file;
        }

        const notice: any = {
          project: this.project['@id'],
          description: data.step2.description,
          email: data.step3.email.trim(),
          name: data.step3.name,
          phoneNumber: data.step3.phoneNumber,
          theme: `/api/smart-reporting-themes/${data.step2.theme.id}`,
          attachment,
          locationLat: location === null ? null : this.markerLocation[0],
          locationLong: location === null ? null : this.markerLocation[1],
        };

        this.responseTime = data.step2.theme.responseTimeDays;

        await this.ticketService.saveName(data.step3.name);
        await this.ticketService.saveEmail(data.step3.email);
        await this.ticketService.savePhoneNumber(data.step3.phoneNumber);

        this.created = await this.smartReportingThemeService.createNotice(
          notice
        );

        this.step = 4;
      } catch (error) {
        console.error(error);
        this.errorService.parseErrorsToForm(this.form, error, {
          attachmentFile: 'attachment',
        });
      } finally {
        this.loading = false;
      }
    }
  }

  public close() {
    if (this.step === 4) {
      this.smartReportingThemeService.requestRedraw.emit(this.created);
      this.toastService.show('smart_reporting.create.success');
    }

    this.navCtrl.setDirection('back');
    if (this.app.isProjectApp()) {
      this.router.navigate(['project']);
    } else {
      this.router.navigate(['projects', this.project.slug]);
    }
  }

  private async fetchCurrentLocation(): Promise<void> {
    try {
      const location = await this.locationService.getCurrentPosition();

      if (location) {
        this.userLocation = [
          location.coords.latitude,
          location.coords.longitude,
        ];
        this.userAccuracy = location.coords.accuracy;
      }
    } catch (error) {
      //Location denied by user
    }

    this.determineFormFromLocation(
      [this.project.locationLat, this.project.locationLong],
      false
    );
  }

  private emitLocation() {
    this.map.moveMarker(this.markerLocation);
  }

  private async loadThemes() {
    this.themes = await this.smartReportingThemeService.themes(this.project);
  }

  public goTo(step: number) {
    if (this.canGoTo(step)) {
      this.step = step;
    }
  }

  private canGoTo(step: number): boolean {
    if (step <= this.step) {
      return true;
    } else if (step === 2) {
      return !!this.markerLocation;
    } else if (step === 3) {
      return this.form.get('step2').valid && !!this.markerLocation;
    }
  }
}
