import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Phase } from 'src/app/interfaces/phase';
import { Project } from 'src/app/interfaces/project';
import { Target } from 'src/app/interfaces/target';
import { Update } from 'src/app/interfaces/update';
import { PhaseService } from 'src/app/services/phase.service';
import { ProjectService } from 'src/app/services/project.service';
import { UpdateService } from 'src/app/services/update.service';
import { ApiInit } from 'src/app/utils/api-init';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';
import { ProjectTabs } from '../../enums/project-tabs';
import { ModuleHelper } from 'src/app/utils/module-helper';
import { Module } from 'src/app/enums/module';
import { Subscription } from 'rxjs';
import { Share } from '@capacitor/share';
import { DeviceService } from '../../services/device.service';
import { HttpParams } from '@angular/common/http';
import { ParticipationService } from '../../services/participation.service';
import { TicketService } from 'src/app/services/ticket.service';
import { VideoService } from '../../services/video.service';
import { TargetPickerService } from '../../services/target-picker.service';
import { ProjectPlanningComponent } from './tabs/project-planning/project-planning.component';
import { StorageService } from 'src/app/services/storage.service';
import { RateApp } from 'capacitor-rate-app';
import * as Sentry from '@sentry/browser';
import { SmartReportingService } from 'src/app/services/smart-reporting.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AnalyticsType } from 'src/app/enums/analytics-type';

@Component({
  selector: 'app-project',
  templateUrl: './project.page.html',
})
export class ProjectPage implements OnInit {
  project: Project;
  phases: Phase[];
  latestUpdate: Update;
  updates: Update[];
  totalUpdates: number;
  companyText: string;
  projectLoaded: boolean = false;
  deviceDataLoaded: boolean = false;
  currentPage: string;
  targets: Target[];
  projectSlug: string;
  sticky: boolean = false;
  fromUrl: string;
  starSubscription: Subscription;
  canShare: boolean = false;
  navigationTab: string;
  loading: boolean = false;
  startLivestream: boolean = false;
  isProjectApp: boolean = false;
  updateDeviceData: any = {};
  ProjectTabs = ProjectTabs;

  pages = [
    { value: ProjectTabs.HOME, title: 'project.tab.home', enabled: true },
    {
      value: ProjectTabs.UPDATES,
      title: 'project.tab.updates',
      enabled: false,
      unread: 0,
    },
    {
      value: ProjectTabs.PLANNING,
      title: 'project.tab.planning',
      enabled: false,
    },
    {
      value: ProjectTabs.PARTICIPATE,
      title: 'project.tab.participate',
      enabled: false,
      unread: 0,
    },
    { value: ProjectTabs.CONTACT, title: 'project.tab.contact', enabled: true },
    {
      value: ProjectTabs.CONVERSATIONS,
      title: 'project.tab.conversations',
      enabled: false,
      unread: 0,
    },
    {
      value: ProjectTabs.SMART_REPORTING,
      title: 'project.tab.smart-reporting',
      enabled: false,
    },
    {
      value: ProjectTabs.CHILD_PROJECTS,
      title: 'project.tab.child-projects',
      enabled: false,
    },
    {
      value: ProjectTabs.ABOUT_US,
      title: 'project.tab.about-us',
      enabled: true,
    },
  ];

  @ViewChild(IonContent) content: IonContent;
  @ViewChild('tabs') projectTabs: ElementRef;
  @ViewChild(ProjectPlanningComponent)
  public projectPlaningComponent: ProjectPlanningComponent;

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private updateService: UpdateService,
    private phaseService: PhaseService,
    private deviceService: DeviceService,
    private router: Router,
    private videoService: VideoService,
    private app: CurrentWhiteLabelApplication,
    private ticketService: TicketService,
    private smartReportingService: SmartReportingService,
    private targetPickerService: TargetPickerService,
    private participationService: ParticipationService,
    private apiInit: ApiInit,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private analyticsService: AnalyticsService
  ) {
    this.isProjectApp = this.app.isProjectApp();

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.preview_token) {
        sessionStorage.setItem('preview_token', params.preview_token);
      }
    });

    if (this.isProjectApp) {
      this.apiInit.watch(async () => {
        this.projectSlug = this.app.getProjectAppSlug();
        this.loadProject();
      });
    } else {
      const currentUrlSegment = this.route.snapshot.url.join('/');
      const isUrlEndingWithUpdates = currentUrlSegment.endsWith(
        ProjectTabs.UPDATES
      );

      if (
        this.router.getCurrentNavigation()?.extras.state?.tab ||
        isUrlEndingWithUpdates
      ) {
        this.navigationTab =
          this.router.getCurrentNavigation()?.extras.state?.tab ??
          ProjectTabs.UPDATES;
      }

      this.projectSlug = this.route.snapshot.params.slug;
      this.apiInit.watch(async () => {
        this.loadProject();
      });

      this.targetPickerService.starDone$.subscribe(
        () => (this.loading = false)
      );

      this.storageService.get('projects_opened').then((result) => {
        const value: number = result || 0;
        if (value === 3) {
          RateApp.requestReview();
        }
        this.storageService.set('projects_opened', value + 1);
      });
    }

    this.starSubscription = this.projectService.starChanged$.subscribe(
      (project) => {
        if (project.id == this.project.id) {
          this.project.starred = project.starred;
        }
      }
    );

    this.ticketService.lastViewDataUpdated$.subscribe(() =>
      this.setPageUnread(ProjectTabs.CONVERSATIONS, 0)
    );
    this.participationService.lastViewDataUpdated$.subscribe(() =>
      this.setPageUnread(ProjectTabs.PARTICIPATE, 0)
    );

    this.smartReportingService.noticeSubmitted$.subscribe(() =>
      this.enablePage(ProjectTabs.CONVERSATIONS)
    );
    this.smartReportingService.noticeSubscribed$.subscribe(() =>
      this.enablePage(ProjectTabs.CONVERSATIONS)
    );

    Share.canShare().then(
      (result) => (this.canShare = result.value && !!app.getPwaDomain())
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.starSubscription.unsubscribe();
  }

  async handleScroll(event) {
    if (this.projectTabs === undefined) {
      return;
    }
    const projectTabsHeight = this.projectTabs.nativeElement.offsetHeight;
    const stickyBefore = this.sticky;

    this.sticky =
      event.detail.scrollTop +
        0.5 * projectTabsHeight * (this.sticky ? 1 : -1) >
      200;

    if (stickyBefore !== this.sticky) {
      const scrollElement = await event.target.getScrollElement();
      if (
        event.detail.scrollTop >
        scrollElement.scrollHeight -
          scrollElement.clientHeight -
          projectTabsHeight
      ) {
        return;
      }

      this.content.scrollByPoint(
        0,
        projectTabsHeight * (this.sticky ? -1 : 1),
        0
      );
    }
  }

  onScrollEnd() {
    this.videoService.checkVideos();
  }

  private setPageUnread(tab: ProjectTabs, value: number) {
    const index = this.pages.findIndex((p) => p.value === tab);
    this.pages[index].unread = value;
  }

  private enablePage(tab: ProjectTabs) {
    const index = this.pages.findIndex((p) => p.value === tab);
    this.pages[index].enabled = true;
  }

  determineProjectPages() {
    if (!this.isProjectApp) {
      this.enablePage(ProjectTabs.UPDATES);
    }

    if (
      !this.isProjectApp &&
      (this.project.ratingThemes.length > 0 || this.project.activeSurvey)
    ) {
      this.enablePage(ProjectTabs.PARTICIPATE);
    }

    if (ModuleHelper.hasModule(this.project, Module.SMART_REPORTING)) {
      this.enablePage(ProjectTabs.SMART_REPORTING);
    }

    if (!!this.project.planning) {
      this.enablePage(ProjectTabs.PLANNING);
    }

    if (
      this.project.isParentProject &&
      this.project.childrenProjects.length > 0 &&
      ModuleHelper.hasModule(this.project, Module.CHILD_PROJECTS) &&
      !this.isProjectApp
    ) {
      this.enablePage(ProjectTabs.CHILD_PROJECTS);
    }

    this.currentPage = this.navigationTab
      ? this.navigationTab
      : this.pages[0].value;
    this.projectLoaded = true;
  }

  segmentChanged(event) {
    this.switchTab(event.detail.value);
  }

  async switchTab(tab) {
    let delayScroll = false;

    if (
      tab === ProjectTabs.CONVERSATIONS &&
      !this.pages[
        this.pages.findIndex((p) => p.value === ProjectTabs.CONVERSATIONS)
      ].enabled
    ) {
      this.enablePage(ProjectTabs.CONVERSATIONS);
      delayScroll = true;
    }

    const index = this.pages.findIndex((page) => page.value == tab);
    this.analyticsService.logEvent(
      AnalyticsType.PROJECT_DETAIL_TAB_CHANGED,
      {
        from: this.currentPage,
        to: this.pages[index].value,
      },
      this.project
    );

    this.currentPage = this.pages[index].value;

    const element = await this.content.getScrollElement();

    if (
      tab === ProjectTabs.PLANNING &&
      this.projectPlaningComponent.scroll(element)
    ) {
      return;
    }

    if (element.scrollTop > 240) {
      await this.content.scrollToPoint(0, 240);
    }

    setTimeout(
      () => {
        this.projectTabs.nativeElement
          .getElementsByClassName(this.currentPage)[0]
          .scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
      },
      delayScroll ? 100 : 0
    );
  }

  goBack() {
    this.router.navigate([this.fromUrl], { replaceUrl: true });
  }

  async loadProject() {
    this.projectLoaded = false;

    const projectSlug = this.projectSlug;
    try {
      this.project = await this.projectService.getProject(projectSlug);

      this.phases = await this.phaseService.fetch(this.project);

      const params = new HttpParams().set('perPage', '21');
      const updateResponse = await this.updateService.getAllUpdates(
        this.project,
        params
      );
      this.updates = updateResponse.member;

      let updates = await this.updateService.filterUpdatesByTargetGroup(
        this.updates,
        this.project
      );

      updates = await this.getUpdateReactions(updates);
      const filteredUpdatesCount = this.updates.length - updates.length;
      this.updates = updates;
      this.totalUpdates = updateResponse.totalItems - filteredUpdatesCount;

      this.project.childrenProjects = this.project.childrenProjects.filter(
        (p) => p.status === 'PUBLISHED'
      );

      await this.getDeviceData();
      this.determineProjectPages();
      this.getRatingThemes();

      await this.updateService.updateLastViewData(this.project);
    } catch (error) {
      if (!(error instanceof Error)) {
        const errorDetails = {
          message: error.message || 'Unknown error',
          status: error.status || 'Unknown status',
          ...(error.headers && { headers: error.headers }),
        };
        error = new Error(JSON.stringify(errorDetails));
      }

      Sentry.captureException(error);
      this.router.navigate(['404'], { replaceUrl: true });
    }
  }

  async getUpdateReactions(updates: Update[]): Promise<Update[]> {
    const reactions = await this.updateService.getUpdateReactionsForUpdates(
      updates
    );
    return updates.map((u: Update) => {
      u.likesPerType = reactions[u.id];
      u.likedBy = Object.values(u.likesPerType).reduce((c, n) => c + n, 0);
      return u;
    });
  }

  async getDeviceData() {
    let projectId = this.project.id.toString();
    let updateIds = this.updates.map(({ id }) => id).join(',');
    let params = new HttpParams()
      .set('project_ids', projectId)
      .set('update_ids', updateIds);

    try {
      const response = await this.deviceService.getData(params);

      const projectData = response.projects[projectId];

      this.project.distance = projectData.distance;
      this.project.starred = projectData.starred;

      this.setPageUnread(ProjectTabs.UPDATES, projectData.updatesUnreadCount);
      this.setPageUnread(
        ProjectTabs.PARTICIPATE,
        projectData.participationUnreadCount
      );
      this.setPageUnread(
        ProjectTabs.CONVERSATIONS,
        projectData.conversationsUnreadCount
      );

      this.updateDeviceData = response.updates;

      if (this.updates.length) {
        if (projectData.target) {
          this.updates.forEach((update, index) => {
            if (
              update.targets &&
              update.targets.find(
                (target) => target.id == projectData.target.id
              )
            ) {
              this.updates.splice(index, 1);
            }
          });
        }

        this.updates.some((update) => {
          if (!update.ratingMonitorPush) {
            this.latestUpdate = update;
            return true;
          }
        });
      }
      this.project.amountOfFollowers = projectData.amountOfFollowers;
      if (projectData.conversationCount > 0) {
        this.enablePage(ProjectTabs.CONVERSATIONS);
      }
    } catch (error) {
    } finally {
      this.deviceDataLoaded = true;
    }
  }

  async getRatingThemes() {
    try {
      this.project.ratingThemes = await this.projectService.getRatingThemes(
        this.project
      );
    } catch (error) {
      // Ignore and use the themes already available
    }
  }

  goToParent() {
    this.router.navigate(['projects', this.project.parentProject.slug]);
  }

  async share() {
    await Share.share({
      url: `https://${this.app.getPwaDomain()}/projects/${this.project.slug}`,
    }).catch((e) => {
      // share cancelled
    });

    this.analyticsService.logEvent(
      AnalyticsType.PROJECT_SHARED,
      {},
      this.project
    );
  }

  async toggleFollowProject() {
    this.loading = true;
    if (this.project.starred) {
      await this.projectService.unstarProject(this.project);
      this.loading = false;
    } else {
      this.targetPickerService.presentTargets(this.project);
    }
  }

  public goToLivestream() {
    this.startLivestream = true;
    this.currentPage = this.pages[0].value;
    this.analyticsService.logEvent(
      AnalyticsType.LIVESTREAM_CLICKED,
      {},
      this.project
    );
  }

  public startedLivestream(event) {
    this.startLivestream = false;
  }
}
