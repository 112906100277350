import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Share } from '@capacitor/share';
import { ModalController } from '@ionic/angular';
import { Attachment } from 'src/app/interfaces/attachment';
import { AttachmentService } from '../../services/attachment.service';

@Component({
  selector: 'app-media-slider-modal',
  templateUrl: './media-slider-modal.component.html',
})
export class MediaSliderModalComponent {
  @Input() attachments: Attachment[];
  @Input() startAtSlide: number = 0;
  @Output() slideChanged: EventEmitter<number> = new EventEmitter();

  currentSlide: number;
  canShare: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private attachmentService: AttachmentService
  ) {
    Share.canShare().then((result) => (this.canShare = result.value));
    this.currentSlide = this.startAtSlide;
  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }

  setCurrentSlide(i: number) {
    this.currentSlide = i;
    this.slideChanged.emit(i);
  }

  share() {
    this.attachmentService.shareAttachment(this.attachments[this.currentSlide]);
  }
}
