import { EventEmitter, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { StorageService } from './storage.service';
import { ApiService } from './api.service';
import { Hydra } from '../interfaces/hydra';
import { Project } from '../interfaces/project';
import { lastValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { Target } from '../interfaces/target';
import * as moment from 'moment';
import { Attachments } from '../utils/attachments';
import { debounceTime, map } from 'rxjs/operators';
import { ImageService } from '../services/image.service';
import { Suggestion } from '../interfaces/suggestion';
import { RatingTheme } from '../interfaces/rating-theme';
import { PushService } from './push.service';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  private static PROJECT_IRI_PREFIX = '/api/projects/';
  private static TARGET_IRI_PREFIX = '/api/targets/';
  public static PROJECT_TARGET_KEY = 'projectTarget';

  public search: Subject<string> = new Subject<string>();
  public onSearch$: Observable<string> = this.search
    .asObservable()
    .pipe(debounceTime(500));

  public starChanged$ = new EventEmitter<Project>();
  public projectSuggestionDismissed$ = new EventEmitter<Project>();

  private currentRequest: Subscription = null;
  private currentSearch: Subscription = null;
  private preloadQueue: string[] = [];

  constructor(
    private apiService: ApiService,
    private storageService: StorageService,
    private imageService: ImageService,
    private pushService: PushService
  ) {}

  public async getProject(slug: string): Promise<Project> {
    const token = sessionStorage.getItem('preview_token');
    let params = new HttpParams();
    if (token) params = params.set('preview', token);

    let url = '/api/v3/projects/' + slug;

    return this.apiService
      .identifiedGet(url, params)
      .pipe(map((response) => this.mapProject(response)))
      .toPromise();
  }

  public async getStarredProjects(
    urlParams?: HttpParams
  ): Promise<Hydra<Project>> {
    let params = urlParams ? urlParams : new HttpParams();

    return await this.apiService
      .identifiedGet(`/api/v3/projects/starred`, params)
      .toPromise()
      .then(async (response: Response) => {
        let responseData = response;
        let obj = <Hydra<Project>>{};

        obj.member = <Project[]>(
          responseData['hydra:member'].map((object) => this.mapProject(object))
        );

        obj.totalItems = responseData['hydra:totalItems'];

        return obj;
      });
  }

  public async starProject(project: Project, target: Target): Promise<any> {
    const data = {
      project: ProjectService.PROJECT_IRI_PREFIX + project.slug,
      target: ProjectService.TARGET_IRI_PREFIX + target.slug,
    };

    // store what target we are
    this.storageService.set(this.getTargetKey(project), target.slug);

    project.starred = true;
    this.starChanged$.next(project);

    const response = await this.apiService
      .identifiedPost('/api/v2/projects/star', data)
      .toPromise();

    await this.pushService.requestPermission(true);

    return response;
  }

  public async unstarProject(project: Project): Promise<any> {
    const data = {
      project: ProjectService.PROJECT_IRI_PREFIX + project.slug,
    };

    // delete what target we were
    this.storageService.set(this.getTargetKey(project), null);

    project.starred = false;
    this.starChanged$.next(project);

    const response = await this.apiService
      .identifiedPost('/api/v2/projects/unstar', data)
      .toPromise();

    return response;
  }

  public async updateTargetGroup(
    project: Project,
    target: Target
  ): Promise<any> {
    const data = {
      project: ProjectService.PROJECT_IRI_PREFIX + project.slug,
      target: ProjectService.TARGET_IRI_PREFIX + target.slug,
    };

    this.storageService.set(this.getTargetKey(project), target.slug);

    project.starred = true;
    this.starChanged$.next(project);

    const response = await this.apiService
      .identifiedPost('/api/v2/projects/change-star-target', data)
      .toPromise();

    await this.pushService.requestPermission(true);

    return response;
  }

  public async getCurrentTarget(project: Project): Promise<string | null> {
    return await this.storageService.get(this.getTargetKey(project));
  }

  private mapProject(object: Project) {
    object.createdAt = moment(object.createdAt).toDate();
    object.ratingPushedAt =
      object.ratingPushedAt == null
        ? null
        : moment(object.ratingPushedAt).toDate();

    if (object.updates != null) {
      object.images = [];

      let images = 0;

      for (const update of object.updates) {
        if (update.attachments != null && update.status == 'PUBLISHED') {
          for (const attachment of update.attachments) {
            if (
              Attachments.isYoutubeVideo(attachment) ||
              attachment.filePath != null
            ) {
              object.images.push(attachment);

              if (++images <= 4) {
                // preload first images
                const url =
                  attachment != null && Attachments.isYoutubeVideo(attachment)
                    ? this.imageService.getVideoImage(attachment.videoId)
                    : attachment?.filePathThumbnails?.medium;
                this.preloadQueue.push(url);
              }
            }
          }
        }
      }
    }

    this.preloadQueue.push(object.coverImageThumbnails?.large);

    return object;
  }

  private getTargetKey(project: Project): string {
    return ProjectService.PROJECT_TARGET_KEY + '- ' + project.slug;
  }

  getProjectsByClosestLocation(
    cancelPending: boolean = false
  ): Promise<Suggestion[]> {
    return new Promise((resolve, reject) => {
      const params = new HttpParams();

      if (cancelPending && this.currentRequest != null) {
        this.currentRequest.unsubscribe();
      }

      const observable = this.apiService.identifiedGet(
        '/api/v3/search/projects/closest',
        params
      );
      this.currentRequest = observable.subscribe(
        (response) => {
          response = Object.values(response);

          response.map((value: Suggestion) => {
            value.item.starred = value.starred;
          });

          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getNumberOfAllProjects(): Promise<number> {
    return this.apiService
      .identifiedGet('/api/v3/search/projects-count', null)
      .toPromise()
      .then((response: any) => {
        const { numberOfProjects }: { numberOfProjects: number } = response;
        return numberOfProjects;
      });
  }

  getProjectsByLocation(urlParams: HttpParams): Promise<Project[]> {
    return new Promise((resolve, reject) => {
      const params = urlParams ? urlParams : new HttpParams();

      if (this.currentSearch != null) {
        this.currentSearch.unsubscribe();
      }

      const observable = this.apiService.identifiedGet(
        '/api/v3/search/projects',
        params
      );
      this.currentSearch = observable.subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  public async getProjectsByLocationBox(
    urlParams: HttpParams
  ): Promise<Hydra<Project>> {
    return new Promise((resolve, reject) => {
      const params = urlParams ? urlParams : new HttpParams();

      const observable = this.apiService.identifiedGet(
        '/api/v3/search/projects/box',
        params
      );

      if (this.currentRequest != null) {
        this.currentRequest.unsubscribe();
      }

      this.currentRequest = observable.subscribe(
        (response) => {
          let obj = <Hydra<Project>>{};
          if (response['hydra:member'] !== undefined) {
            obj.member = response['hydra:member'];
            obj.totalItems = response['hydra:totalItems'];
          }

          resolve(obj);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getProjectsByKeyword(urlParams: HttpParams): Promise<Project[]> {
    return new Promise((resolve, reject) => {
      const params = urlParams ? urlParams : new HttpParams();

      if (this.currentRequest != null) {
        this.currentRequest.unsubscribe();
      }

      const observable = this.apiService.identifiedGet(
        '/api/v3/search/keyword',
        params
      );
      this.currentRequest = observable.subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  public async getSuggestedProjects(urlParams: HttpParams): Promise<Project[]> {
    return new Promise((resolve, reject) => {
      const params = urlParams ? urlParams : new HttpParams();

      const observable = this.apiService.identifiedGet(
        '/api/v3/projects/nearby-device-addresses',
        params
      );
      this.currentRequest = observable.subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  public async dismissProjectSuggestion(project: Project) {
    this.projectSuggestionDismissed$.next(project);
    return this.apiService
      .identifiedPut(
        '/api/v3/projects/nearby-device-addresses/' + project.slug + '/hide',
        null
      )
      .toPromise();
  }

  public async getRatingThemes(project: Project): Promise<RatingTheme[]> {
    return this.apiService
      .identifiedGet(
        '/api/v3/projects/' + project.slug + '/rating-themes',
        null
      )
      .toPromise();
  }

  public async getAllProjects(urlParams: HttpParams): Promise<Hydra<Project>> {
    const params = urlParams ? urlParams : new HttpParams();

    return lastValueFrom(
      this.apiService.identifiedGet('/api/v2/projects', params)
    ).then(async (response: Response) => {
      let responseData = response;
      let obj = <Hydra<Project>>{};

      obj.member = responseData['hydra:member'];
      obj.totalItems = responseData['hydra:totalItems'];

      return obj;
    });
  }
}
