<ion-content class="app conversation" #content>
  <div class="conversation__inner" *ngIf="ticket">
    <div class="conversation__top">
      <div class="back-button" *ngIf="!showForm && !fullScreen">
        <ion-back-button
          mode="md"
          [defaultHref]="defaultHref"
        ></ion-back-button>
      </div>
      <div
        class="back-button"
        *ngIf="showForm && !fullScreen"
        (click)="showForm = false"
      ></div>
      <div
        class="back-button"
        *ngIf="!showForm && fullScreen"
        (click)="fullScreen = false"
      ></div>
      <div
        class="back-button"
        *ngIf="showForm && fullScreen"
        (click)="showForm = false"
      ></div>
      <div>
        <!-- Insert translation button here -->
      </div>
    </div>

    <div class="conversation__head" *ngIf="!showForm">
      <div class="conversation__image">
        <img
          appImgFallback
          [src]="ticket.project.logoThumbnails?.small"
          alt=""
          width="40"
          height="40"
        />
      </div>
      <div class="conversation__title">
        <span>
          <span>{{ ticket.project.name }}</span>
          <span>{{ ticket.project.participants }}</span>
        </span>
        <span>
          {{ ticket.project.startDate | date:'longDate' }} • {{
          (ticket.project.amountOfFollowers == 1 ? 'card.followers.singular' :
          'card.followers.plural') | translate:{'followers':
          ticket.project.amountOfFollowers} }}
        </span>
      </div>
    </div>

    <div class="conversation__content" *ngIf="!showForm">
      <div class="conversation__content__meta">
        <h1>{{ ticket.subject }}</h1>
        <span class="date"
          >{{ 'ticket.submitted_on' | translate }} {{ ticket.createdAtMock |
          date:'longDate'}}</span
        >
        <span class="status" [class.closed]="ticket.status == 'CLOSED'"
          >{{ ticket.status }}</span
        >
      </div>

      <div
        class="conversation__closed"
        [hidden]="ticket.status !== 'CLOSED' || paginator.isLoading()"
      >
        <p>
          {{ 'conversation.closed.start' | translate }} <br />
          <a [routerLink]="['..', { state: { tab: 'contant' }}]"
            >{{ 'conversation.closed.link' | translate }}</a
          >
          {{ 'conversation.closed.end' | translate }}
        </p>

        <div
          class="conversation__provide-feedback"
          *ngIf="ticket.requestedFeedback"
        >
          <span class="regular-black conversation__feedback-label"
            >{{ 'conversation.feedback'|translate }}</span
          >
          <app-smiley-input
            [formControl]="feedbackControl"
            [disabled]="ticket.feedback != null"
          ></app-smiley-input>
        </div>
      </div>

      <div
        class="conversation__chat"
        [class.conversation__chat--fullscreen]="fullScreen"
        [hidden]="paginator.hasError()"
      >
        <app-translation-button
          *ngIf="shouldShowTranslation"
          (translate)="translateMessages()"
        ></app-translation-button>
        <div class="conversation__chat__head">
          <div>
            <span>{{ 'conversation.reactions' | translate }}</span>
            <span (click)="toggleFullScreen()">
              <span>{{ 'conversation.fullscreen' | translate }}</span>
              <span [inlineSVG]="'/assets/icons/external.svg'"></span>
            </span>
          </div>
        </div>

        <app-loader
          [full]="false"
          [loading]="paginator.isLoading() && paginator.items.length == 0"
          [hidden]="!paginator.isLoading() || paginator.items.length > 0"
        ></app-loader>

        <ion-list class="conversation__messages">
          <ul>
            <li
              *ngFor="let message of paginator.items"
              [class.support]="message.projectUser != null"
              [class.device]="message.projectUser == null"
              [class.hasImage]="message.attachment != null"
            >
              <span class="title" *ngIf="message.name">
                {{ message.name }}
              </span>
              <span
                class="title"
                *ngIf="!message.name && message.projectUser != null"
              >
                {{ message.projectUser.firstName }} {{
                message.projectUser.lastName }}
              </span>
              <span
                class="title"
                *ngIf="!message.name && message.projectUser == null"
              >
                {{ ticket.name }}
              </span>

              <div class="selectable" [innerHTML]="message.content"></div>

              <div
                class="attachment"
                *ngIf="message.attachment != null || message.attachmentPreview != null"
              >
                <div [style.background-image]="getAttachment(message)"></div>
              </div>

              <span class="date">
                {{ message.createdAt | appDate:'d MMMM yyyy - HH:mm' }}
              </span>

              <app-loader
                color="light"
                [loading]="message.loading"
              ></app-loader>
            </li>
            <li
              class="new"
              [hidden]="ticket.status === 'CLOSED'"
              (click)="toggleForm()"
            >
              {{ 'conversation.reaction.new' | translate }}
            </li>
          </ul>

          <!--<ion-infinite-scroll [hidden]="paginator.items.length == 0" position="top">
                        <ion-infinite-scroll-content></ion-infinite-scroll-content>
                    </ion-infinite-scroll>-->
        </ion-list>
      </div>

      <div class="conversation__error" [hidden]="!paginator.hasError()">
        <button (click)="paginator.loadPage()">
          {{'errors.retry' | translate }}
        </button>
      </div>
    </div>

    <div class="conversation__form" *ngIf="showForm">
      <div class="conversation__form__head">
        <p>{{ 'conversation.reaction.new.title' | translate }}</p>
        <span>{{ ticket.project.name }}</span>
      </div>

      <form
        [formGroup]="form"
        (submit)="send()"
        [class.invalid]="form.get('message').invalid && form.get('message').dirty"
      >
        <div class="form-group">
          <label>
            {{ 'conversation.reaction.new.description' | translate }}
            <span class="required">*</span>
          </label>
          <textarea
            formControlName="message"
            [attr.aria-label]="'conversation.message.placeholder'|translate"
            placeholder="{{ 'conversation.message.placeholder'|translate }}"
          ></textarea>
        </div>

        <div class="form-group attachment">
          <label
            >{{ 'conversation.reaction.new.attachments' | translate }}</label
          >
          <label class="attachment__default" (click)="changeAttachment()">
            <span role="button" [hidden]="uploadedFile != null">
              {{ 'conversation.message.attachment.add' | translate}}
            </span>
            <input
              #fileInput
              *ngIf="pwa"
              type="file"
              [accept]="mimeTypesAsString"
              (change)="nativeInputChange($event)"
            />
          </label>

          <div
            class="attachment__added"
            *ngIf="uploadedFile != null"
            (click)="clearAttachment()"
          >
            <span>
              <span
                >{{ 'conversation.message.attachment.remove' | translate}}</span
              >
              <span [inlineSVG]="'/assets/icons/close.svg'"></span>
            </span>
          </div>
        </div>

        <button
          type="submit"
          [disabled]="!form.valid"
          class="conversation__form__button"
        >
          <span [hidden]="loading">{{ 'conversation.send'|translate }}</span>
          <app-loader color="light" [loading]="loading"></app-loader>
        </button>
      </form>
    </div>
  </div>
</ion-content>
