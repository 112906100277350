<div class="card--summary__inner">
  <div class="card--summary__head">
    <div>
      <h2>
        {{
          'card.organization.title'
            | translate : { organization: customer.name }
        }}
      </h2>
    </div>
  </div>
  <div class="card--summary__organization">
    <div class="card__image default">
      <img
        *ngIf="customer.logoThumbnails"
        [src]="customer.logoThumbnails?.small"
        width="40"
        height="40"
        alt="{{ customer.name }}"
      />
    </div>
    <div class="card__content" *ngIf="customer.description">
      <div
        class="card--summary__description"
        [appTranslateText]="customer.description | truncate : 300"
        [html]="customer.description | truncate : 300"
        [language]="language"
      ></div>
    </div>
  </div>
  <a class="card--summary__link" (click)="open()">
    {{ 'card.organization.action' | translate }}
  </a>
</div>
