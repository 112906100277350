import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Share } from '@capacitor/share';
import { CardType } from 'src/app/enums/card-type';
import { Project } from 'src/app/interfaces/project';
import { Update } from 'src/app/interfaces/update';
import { UpdateAttachment } from 'src/app/interfaces/update-attachment';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ProjectService } from 'src/app/services/project.service';
import { UpdateService } from 'src/app/services/update.service';
import { ApiInit } from 'src/app/utils/api-init';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';
import { DeviceService } from 'src/app/services/device.service';
import { NavController } from '@ionic/angular';
import { UpdateLikeType } from 'src/app/enums/update-like-type';
import { AnalyticsType } from 'src/app/enums/analytics-type';

@Component({
  selector: 'app-project-update',
  templateUrl: './project-update.page.html',
})
export class ProjectUpdatePage implements OnInit {
  project: Project;
  update: Update;
  CardType = CardType;
  appLanguage: string;
  images: UpdateAttachment[];
  canShare: boolean = false;
  defaultHref: string = '/';
  likedType: UpdateLikeType = null;

  private loadedUpdate: boolean = false;
  private fromNotification: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiInit: ApiInit,
    private updateService: UpdateService,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private app: CurrentWhiteLabelApplication,
    private deviceService: DeviceService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private navCtrl: NavController
  ) {
    const navigationState = this.router.getCurrentNavigation()?.extras.state;

    if (navigationState && navigationState.notification) {
      this.fromNotification = true;
    }

    try {
      this.loadUpdate(
        this.activatedRoute.snapshot.paramMap.get('slug'),
        parseInt(this.activatedRoute.snapshot.paramMap.get('id'))
      );
    } catch (error) {
      // ignore
    }

    this.route.params.subscribe((params) => {
      if (params.slug && params.id) {
        try {
          this.loadUpdate(params.slug, params.id);
        } catch (error) {
          // ignore
        }
      }
    });

    Share.canShare().then(
      (result) => (this.canShare = result.value && !!app.getPwaDomain())
    );
  }

  ngOnInit() {
    this.analyticsService.logEvent(
      AnalyticsType.UPDATE_OPEN,
      {
        update_id: this.activatedRoute.snapshot.paramMap.get('id'),
      },
      this.project
    );
  }

  openProject() {
    this.navCtrl.setDirection('back');
    if (this.app.isProjectApp()) {
      this.router.navigate(['project'], { replaceUrl: true });
    } else {
      this.router.navigate(['projects', this.project.slug], {
        replaceUrl: true,
      });
    }
  }

  async loadUpdate(projectSlug: string, updateId: number) {
    if (this.loadedUpdate) {
      return;
    }
    this.loadedUpdate = true;

    this.apiInit.watch(async () => {
      this.projectService.getProject(projectSlug).then(async (project) => {
        this.project = project;
        this.defaultHref = this.app.isProjectApp()
          ? '/project'
          : '/projects/' + this.project.slug;
      });
      this.updateService.getUpdateById(updateId).then(async (update) => {
        this.update = update;
        if (
          this.update.status === 'CONCEPT' ||
          new Date(this.update.publicationDate) > new Date()
        ) {
          let url = this.router.url.split('/projects')[0];
          this.router.navigate([url, '404'], { replaceUrl: true });
        } else {
          if (false === this.fromNotification) {
            const response = await this.deviceService.getData(
              new HttpParams().set('update_ids', this.update.id.toString())
            );
            if (response?.updates) {
              this.likedType = response.updates[this.update.id].likedType;
            }
          }
          this.update.expanded = true;

          this.update.likesPerType =
            await this.updateService.getUpdateReactions(this.update);
          this.update.likedBy = Object.values(this.update.likesPerType).reduce(
            (c, n) => c + n,
            0
          );
          this.update = { ...this.update };
        }
      });
    });
  }

  async share(event) {
    event.stopPropagation();
    await Share.share({
      url: `https://${this.app.getPwaDomain()}/projects/${
        this.project.slug
      }/update/${this.update.id}`,
    }).catch((e) => {
      // share cancelled
    });

    this.analyticsService.logEvent(
      AnalyticsType.UPDATE_SHARED,
      { update: this.update.id },
      this.project
    );
  }
}
