<ion-content class="app conversation" #content>
  <div class="conversation__inner" *ngIf="notice && project">
    <div class="conversation__top">
      <div class="back-button" *ngIf="!showForm && !fullScreen">
        <ion-back-button
          mode="md"
          [defaultHref]="defaultHref"
        ></ion-back-button>
      </div>
      <div
        class="back-button"
        *ngIf="showForm && !fullScreen"
        (click)="showForm = false"
      ></div>
      <div
        class="back-button"
        *ngIf="!showForm && fullScreen"
        (click)="fullScreen = false"
      ></div>
      <div
        class="back-button"
        *ngIf="showForm && fullScreen"
        (click)="showForm = false"
      ></div>
    </div>

    <div class="conversation__head" *ngIf="!showForm">
      <div class="conversation__image">
        <img
          [src]="this.project.chatPlaceholderAvatarThumbnails"
          appImgFallback
          alt=""
          width="40"
          height="40"
        />
      </div>
      <div class="conversation__title">
        <span>
          <span>{{ project.name }}</span>
          <span>{{ project.participants }}</span>
        </span>
        <span>
          {{ project.startDate | date:'longDate' }} • {{
          (project.amountOfFollowers == 1 ? 'card.followers.singular' :
          'card.followers.plural') | translate:{'followers':
          project.amountOfFollowers} }}
        </span>
      </div>
    </div>

    <div class="conversation__content" *ngIf="!showForm">
      <div class="conversation__content__meta">
        <h1>{{ notice.theme.title }}</h1>
        <span class="date"
          >{{ 'ticket.submitted_on' | translate }} {{ notice.createdAt |
          date:'longDate'}}</span
        >
        <span
          class="status.toUpperCase()"
          [class.closed]="notice.status == SmartReportingNoticeStatus.CLOSED"
          >{{ 'smart_reporting.detail.notice.status.' + notice.status |
          translate }}</span
        >
      </div>

      <div
        class="conversation__closed"
        [hidden]="notice.status !== SmartReportingNoticeStatus.CLOSED"
      >
        <p>{{ 'conversation.closed.start' | translate }}</p>
        <ng-container *ngIf="canFeedback">
          <div class="conversation__provide-feedback">
            <span class="regular-black conversation__feedback-label"
              >{{ 'smart_reporting.detail.notice.feedback.solution'|translate
              }}</span
            >
            <app-smiley-input
              [formControl]="feedbackForm.get('feedbackSolution')"
              [disabled]="notice.feedbackSolution"
            ></app-smiley-input>
          </div>
          <div class="conversation__provide-feedback">
            <span class="regular-black conversation__feedback-label"
              >{{ 'smart_reporting.detail.notice.feedback.process'|translate
              }}</span
            >
            <app-smiley-input
              [formControl]="feedbackForm.get('feedbackProcess')"
              [disabled]="notice.feedbackProcess"
            ></app-smiley-input>
          </div>
        </ng-container>
      </div>
      <div class="conversation__description">
        <div class="conversation__description__head">
          <span>{{ 'conversation.description' | translate }}</span>
        </div>
        <div class="conversation__description__content">
          <span
            [appTranslateText]="notice.description"
            [html]="notice.description"
            [language]="project.language"
            (textTranslated)="textTranslated($event)"
          ></span>
        </div>
      </div>
      <div
        class="conversation__chat"
        [class.conversation__chat--fullscreen]="fullScreen"
      >
        <div class="conversation__chat__head">
          <div>
            <span>{{ 'conversation.reactions' | translate }}</span>
            <span (click)="toggleFullScreen()">
              <span>{{ 'conversation.fullscreen' | translate }}</span>
              <span [inlineSVG]="'/assets/icons/external.svg'"></span>
            </span>
          </div>
          <div>
            {{ (notice.theme.responseTimeDays === 1 ?
            'conversation.reaction.response-time.day' :
            'conversation.reaction.response-time.days') | translate:{days:
            notice.theme.responseTimeDays} }}
          </div>
        </div>

        <ion-list class="conversation__messages">
          <ul>
            <ng-container *ngFor="let message of responses">
              <li
                [class.support]="message.projectUser != null"
                [class.device]="message.projectUser == null"
              >
                <span class="title" *ngIf="message.name">
                  {{ message.name }}
                </span>
                <span
                  class="title"
                  *ngIf="!message.name && message.projectUser != null"
                >
                  {{ message.projectUser.firstName }} {{
                  message.projectUser.lastName }}
                </span>
                <span
                  class="title"
                  *ngIf="!message.name && message.projectUser == null"
                >
                  {{ notice.name }}
                </span>

                <div class="selectable" [innerHTML]="message.message"></div>

                <span class="date">
                  {{ message.createdAt | appDate:'d MMMM yyyy - HH:mm' }}
                </span>

                <app-loader
                  color="light"
                  [loading]="message.loading"
                ></app-loader>
              </li>
              <li
                class="new"
                *ngIf="notice.status !== SmartReportingNoticeStatus.CLOSED && notice.own && !message.isResponse && message.projectUser && message.responses.length === 0"
                (click)="toggleForm(message)"
              >
                {{ 'conversation.reaction.new' | translate }}
              </li>
            </ng-container>
          </ul>
        </ion-list>
      </div>
      <button
        [hidden]="notice.own"
        class="conversation__content__button"
        tabindex="0"
        (click)="toggleSubscription()"
      >
        {{ (notice.subscribed ? 'smart_reporting.detail.unsubscribe' :
        'smart_reporting.detail.subscribe') | translate }}
      </button>
    </div>

    <div class="conversation__form" *ngIf="showForm">
      <div class="conversation__form__head">
        <p>{{ 'conversation.reaction.new.title' | translate }}</p>
        <span>{{ project.name }}</span>
      </div>

      <form
        [formGroup]="form"
        (submit)="send()"
        [class.invalid]="form.get('message').invalid && form.get('message').dirty"
      >
        <div class="form-group">
          <label>
            {{ 'conversation.reaction.new.description' | translate }}
            <span class="required">*</span>
          </label>
          <textarea
            formControlName="message"
            [attr.aria-label]="'conversation.message.placeholder'|translate"
            placeholder="{{ 'conversation.message.placeholder'|translate }}"
          ></textarea>
        </div>

        <button
          type="submit"
          [disabled]="!form.valid"
          class="conversation__form__button"
        >
          <span [hidden]="loading">{{ 'conversation.send'|translate }}</span>
          <app-loader color="light" [loading]="loading"></app-loader>
        </button>
      </form>
    </div>
  </div>
</ion-content>
