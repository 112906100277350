export enum ProjectTabs {
  HOME = 'home',
  UPDATES = 'updates',
  PARTICIPATE = 'participate',
  CONTACT = 'contact',
  SMART_REPORTING = 'smart-reporting',
  CONVERSATIONS = 'conversations',
  PLANNING = 'planning',
  ABOUT_US = 'about-us',
  CHILD_PROJECTS = 'child-projects',
}
