<div class="form-group" [ngClass]="{ 'form-group--error': hasInvalidState() }">
  <ng-content></ng-content>

  <div class="errors" *ngIf="hasInvalidState() && control.errors">
    <div *ngIf="control.errors.required">
      {{ 'form-group.required' | translate }}
    </div>
    <div *ngIf="control.errors.maxlength">
      {{
        'form-group.max-length'
          | translate : { max: control.errors.maxlength.requiredLength }
      }}
    </div>
    <div *ngIf="control.errors.server">
      <div *ngIf="control.errors.server.length === 1">
        {{ control.errors.server[0] }}
      </div>
      <ul *ngIf="control.errors.server.length > 1">
        <li *ngFor="let message of control.errors.server">{{ message }}</li>
      </ul>
    </div>
  </div>
</div>
