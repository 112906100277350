<div class="planning">
  <div class="planning__head" *ngIf="phases.length > 0">
    <span class="icon" [inlineSVG]="'/assets/icons/rocket.svg'"></span>
    <h2>
      {{ 'project.planning.started' | translate }}
      {{ project.startDate | date : 'longDate' }}
    </h2>
  </div>
  <ul class="planning__items" *ngIf="planningLoaded">
      <li *ngIf="!showPastPhases">
        <button (click)="showPastPhases = true">
          <span class="icon" inlineSVG="/assets/icons/arrow-up.svg"></span>
          <p>{{ 'project.planning.show-past' | translate }}</p>
        </button>
      </li>
      <li
        *ngFor="let item of planningItems; let index = index"
        #phaseElement
        [ngClass]="(item['type'] == 'planning') && {
          first: (item['index'] + 1) % 3 == 1,
          second: (item['index'] + 1) % 3 == 2,
          third: (item['index'] + 1) % 3 == 0
        }"
        [class.hide]="!showPastPhases && item['isPast']"
      >
        <ng-container *ngIf="item['type'] == 'planning'">
          <div class="planning__items__box" (click)="openPhase(item)">
            <div *ngIf="item['isCurrent']" class="current">
              <span inlineSVG="assets/icons/flag-planning.svg"></span>
              <span>{{ 'project.planning.current' | translate }}</span>
            </div>
            <h3>
              {{ item.name }}
            </h3>
            <span>
              {{ item.startDate | dateType : item.startType }} - 
              {{ item.endDate | dateType : item.endType }}
            </span>
            <div class="text">
              <div
                [appTranslateText]="item.text | truncate : 300"
                [html]="item.text | truncate : 300"
                [language]="project.language"
              ></div>
            </div>
          </div>
            <div class="planning__items__events" *ngIf="item.events?.length">
              <app-event [language]="project.language" [standalone]="true" *ngFor="let event of item.events" [event]="event"></app-event>
            </div>
        </ng-container>
      
        <ng-container *ngIf="item['type'] == 'event'">
          <app-event [language]="project.language" [standalone]="true" [event]="item"></app-event>
        </ng-container>
      </li>
  </ul>

  <div *ngIf="phases.length == 0 && planningLoaded" class="empty-list">
    <span inlineSVG="/assets/icons/shuttle.svg"></span>
    <h2>{{ 'project.tab.planning' | translate }}</h2>
    <p>{{ 'planning.empty.desc' | translate }}</p>
  </div>
</div>
