import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectUpdatePage } from './project-update/project-update.page';
import { ProjectPage } from './project.page';
import { ProjectOpinionPage } from './project-opinion/project-opinion.page';
import { SmartReportingCreatePage } from '../../modules/smart-reporting/pages/create/create.page';
import { SmartReportingDetailPage } from '../../modules/smart-reporting/pages/detail/detail.page';
import { ConversationPage } from '../conversation/conversation.page';
import { ProjectSurveyPage } from './project-survey/project-survey.page';
import { ProjectPlanningPage } from './project-planning/project-planning.page';
import { ProjectTabs } from 'src/app/enums/project-tabs';

const routes: Routes = [
  {
    path: '',
    component: ProjectPage,
  },
  {
    path: 'updates',
    component: ProjectPage,
  },
  {
    path: 'update/:id',
    component: ProjectUpdatePage,
  },
  {
    path: 'feedback',
    component: ProjectOpinionPage,
  },
  {
    path: 'smart-reporting',
    component: SmartReportingCreatePage,
  },
  {
    path: 'smart-reporting/:id',
    component: SmartReportingDetailPage,
  },
  {
    path: 'survey/:id',
    component: ProjectSurveyPage,
  },
  {
    path: 'conversations/:id',
    component: ConversationPage,
  },
  {
    path: 'phase/:id',
    component: ProjectPlanningPage,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class ProjectPageRoutingModule {}
