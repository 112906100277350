<app-smart-reporting-map
  [project]="project"
  [userLocation]="userLocation"
  [userAccuracy]="userAccuracy"
  [full]="true"
></app-smart-reporting-map>

<button class="smart-reporting__new" (click)="create()">
  <span class="icon" [inlineSVG]="'/assets/icons/plus.svg'"></span>
  <span>{{ 'smart_reporting.create' | translate }}</span>
</button>
