import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class ToastService {
  public static readonly DEFAULT_DURATION = 6000;

  constructor(
    private toastCtrl: ToastController,
    private translate: TranslateService
  ) {}

  public async show(translationKey: string) {
    const toast = await this.toastCtrl.create({
      message: this.translate.instant(translationKey),
      duration: ToastService.DEFAULT_DURATION,
      buttons: [
        {
          text: this.translate.instant('errors.close'),
          role: 'cancel',
        },
      ],
    });

    toast.present();
  }

  public async showRetry(translationKey: string, callback: () => any) {
    const toast = await this.toastCtrl.create({
      message: this.translate.instant(translationKey),
      duration: ToastService.DEFAULT_DURATION,
      buttons: [
        {
          text: this.translate.instant('errors.retry'),
          role: 'cancel',
          handler: () => {
            callback();
          },
        },
      ],
    });

    toast.present();
  }
}
