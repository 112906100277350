import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Poll } from '../../interfaces/poll';
import { FormBuilder, FormControl } from '@angular/forms';
import { PollService } from '../../services/poll.service';
import { AnalyticsService } from '../../services/analytics.service';
import { Update } from '../../interfaces/update';
import * as moment from 'moment';
import { PollChoice } from '../../interfaces/poll-choice';
import { Project } from '../../interfaces/project';
import { WidgetService } from 'src/app/services/widget.service';
import { AnalyticsType } from 'src/app/enums/analytics-type';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
})
export class PollComponent {
  public poll: Poll;
  public showResult = false;
  public totalVotes = 0;
  public uniquePrefix: string;

  public control: FormControl;

  @Input('poll') public set pollSetter(item: Poll) {
    this.poll = item;

    this.loadResults();
  }
  @Input() public update: Update;
  @Input() public project: Project;

  constructor(
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private pollService: PollService,
    private analyticsService: AnalyticsService,
    private widgetService: WidgetService
  ) {
    this.createForm();
    this.pollService.pollVoted.subscribe((poll: Poll) => {
      if (poll['@id'] === this.poll['@id']) {
        this.poll = poll;
        this.loadResults();
      }
    });
    this.uniquePrefix = new Date().getTime().toString();
  }

  private loadResults() {
    let totalVotes = 0;
    let maxVotes = 0;

    for (const choice of this.poll.choices) {
      totalVotes += choice.votes;

      if (choice.votes > maxVotes) {
        maxVotes = choice.votes;
      }

      if (choice.voted) {
        this.control.patchValue(choice, { emitEvent: false });
        this.showResult = true;
      }
    }

    let rest = 100;
    let index = 1;

    if (this.poll.closed) {
      this.showResult = true;
    }

    for (const choice of this.poll.choices) {
      if (this.showResult) {
        let percentageSize;

        if (totalVotes === 0) {
          choice.percentage = 0;
          percentageSize = 5;
        } else {
          let percentage;

          if (index === this.poll.choices.length) {
            percentage = rest;
          } else {
            percentage = Math.round((choice.votes / totalVotes) * 100);

            rest -= percentage;
          }

          choice.percentage = percentage;
          percentageSize = choice.percentage;
        }

        setTimeout(() => (choice.percentageSize = percentageSize), 100);
      }

      if (choice.filePath != null && choice.image == null) {
        choice.image = this.sanitizer.bypassSecurityTrustStyle(
          `url(${choice.filePathThumbnails?.small})`
        );
      }

      if (choice.votes === maxVotes && choice.votes > 0) {
        choice.active = true;
      } else {
        choice.active = false;
      }

      index++;
    }

    this.totalVotes = totalVotes;
  }

  private createForm() {
    this.control = this.fb.control(null);

    this.control.valueChanges.subscribe((value) => {
      if (this.widgetService.getIsEmbedded()) {
        this.widgetService.openApp();
        this.control.patchValue(false, { emitEvent: false });
        return;
      }
      if (value != null) {
        this.poll.choices.forEach((choice) => {
          if (choice.voted) {
            choice.votes--;
            choice.voted = false;
          }
        });

        value.voted = true;
        value.votes += 1;

        this.showResult = true;

        this.loadResults();

        this.pollService.votePoll(this.poll, value);
        this.logVote(value);
      }
    });
  }

  private logVote(value: PollChoice) {
    this.analyticsService.logEvent(
      AnalyticsType.POLL_REACT,
      {
        update_date: moment(this.update.publicationDate).format(),
        poll_question: this.poll.title,
        poll_response: value.text,
        poll_response_id: value.id,
      },
      this.project
    );
  }
}
