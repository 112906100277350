import { Component, HostListener, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { QuestionType } from 'src/app/enums/question-type';
import { Project } from 'src/app/interfaces/project';
import { Survey } from 'src/app/interfaces/survey';
import { SurveyQuestion } from 'src/app/interfaces/survey-question';
import { SurveyQuestionAnswer } from 'src/app/interfaces/survey-question-answer';
import { ErrorService } from 'src/app/services/error.service';
import { ProjectService } from 'src/app/services/project.service';
import { SurveyService } from 'src/app/services/survey.service';
import { ApiInit } from 'src/app/utils/api-init';
import { ConfettiService } from 'src/app/services/conffeti.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AnalyticsType } from 'src/app/enums/analytics-type';

@Component({
  selector: 'app-project-survey',
  templateUrl: './project-survey.page.html',
})
export class ProjectSurveyPage implements OnInit {
  project: Project;
  survey: Survey;
  currentStep: number = 0;
  steps: number[] = [0];
  totalSteps: number;
  QuestionType = QuestionType;
  questionForm: FormArray;
  answerForm: FormArray;
  alreadySent: boolean = false;
  invalid: boolean = false;
  ready: boolean = false;
  surveys: Survey[];
  warning: boolean = false;
  surveyStarted: boolean = false;
  @HostListener('document:keypress', ['$event']) handleKeyboardEvent(
    event: KeyboardEvent
  ) {
    if (event.key == 'Enter' && event.target['nodeName'] != 'TEXTAREA') {
      if (this.currentStep < this.survey.surveyQuestions.length) {
        this.next();
      }
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private navCtrl: NavController,
    private projectService: ProjectService,
    private surveyService: SurveyService,
    private confettiService: ConfettiService,
    private apiInit: ApiInit,
    private fb: FormBuilder,
    private errorService: ErrorService,
    private analyticsService: AnalyticsService
  ) {
    this.activatedRoute.params.subscribe(async (params) => {
      if (params.id) {
        this.load(params.id);
      }
      if (params.slug) {
        this.fetchProject(params.slug);
      }
    });
  }

  ngOnInit() {}

  async checkAnswers() {
    await this.surveyService
      .getAnswersByDevice(this.survey.id)
      .then((answers: SurveyQuestionAnswer[]) => {
        if (answers.length > 0) {
          this.invalid = true;
          this.alreadySent = true;
        }
      });
    this.ready = true;
  }

  startSurvey() {
    this.surveyStarted = true;
  }

  private load(id: number) {
    this.apiInit.watch(async () => {
      try {
        this.survey = await this.surveyService.getSurvey(id);
        if (this.survey.status === 'ACTIVE') {
          this.createForm();
          this.checkAnswers();
        } else {
          this.invalid = true;
          this.ready = true;
        }
      } catch (error) {
        // ignore
      }
    });
  }

  private fetchProject(slug) {
    this.apiInit.watch(async () => {
      this.projectService
        .getProject(slug)
        .then((result) => {
          this.project = result;
        })
        .catch(() => {});
    });
  }

  createForm() {
    this.survey.surveyQuestions.forEach((question: SurveyQuestion) => {
      if (question.type == QuestionType.DESCRIPTION) {
        //no answer needed
      } else {
        //temporarily save the form on the question object so it's available in the loop
        let answer = this.fb.group({
          question: [question['@id']],
          survey: [this.survey['@id']],
          questionOption: [],
          questionOptions: this.fb.array([]),
          value: [],
        });
        if (question.required) {
          if (
            question.type == QuestionType.MULTIPLE_CHOICE ||
            question.type == QuestionType.CHECKBOX
          )
            answer.get('questionOption').setValidators([Validators.required]);
          else if (
            question.type == QuestionType.OPEN ||
            question.type == QuestionType.RANGE
          )
            answer.get('value').setValidators([Validators.required]);
        }
        if (question.type == QuestionType.RANGE) {
          question['rangeNumbers'] = [];
          for (let i = question.ratingStart; i <= question.ratingEnd; i++) {
            question['rangeNumbers'].push(i);
          }
        }
        answer.get('questionOption').valueChanges.subscribe((value) => {
          answer.get('value').patchValue(null);
        });
        question['answerForm'] = answer;
      }
    });
  }

  getEmojiForRangeNumber(number: number, question: SurveyQuestion) {
    switch (question.ratingEnd) {
      case 2:
        switch (number) {
          case 2:
            return 5;
          default:
            return number;
        }
      case 3:
        switch (number) {
          case 2:
            return 3;
          case 3:
            return 5;
          default:
            return number;
        }
      case 4:
        switch (number) {
          case 3:
            return 4;
          case 4:
            return 5;
          default:
            return number;
        }
      default:
        return number;
    }
  }

  getIsChecked(question, option, index) {
    if (
      (<FormArray>question['answerForm'].get('questionOptions'))
        .getRawValue()
        .indexOf(option.id.toString()) > -1
    ) {
      return true;
    }
  }

  checkboxChanged(question, option, event) {
    let checkArray: FormArray = (<FormArray>(
      question['answerForm'].get('questionOptions')
    )) as FormArray;
    if (event.target.checked) {
      checkArray.push(new FormControl(event.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == event.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    (<FormGroup>question['answerForm'])
      .get('questionOption')
      .patchValue(checkArray.length > 0);
  }

  getCurrentClass(type) {
    switch (type) {
      case QuestionType.DESCRIPTION:
        return 'description';
      case QuestionType.OPEN:
        return 'open';
      case QuestionType.MULTIPLE_CHOICE:
        return 'multiple';
      case QuestionType.CHECKBOX:
        return 'checkboxes';
      case QuestionType.RANGE:
        return 'scale';
    }
  }

  closeSurvey() {
    if (
      this.currentStep == 0 ||
      this.currentStep == this.survey.surveyQuestions.length
    )
      this.confirmClose();
    else this.warning = true;
  }

  cancelClose() {
    this.warning = false;
  }

  confirmClose() {
    this.navCtrl.back();
  }

  previous() {
    this.steps.splice(this.steps.length - 1, 1);
    this.currentStep = this.steps[this.steps.length - 1];
  }

  async next() {
    let question = this.survey.surveyQuestions[this.currentStep];
    if (question.type == QuestionType.DESCRIPTION) {
      this.currentStep++;
      this.steps.push(this.currentStep);
    } else {
      let answerForm: FormGroup = question['answerForm'];
      this.errorService.markFormGroupTouchedAndDirty(answerForm);
      if (answerForm.valid) {
        let shouldSkip = false;
        if (
          question.type == QuestionType.MULTIPLE_CHOICE &&
          answerForm.get('questionOption').value
        ) {
          question.surveyQuestionOptions.forEach((option) => {
            if (option.id == answerForm.get('questionOption').value) {
              if (option.action.length) {
                let action = option.action[0]?.split(':')[1]; //until we support multiple actions
                if (action) {
                  if (action != 'additional') {
                    this.currentStep = parseInt(action);
                    this.steps.push(this.currentStep);
                    shouldSkip = true;
                  }
                }
              }
            }
          });
        }
        if (
          question.type == QuestionType.CHECKBOX &&
          answerForm.get('questionOptions').value.length
        ) {
          question.surveyQuestionOptions.forEach((option) => {
            if (
              answerForm
                .get('questionOptions')
                .value.indexOf(option.id.toString()) > -1
            ) {
              if (option.action.length) {
                let action = option.action[0]?.split(':')[1]; //until we support multiple actions
                if (action) {
                  if (action != 'additional' && !shouldSkip) {
                    this.currentStep = parseInt(action);
                    this.steps.push(this.currentStep);
                    shouldSkip = true;
                  }
                }
              }
            }
          });
        }
        if (!shouldSkip) {
          this.currentStep++;
          this.steps.push(this.currentStep);
        }
      }
    }

    if (this.currentStep == this.survey.surveyQuestions.length) {
      this.confettiService.canon();

      let formData: FormArray = this.fb.array([]);
      this.survey.surveyQuestions.forEach((question) => {
        if (question.type != QuestionType.DESCRIPTION) {
          let optionIds: FormArray = question['answerForm'].get(
            'questionOptions'
          ) as FormArray;
          if (optionIds.length) {
            optionIds.controls.forEach((option) => {
              let answer = this.fb.group({
                //to-do: make this better reusable code
                question: [question['@id']],
                survey: [this.survey['@id']],
                questionOption: ['/api/survey-question-option/' + option.value],
                value: [],
              });
              formData.push(answer);
            });
          } else {
            let answer = <FormGroup>question['answerForm'];
            if (answer.get('questionOption').value)
              answer
                .get('questionOption')
                .patchValue(
                  '/api/survey-question-option/' +
                    answer.get('questionOption').value,
                  { emitEvent: false }
                );
            answer.removeControl('questionOptions');
            formData.push(answer);
          }
        }
      });

      await this.surveyService.saveAnswers(
        this.survey.id,
        formData.getRawValue()
      );

      this.analyticsService.logEvent(
        AnalyticsType.SURVEY_FILLED,
        { values: formData.getRawValue() },
        this.project
      );

      this.surveyService.surveySubject.next(this.survey);
    }
  }
}
