import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './api.service';
import { base64ToBlob } from 'base64-blob';
import { Base64Helper } from '../utils/base64-helper';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';

@Injectable({ providedIn: 'root' })
export class UploadService {
  constructor(
    private apiService: ApiService,
    private translateService: TranslateService
  ) {}

  /**
   * @returns {Promise<string>}
   */
  public selectUpload(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const actionSheetOptions = {
          title: this.translateService.instant('camera.info'),
          options: [
            { title: this.translateService.instant('camera.take') },
            { title: this.translateService.instant('camera.select') },
            {
              title: this.translateService.instant('camera.cancel'),
              style: ActionSheetButtonStyle.Cancel,
            },
          ],
        };

        const value = await ActionSheet.showActions(actionSheetOptions);

        if (value.index === 2) {
          return;
        }

        const options = {
          quality: 60,
          resultType: CameraResultType.Uri,
          correctOrientation: true,
          source: value.index === 0 ? CameraSource.Camera : CameraSource.Photos,
        };

        const image = await Camera.getPhoto(options);
        resolve(image.path);
      } catch (error) {
        reject(error);
      }
    });
  }

  public upload(type: string, file: string | File): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        let blob: Blob;

        if (typeof file === 'string') {
          const fileResult = await Filesystem.readFile({ path: file });
          const data = Base64Helper.formatBase64JpegImage(fileResult.data);
          blob = await base64ToBlob(data);
        } else {
          blob = file as File;
        }

        const formData = new FormData();
        formData.append('file', blob);

        const response = await this.apiService
          .identifiedPost(`/api/v2/uploads/${type}`, formData)
          .toPromise();

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}
