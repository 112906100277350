import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings';
import { LocalNotifications } from '@capacitor/local-notifications';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PwaService } from 'src/app/services/pwa.service';
import { Platform } from '@ionic/angular';
import { PushService } from 'src/app/services/push.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.page.html',
})
export class PermissionsPage implements OnInit, OnDestroy {
  permissionsForm: FormGroup;
  private resumeSubscription: Subscription;

  constructor(
    private pushService: PushService,
    private fb: FormBuilder,
    private platform: Platform,
    private ngZone: NgZone,
    private pwaService: PwaService
  ) {
    this.createPermissionsForm();
  }

  ngOnInit() {
    this.checkStatuses(false);
    this.resumeSubscription = this.platform.resume.subscribe(() => {
      this.checkStatuses();
    });
  }

  ngOnDestroy() {
    this.resumeSubscription.unsubscribe();
  }

  createPermissionsForm() {
    this.permissionsForm = this.fb.group({
      notificationsEnabled: [false],
      locationEnabled: [false],
    });

    this.permissionsForm
      .get('notificationsEnabled')
      .valueChanges.subscribe((value) => {
        if (!this.pwaService.isPwa()) {
          NativeSettings.open({
            optionAndroid: AndroidSettings.AppNotification,
            optionIOS: IOSSettings.Notifications,
          });
        }
      });
  }

  openLocationSettings() {
    if (!this.pwaService.isPwa()) {
      NativeSettings.open({
        optionAndroid: AndroidSettings.Location,
        optionIOS: IOSSettings.LocationServices,
      });
    }
  }

  async checkStatuses(updatePush: boolean = true) {
    let notificationsEnabled = false;
    let locationEnabled = false;

    if (!this.pwaService.isPwa()) {
      notificationsEnabled =
        (await LocalNotifications.checkPermissions()).display === 'granted'
          ? true
          : false;
      try {
        let locationPermissions = await Geolocation.checkPermissions();
        locationEnabled =
          locationPermissions.location === 'granted' ||
          locationPermissions.coarseLocation === 'granted'
            ? true
            : false;
      } catch (error) {
        locationEnabled = false;
      }
    }

    this.ngZone.run(() => {
      this.permissionsForm
        .get('notificationsEnabled')
        .setValue(notificationsEnabled, { emitEvent: false });
      this.permissionsForm
        .get('locationEnabled')
        .setValue(locationEnabled, { emitEvent: false });
    });

    if (!updatePush) {
      return;
    }

    if (notificationsEnabled) {
      await this.pushService.enablePush();
    } else {
      await this.pushService.disablePush();
    }
  }
}
