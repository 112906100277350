import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Project } from 'src/app/interfaces/project';
import { SmartReportingNotice } from 'src/app/interfaces/smart-reporting-notice';
import { SmartReportingService } from 'src/app/services/smart-reporting.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Customer } from 'src/app/interfaces/customer';
import { MapboxService } from 'src/app/services/mapbox.service';
import { MapComponent } from 'ngx-mapbox-gl';
import * as mapboxgl from 'mapbox-gl';
import { MapMouseEvent } from 'mapbox-gl';
import { PwaService } from 'src/app/services/pwa.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-smart-reporting-map',
  templateUrl: './map.component.html',
})
export class SmartReportingMapComponent implements AfterViewInit, OnDestroy {
  @Input('project') set projectSetter(project: Project) {
    this.project = project;

    this.location = [project.locationLat, project.locationLong];
  }

  @Input() public zoomPosition = 'bottom-right';
  @Input() public hasFilter = true;

  public mapError: boolean = false;
  private redrawSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private mapboxService: MapboxService,
    private smartReportingService: SmartReportingService,
    private fb: FormBuilder,
    private app: CurrentWhiteLabelApplication,
    private pwaService: PwaService
  ) {
    this.createForm();

    this.redrawSubscription =
      this.smartReportingService.requestRedraw$.subscribe((data) => {
        if (data) {
          this.noticeList = [
            ...this.noticeList.filter((item) => +item.id !== +data.id),
            data,
          ];
        }

        this.createListMarkers();
      });
  }

  public static readonly ZOOM = 14;

  public project: Project;
  @ViewChild(MapComponent) public mapComponent: MapComponent;
  @ViewChild('holder', { static: true }) public holder: ElementRef;
  @Output() public mapLocationSelected: EventEmitter<number[]> =
    new EventEmitter<number[]>();
  @Input() public pointToUser = false;
  @Input() public full = false;
  @Input() public hasProjectMarker = true;
  public mapMarkers: mapboxgl.Marker[] = [];
  public noticeList: SmartReportingNotice[] = [];
  public location: number[];
  @Input() public userLocation: number[];
  @Input() public userAccuracy: number;
  public markerLocation: number[];
  public options = {
    disableDefaultUI: true,
    clickableIcons: false,
  };
  public marker: mapboxgl.Marker;
  public filterOpen = false;
  public filterForm: FormGroup;
  public currentFilterFormValue: any;
  public width: number;
  public height: number;
  public loading = true;

  private projectMarker: mapboxgl.Marker;

  ngOnInit() {
    if (!this.mapboxService.isWebGLSupported()) {
      this.mapError = true;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.width = this.holder.nativeElement.clientWidth;
      this.height = this.holder.nativeElement.clientHeight;
    }, 100);
  }

  ngOnDestroy(): void {
    this.redrawSubscription.unsubscribe();
  }

  public moveMarker(location: number[]) {
    if (this.mapError) {
      return;
    }
    this.markerLocation = location;

    if (!this.marker) {
      this.marker = this.mapboxService.drawMarker(
        this.mapComponent,
        this.markerLocation,
        '/assets/icons/markers/own.svg'
      );
    } else {
      this.marker.setLngLat([this.markerLocation[1], this.markerLocation[0]]);
    }

    this.mapboxService.setLocation(this.mapComponent, this.markerLocation);
  }

  public initMap(event) {
    this.holder.nativeElement.style.width = this.width + 'px';
    this.holder.nativeElement.style.height = this.height + 'px';
    this.mapComponent.mapInstance.resize();
    setTimeout(() => {
      this.loading = false;

      if (this.hasProjectMarker) {
        this.addProjectMarker();
      }

      this.mapboxService.setLocation(this.mapComponent, [
        this.project.locationLat,
        this.project.locationLong,
      ]);

      if (this.userLocation) {
        this.mapboxService.drawLocationCircle(
          this.mapComponent,
          this.userLocation,
          this.userAccuracy
        );
      }
    }, 100);

    this.fetchNotices();
  }

  public mapClick($event: MapMouseEvent) {
    if ($event.lngLat) {
      this.mapLocationSelected.emit([$event.lngLat.lat, $event.lngLat.lng]);
    }
  }

  public async fetchNotices() {
    this.noticeList = await this.smartReportingService.list(this.project);
    this.createListMarkers();
  }

  public insertNotice(notice: SmartReportingNotice) {
    const location = [notice.locationLat, notice.locationLong];

    setTimeout(() => {
      this.mapboxService.setLocation(this.mapComponent, location);
      this.mapComponent.mapInstance.setZoom(SmartReportingMapComponent.ZOOM);
    }, 200);

    notice.subscribed = true;
    notice.own = true;

    this.noticeList.push(notice);

    setTimeout(() => {
      const m: mapboxgl.Marker = this.mapboxService.drawMarker(
        this.mapComponent,
        location,
        `/assets/icons/markers/own.svg`
      );
      m.getElement()
        .querySelector('.marker')
        .classList.add('smart-reporting__drop-animation');
      this.mapMarkers.push(m);

      this.addMarkerListener(m, notice);

      setTimeout(() => {
        this.createListMarkers();
      }, 1000);
    }, 100);
  }

  public createListMarkers() {
    if (!this.noticeList) {
      return;
    }

    this.updateMovements();

    this.mapMarkers.forEach((it) => {
      it.remove();
    });
    this.mapMarkers = [];

    this.noticeList
      .filter((it) => {
        return (
          (it.own && this.currentFilterFormValue.own) ||
          (it.subscribed &&
            !it.own &&
            this.currentFilterFormValue.subscribed) ||
          (!it.own && !it.subscribed && this.currentFilterFormValue.regular)
        );
      })
      .forEach((item) => {
        const marker = item.own
          ? 'own'
          : item.subscribed
          ? 'subscribed'
          : 'regular';
        const count = Math.max(0, item.amountOfSubscribers);
        const markerUrl = `/assets/icons/markers/${marker}/${
          count > 9 ? '9p' : count
        }.svg`;

        const lat = item.locationLatModified
          ? item.locationLatModified
          : item.locationLat;
        const lng = item.locationLongModified
          ? item.locationLongModified
          : item.locationLong;

        const m: mapboxgl.Marker = this.mapboxService.drawMarker(
          this.mapComponent,
          [lat, lng],
          markerUrl,
          {},
          40,
          40
        );

        this.addMarkerListener(m, item);

        this.mapMarkers.push(m);
      });
  }

  public addMarkerListener(m: mapboxgl.Marker, item: SmartReportingNotice) {
    m.getElement().addEventListener('click', async () => {
      this.router.navigate(
        ['projects', this.project.slug, 'smart-reporting', item.id],
        { state: { notice: item } }
      );
    });
  }

  public toggleFilter() {
    this.filterOpen = !this.filterOpen;

    if (this.filterOpen) {
      this.filterForm.patchValue(this.currentFilterFormValue);
    }
  }

  public saveFilter() {
    const value = this.filterForm.value;

    this.currentFilterFormValue = value;
    this.filterOpen = false;

    this.createListMarkers();
  }

  public getLogoUrl(project: Project): string {
    return project.logo
      ? project.logoThumbnails.small
      : project.customer.logoThumbnails.small;
  }

  public hasLogo(project: Project): boolean {
    return !!(project.logo || project.customer.logo);
  }

  private createForm() {
    this.filterForm = this.fb.group({
      own: [true],
      regular: [true],
      subscribed: [true],
    });

    this.currentFilterFormValue = this.filterForm.value;
  }

  private addProjectMarker() {
    const wrapper = document.createElement('div');
    const el = document.createElement('div');
    el.innerHTML = `${
      this.hasLogo(this.project)
        ? `<div class="projects-map__logo"><img src="${this.getLogoUrl(
            this.project
          )}"></div>`
        : ''
    }
            <div class="projects-map__text">${this.project.name}</div>`;
    el.className = 'projects-map__marker';
    el.style.transform = 'translateZ(1px)';
    wrapper.append(el);

    this.projectMarker = new mapboxgl.Marker(wrapper)
      .setLngLat([this.project.locationLong, this.project.locationLat])
      .addTo(this.mapComponent.mapInstance);
  }

  public updateMovements() {
    const hadMovement = [];

    for (const notice of this.noticeList) {
      const clustering = [notice];

      for (const otherNotice of this.noticeList) {
        if (notice === otherNotice || hadMovement.includes(otherNotice)) {
          continue;
        }

        const lat1 = notice.locationLat;
        const lng1 = notice.locationLong;
        const lat2 = otherNotice.locationLat;
        const lng2 = otherNotice.locationLong;

        const distance = Math.sqrt(
          Math.pow(lng2 - lng1, 2) + Math.pow(lat2 - lat1, 2)
        );

        if (distance < 0.000002) {
          clustering.push(otherNotice);
        }
      }

      if (clustering.length > 1) {
        clustering.forEach((n) => hadMovement.push(n));

        let totalLat = 0;
        let totalLng = 0;
        for (const n of clustering) {
          totalLat += n.locationLat;
          totalLng += n.locationLong;
        }

        const centerLat = totalLat / clustering.length;
        const centerLng = totalLng / clustering.length;

        let i = 0;
        for (const n of clustering) {
          const dist = 0.00001;
          const angle = (i / clustering.length) * (2 * Math.PI);
          const offsetLat = Math.sin(angle) * dist;
          const offsetLng = Math.cos(angle) * dist;

          n.locationLatModified = centerLat + offsetLat;
          n.locationLongModified = centerLng + offsetLng;

          i++;
        }
      }
    }
  }

  get pwa() {
    return this.pwaService.isPwa();
  }
}
