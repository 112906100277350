import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: 'loader.component.html',
})
export class LoaderComponent {
  @Input() public loading: boolean;
  @Input() public full: boolean = false;
  @Input() public name: string = 'lines';
  @Input() public color: string = 'primary';
}
