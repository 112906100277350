import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Project } from 'src/app/interfaces/project';
import { LocationService } from 'src/app/services/location.service';
import { SmartReportingMapComponent } from '../../components/map/map.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';

@Component({
  selector: 'app-smart-reporting-map-page',
  templateUrl: './map.page.html',
})
export class SmartReportingMapPage implements OnInit {
  @Input() project: Project;
  @ViewChild(SmartReportingMapComponent, { static: true })
  mapComponent: SmartReportingMapComponent;
  userLocation: number[];
  userAccuracy: number;

  constructor(
    private locationService: LocationService,
    private router: Router,
    private app: CurrentWhiteLabelApplication,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.fetchCurrentLocation();
  }

  create() {
    this.router.navigate(['projects', this.project.slug, 'smart-reporting'], {
      state: { project: this.project },
    });
  }

  private async fetchCurrentLocation(): Promise<void> {
    try {
      const location = await this.locationService.getCurrentPosition();

      if (location) {
        this.userLocation = [
          location.coords.latitude,
          location.coords.longitude,
        ];
        this.userAccuracy = location.coords.accuracy;
      }
    } catch (error) {
      //Location denied by user
    }
  }
}
