<ion-content class="app planning">
  <div class="planning-detail">
    <div class="planning-detail__head">
      <div class="back-button">
        <ion-back-button
          mode="md"
          [defaultHref]="defaultHref"
        ></ion-back-button>
      </div>
      <div class="planning-detail__translate"></div>
    </div>

    <div class="planning-detail__content" *ngIf="project && phase">
      <div class="card__head">
        <div class="card__image default">
          <img
            *ngIf="project.logoThumbnails"
            appImgFallback
            [src]="project.logoThumbnails.small"
            width="40"
            height="40"
            alt="{{ project.name }}"
          />
          <img
            *ngIf="!project.logoThumbnails"
            appImgFallback
            [src]="project.customer.logoThumbnails?.small"
            width="40"
            height="40"
            alt="{{ project.name }}"
          />
        </div>
        <div class="card__title">
          <span>
            <span>{{ project.name }}</span>
            <span>{{ project.participants }}</span>
          </span>
          <span
            >{{ project.startDate | date:'longDate' }} • {{
            (project.amountOfFollowers == 1 ? 'card.followers.singular' :
            'card.followers.plural') | translate:{'followers':
            project.amountOfFollowers} }}</span
          >
        </div>
      </div>

      <div class="planning-detail__line"></div>

      <div class="planning-detail__inner">
        <h1>{{ phase.name }}</h1>
        <span class="date">
          {{ phase.startDate | dateType:phase.startType }} {{
          'project.planning.phase.until' | translate }} {{ phase.endDate |
          dateType:phase.endType}}
        </span>
        <div
          [html]="phase.text"
          [appTranslateText]="phase.text"
          [language]="project.language"
        ></div>
      </div>

      <div class="planning-detail__media" *ngIf="phase.attachments.length">
        <app-media-slider [attachments]="phase.attachments"></app-media-slider>
      </div>
    </div>
  </div>
</ion-content>
