import { Component, EventEmitter, Output } from '@angular/core';
import { UploadService } from '../../services/upload.service';
import { PwaService } from '../../services/pwa.service';
import { UploadedFile } from '../../interfaces/uploaded-file';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
})
export class FileInputComponent {
  @Output() public fileChanged = new EventEmitter<string | File>();
  public uploadedFile: string | File;
  public pwa = false;
  public static mimeTypes = ['image/jpeg', 'image/png', 'image/gif'];

  constructor(
    private uploadService: UploadService,
    private pwaService: PwaService
  ) {
    this.pwa = this.pwaService.isPwa();
  }

  /**
   * Clear attachment
   */
  public clearAttachment($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    this.uploadedFile = null;
    this.emit();
  }

  public async changeAttachment() {
    if (this.pwa) {
      return;
    }

    try {
      this.uploadedFile = await this.uploadService.selectUpload();
    } catch (e) {
      return;
    }
    this.emit();
  }

  public nativeInputChange($event) {
    const target = $event.target;
    const files = target.files;

    if (files.length > 0) {
      const file: File = files[0];

      if (FileInputComponent.mimeTypes.includes(file.type)) {
        this.uploadedFile = file;
        this.emit();
      }
    }
  }

  private emit() {
    this.fileChanged.emit(this.uploadedFile);
  }

  get mimeTypesAsString() {
    return FileInputComponent.mimeTypes.join(',');
  }
}
