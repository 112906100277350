import { HttpParams } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from 'src/app/interfaces/project';
import { SmartReportingService } from 'src/app/services/smart-reporting.service';
import { TicketService } from 'src/app/services/ticket.service';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';
import { ConversationType } from 'src/app/enums/conversation-type';
import { Conversation } from 'src/app/interfaces/conversation';

@Component({
  selector: 'app-project-conversations',
  templateUrl: './project-conversations.component.html',
})
export class ProjectConversationsComponent implements OnInit, OnChanges {
  @Input() project: Project;
  conversations: Conversation[] = [];
  ConversationType = ConversationType;
  loading: boolean = true;
  @Input() active: boolean;

  constructor(
    private ticketService: TicketService,
    private smartReportingService: SmartReportingService,
    private router: Router,
    private app: CurrentWhiteLabelApplication,
    private route: ActivatedRoute
  ) {
    this.smartReportingService.requestRedraw$.subscribe((notice) => {
      this.conversations = this.conversations.filter(
        (c) =>
          !c.smartReportingNotice || +c.smartReportingNotice.id !== +notice.id
      );
    });
  }

  ngOnChanges(changes) {
    if (changes.active.currentValue) {
      this.conversations = [];
      this.loading = true;
      this.getConversations();
      this.ticketService.updateLastViewData(this.project);
    }
  }

  ngOnInit() {}

  openConversation(conversation: Conversation) {
    if (conversation.type === ConversationType.TICKET) {
      this.router.navigate(
        [
          'projects',
          this.project.slug,
          'conversations',
          conversation.ticket.id,
        ],
        {
          state: { ticket: conversation.ticket },
        }
      );
    } else if (conversation.type === ConversationType.SMART_REPORTING_NOTICE) {
      this.router.navigate(
        [
          'projects',
          this.project.slug,
          'smart-reporting',
          conversation.smartReportingNotice.id,
        ],
        {
          state: { notice: conversation.smartReportingNotice },
        }
      );
    }
  }

  async getConversations() {
    await this.getTickets();
    await this.getNotices();
    this.conversations.sort(
      (c1, c2) =>
        c2.lastInteractionDate.getTime() - c1.lastInteractionDate.getTime()
    );

    this.loading = false;
  }

  async getTickets() {
    try {
      const response = await this.ticketService.getAllTickets(
        this.setURLParams(),
        this.project
      );
      this.conversations.push(
        ...response.member.map(
          (ticket) =>
            <Conversation>{
              type: ConversationType.TICKET,
              lastInteractionDate: new Date(ticket.lastInteractionDate),
              ticket: ticket,
            }
        )
      );
    } catch (error) {
      // Ignore
    }
  }

  async getNotices() {
    try {
      const response = await this.smartReportingService.listSubscribed(
        this.project
      );
      this.conversations.push(
        ...response.map(
          (notice) =>
            <Conversation>{
              type: ConversationType.SMART_REPORTING_NOTICE,
              lastInteractionDate: new Date(
                notice.responses?.length > 0
                  ? notice.responses[notice.responses.length - 1].createdAt
                  : notice.createdAt
              ),
              smartReportingNotice: notice,
            }
        )
      );
    } catch (error) {
      // ignore
    }
  }

  setURLParams() {
    return new HttpParams()
      .set('status', 'ALL')
      .set('order[updatedAt]', 'desc');
  }

  isToday(date: Date) {
    return new Date().toDateString() === new Date(date).toDateString();
  }
}
