<ion-content class="app opinion">
  <div class="opinion" *ngIf="project">
    <div class="opinion__head">
      <div class="back-button">
        <ion-back-button
          mode="md"
          [defaultHref]="defaultHref"
        ></ion-back-button>
      </div>
      <div class="opinion__translate"></div>
    </div>

    <div class="opinion__content">
      <ng-container *ngIf="!invalid; else invalidContent">
        <app-loader
          [full]="false"
          [loading]="!responses"
          [hidden]="responses"
        ></app-loader>
        <div [hidden]="!responses">
          <div class="opinion__options" [hidden]="sent">
            <div class="opinion__intro">
              <h1>{{ 'project.opinion.title'|translate }}</h1>
              <p
                *ngIf="currentIndex === 0"
                [appTranslateText]="(firstTime ? 'project.opinion.instruction' : 'project.opinion.instruction.repetitive')|translate"
                [language]="project.language"
                [html]="(firstTime ? 'project.opinion.instruction' : 'project.opinion.instruction.repetitive')|translate"
              ></p>
              <span *ngIf="!firstTime"
                >{{ currentIndex + 1 }}/{{ project.ratingThemes.length }}</span
              >
            </div>

            <form [formGroup]="form">
              <div formArrayName="themes">
                <div
                  class="opinion__options__item"
                  *ngFor="let theme of project.ratingThemes; let i=index;"
                  [formGroupName]="i"
                  [hidden]="!firstTime && currentIndex !== i"
                >
                  <h2
                    [appTranslateText]="theme.title"
                    [language]="project.language"
                    [html]="theme.title"
                  ></h2>
                  <app-smiley-input formControlName="score"></app-smiley-input>
                  <input
                    class="input-control"
                    formControlName="content"
                    [attr.aria-label]="'project.opinion.content.placeholder' | translate"
                    placeholder="{{ 'project.opinion.content.placeholder' | translate }}"
                  />
                </div>
              </div>
            </form>

            <div class="opinion__bottom">
              <button
                [hidden]="firstTime || currentIndex + 1 >= project.ratingThemes.length"
                tabindex="0"
                (click)="next()"
                [attr.disabled]="!getThemeControl(currentIndex)?.valid ? true : null"
              >
                {{ 'project.opinion.next' | translate }}
              </button>
              <button
                [hidden]="!firstTime && currentIndex + 1 < project.ratingThemes.length"
                tabindex="0"
                (click)="submit()"
                [attr.disabled]="(!canSubmit || loading) ? true : null"
                [class.isLoading]="loading"
              >
                {{ 'project.opinion.submit' | translate }}
                <app-loader [loading]="loading" color="light"></app-loader>
              </button>
            </div>
          </div>

          <div [hidden]="!error" class="opinion__error">
            {{ 'project.opinion.error'|translate }}
          </div>
        </div>
      </ng-container>

      <ng-template #invalidContent>
        <div class="opinion__invalid">
          <ng-container *ngIf="sent">
            <div class="opinion__intro">
              <h1>{{ 'project.opinion.success.title' | translate }}</h1>
              <p>
                {{ (repetitive ? 'project.opinion.sent.repetitive' :
                'project.opinion.sent')|translate }}
              </p>
            </div>
            <span [inlineSVG]="'/assets/illustrations/confetti.svg'"></span>
          </ng-container>
          <ng-container *ngIf="!sent">
            <div class="opinion__intro">
              <h1>{{ 'project.opinion.inactive.title' | translate }}</h1>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </div>
</ion-content>
