import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Project } from 'src/app/interfaces/project';

@Component({
  selector: 'app-card-contact',
  templateUrl: './card-contact.component.html',
})
export class CardContactComponent implements OnInit {
  @Input() project: Project;
  @Input() withLink: boolean = false;
  @Output() openLink = new EventEmitter<void>();

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {}

  open() {
    this.openLink.next();
  }
}
