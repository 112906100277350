import { Component, Input, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { AnalyticsType } from 'src/app/enums/analytics-type';
import { UpdateLikeType } from 'src/app/enums/update-like-type';
import { Project } from 'src/app/interfaces/project';
import { Update } from 'src/app/interfaces/update';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { UpdateService } from 'src/app/services/update.service';
import { WidgetService } from 'src/app/services/widget.service';

@Component({
  selector: 'app-social-buttons',
  templateUrl: './social-buttons.component.html',
})
export class SocialButtonsComponent implements OnChanges {
  @Input() update: Update;
  @Input() project: Project;
  @Input() likedType: UpdateLikeType = null;
  isPopoverVisible: boolean = false;
  likeTypes: any[];

  constructor(
    private analyticsService: AnalyticsService,
    private updateService: UpdateService,
    private widgetService: WidgetService
  ) {
    this.updateService.updateLiked$.subscribe((update) => {
      this.isPopoverVisible = false;
      if (update.id === this.update.id) {
        this.update = update;
        this.updateLikes();
      }
    });

    this.likeTypes = [
      { key: UpdateLikeType.LIKE, value: 0 },
      { key: UpdateLikeType.AWESOME, value: 0 },
      { key: UpdateLikeType.IDEA, value: 0 },
      { key: UpdateLikeType.WORRIED, value: 0 },
      { key: UpdateLikeType.DISLIKE, value: 0 },
    ];
  }

  ngOnChanges() {
    if (this.likedType && this.update.likesPerType[this.likedType] === 0) {
      this.update.likesPerType[this.likedType] = 1;
      this.update.likedBy = this.update.likedBy ? this.update.likedBy : 1;
    }
    this.updateLikes();
  }

  updateLikes() {
    this.likeTypes.forEach((likeType) => {
      likeType.value = this.update.likesPerType[likeType.key];
    });
  }

  showPopover() {
    this.isPopoverVisible = true;
  }

  hidePopover() {
    this.isPopoverVisible = false;
  }

  async toggleLike(type: UpdateLikeType) {
    this.isPopoverVisible = false;

    if (this.widgetService.getIsEmbedded()) {
      this.widgetService.openApp();
      return;
    }

    const update = this.update;
    const liked = !!this.likedType;
    const originalLikeType = this.likedType;
    const originalLikedBy = update.likedBy;
    const originalLikesPerType = update.likesPerType;

    try {
      if (liked) {
        update.likesPerType[originalLikeType]--;
        if (originalLikeType == type) update.likedBy--;
        await this.updateService.unlikeUpdate(update);
        this.likedType = null;
      }

      if (!liked || originalLikeType != type) {
        this.likedType = type;
        update.likesPerType[type]++;
        if (!liked) update.likedBy++;
        await this.updateService.likeUpdate(update, type);
      }

      this.updateService.updateLiked$.next(update);
      this.analyticsService.logEvent(
        AnalyticsType.UPDATE_REACT,
        {
          update_date: moment(update.publicationDate).format(),
          update_title: update.content.slice(0, 100),
          react_type: type,
          amount_of_reacts: update.likedBy,
        },
        this.project
      );
    } catch (error) {
      this.likedType = originalLikeType;
      update.likesPerType = originalLikesPerType;
      update.likedBy = originalLikedBy;
    }
  }
}
