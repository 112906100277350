import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'readMore',
})
export class ReadMorePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string, length?: number): string {
    if (length && (length <= 300 || value.length >= length)) {
      return value;
    }

    // Remove tag which has been partially truncated
    const partialTagRegex = /<(?!.*>)[^…]*/;
    let match = partialTagRegex.exec(value);
    if (match) value = value.substring(0, match.index) + '…';

    // Begining of open tags fix
    // Required because sometimes truncating can leave
    // tags open for html5 to auto close them
    // which can break styles applied
    const tagStack: string[] = [];
    const regex = /<(\w+)[^>]*>|<\/(\w+)>/g;

    while ((match = regex.exec(value)) !== null) {
      const openingTag = match[1];
      const closingTag = match[2];

      if (openingTag && !openingTag.includes('br')) {
        tagStack.push(openingTag);
      } else if (closingTag) {
        const index = tagStack.lastIndexOf(closingTag);
        if (index !== -1) {
          tagStack.splice(index, 1);
        }
      }
    }

    while (tagStack.length > 0) {
      const unclosedTag = tagStack.pop();
      value += `</${unclosedTag}>`;
    }

    // Make sure the ellipsis falls within the last tag
    if (value.slice(-1) === '…') {
      const lastTagIndex = value.lastIndexOf('<');
      value =
        value.substring(0, lastTagIndex) +
        '…' +
        value.substring(lastTagIndex, value.length - 1);
    }

    // End of open tags fix
    return (
      value +
      '<span class="read-more">' +
      this.translate.instant('card.update.readmore') +
      '</span>'
    );
  }
}
