<div class="media-slider">
  <swiper-container
    #slides
    (swiperslidechangetransitionstart)="checkVideos()"
    (swiperslidechange)="slideChanged($event)"
    [pager]="false"
    *ngIf="attachments.length"
    (click)="openModal($event)"
    [observer]="true"
    [observeParents]="true"
    zoom="true"
  >
    <swiper-slide
      [ngClass]="isDesktop ? 'swiper-no-swiping' : ''"
      *ngFor="let attachment of attachments; let i = index"
    >
      <!-- Image-->
      <ng-container *ngIf="isImage(attachment)">
        <div class="swiper-zoom-container">
          <img [src]="attachment.filePathThumbnails?.medium" />
        </div>
      </ng-container>

      <!-- Pdf -->
      <ng-container *ngIf="isPdf(attachment)">
        <div class="wrapper" [ngClass]="{ inModal: 'no-zoom' }">
          <img
            [src]="
              attachment.thumbnailPathThumbnails?.medium ||
              attachment.filePathThumbnails?.medium
            "
          />
          <div class="media-slider__overlay">
            <button
              *ngIf="isPdf(attachment)"
              class="pdf-button"
              (click)="openPDF(attachment); $event.stopPropagation()"
            >
              {{ 'feed.update.pdf.open' | translate }}
            </button>
          </div>
        </div>
      </ng-container>

      <!-- Video -->
      <ng-container *ngIf="isCustomVideo(attachment)">
        <div class="wrapper" [ngClass]="{ inModal: 'no-zoom' }">
          <video
            (click)="toggleVideo(i, attachment); $event.stopPropagation()"
            #video
            [attr.data-index]="i"
            [poster]="attachment.thumbnailPathThumbnails?.medium"
            playsinline
          >
            <source [src]="attachment.filePathThumbnails?.medium" />
          </video>
          <div class="media-slider__overlay" [hidden]="attachment['isPlaying']">
            <button
              *ngIf="isCustomVideo(attachment)"
              class="play-button"
              (click)="toggleVideo(i, attachment); $event.stopPropagation()"
              [inlineSVG]="'/assets/icons/play.svg'"
            ></button>
          </div>
        </div>
      </ng-container>

      <!-- Youtube -->
      <ng-container *ngIf="isYoutubeVideo(attachment)">
        <div class="wrapper" [ngClass]="{ inModal: 'no-zoom' }">
          <img
            [src]="
              'https://i.ytimg.com/vi/' + attachment.videoId + '/hqdefault.jpg'
            "
          />
          <div class="media-slider__overlay">
            <button
              class="play-button"
              (click)="openYoutube(attachment); $event.stopPropagation()"
              [inlineSVG]="'/assets/icons/youtube.svg'"
            ></button>
          </div>
        </div>
      </ng-container>

      <!-- 360 -->
      <ng-container *ngIf="is360(attachment)">
        <div class="media-slider__sphere" #photoSphere></div>
      </ng-container>

      <ng-container *ngIf="isOther(attachment)">
        <div class="media-slider__overlay" (click)="$event.stopPropagation()">
          <p>{{ 'update.attachment.error' | translate }}</p>
        </div>
      </ng-container>
    </swiper-slide>
  </swiper-container>
  <span
    *ngIf="attachments.length > 1 && !inModal"
    class="media-slider__pagination"
  >
    {{ currentSlide + 1 }} / {{ attachments.length }}
  </span>
</div>
