import {
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Phase } from 'src/app/interfaces/phase';
import { Event } from 'src/app/interfaces/event';
import { Project } from 'src/app/interfaces/project';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Update } from 'src/app/interfaces/update';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-project-planning',
  templateUrl: './project-planning.component.html',
})
export class ProjectPlanningComponent implements OnInit {
  @Input() phases: Phase[];
  @Input() updates: Update[];
  @Input() project: Project;
  currentPhase: number;
  planningLoaded: boolean = false;
  showPastPhases: boolean = false;
  planningItems: (Event | Phase)[];
  @ViewChildren('phaseElement') public phaseElements: QueryList<ElementRef>;

  constructor(
    private router: Router,
    private eventService: EventService
  ) {}

  ngOnInit() {
    this.initPhasesAndEvents();
  }

  public scroll(element: HTMLElement) {
    if (this.currentPhase === undefined) {
      return false;
    }
    const activePhaseElement = this.phaseElements.toArray()[this.currentPhase];
    const scroll =
      element.scrollTop +
      activePhaseElement.nativeElement.getBoundingClientRect().top;

    setTimeout(() => {
      element.scroll({
        behavior: 'smooth',
        top: scroll,
      });
    }, 300);
    return true;
  }

  openPhase(phase: Phase) {
    this.router.navigate(['projects', this.project.slug, 'phase', phase.id], {
      state: { phase: phase, project: this.project },
    });
  }

  async initPhasesAndEvents() {
    const events = this.eventService.sortEvents(await this.eventService.getEventsForProject(this.project));
    this.phases.forEach((phase, index) => {
      phase['index'] = index;
      if(moment().isAfter(phase.endDate)) {
        phase['isPast'] = true;
      }
      if (
        moment().isSameOrAfter(moment(phase.startDate), 'day') &&
        moment().isSameOrBefore(moment(phase.endDate), 'day')
      ) {
        this.currentPhase = index;
        phase['isCurrent'] = true;
      }
      phase.events = events.filter(event => moment(event.startDate).isBetween(phase.startDate, phase.endDate));
      phase.events.map(event => event.phase = phase);
    });

    this.planningItems = this.phases;
    this.planningItems.map(item => item['type'] = 'planning');
    const leftoverEvents = events.filter(event => !event.phase);
    leftoverEvents.forEach((item) => {
        item['type'] = 'event';
        if(moment().isAfter(item.endDate || item.startDate)) item['isPast'] = true;
        
    });

    this.planningItems = this.planningItems.concat(leftoverEvents);
    this.planningItems.sort(
      (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    );

    this.planningLoaded = true;
  }
}
