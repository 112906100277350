<div class="smart-reporting__map">
  <div class="smart-reporting__map__inner" #holder>
    <mgl-map
      #map
      *ngIf="!mapError"
      [style.width]="width + 'px'"
      [style.height]="height + 'px'"
      (mapLoad)="initMap($event)"
      [style]="'mapbox://styles/mapbox/streets-v11'"
      [zoom]="[17]"
      [maxZoom]="50"
      (mapClick)="mapClick($event)"
      [center]="[location[1], location[0]]"
    >
      <mgl-control
        *ngIf="pwa"
        mglNavigation
        [showCompass]="false"
        [position]="zoomPosition"
      ></mgl-control>
    </mgl-map>
    <div class="overlay" *ngIf="mapError">
      <div>{{ 'search.map.webgl.disabled' | translate }}</div>
    </div>
  </div>

  <div
    *ngIf="hasFilter && !mapError"
    class="smart-reporting__filter"
    (click)="toggleFilter()"
  >
    <span class="icon" [inlineSVG]="'/assets/icons/filter.svg'"></span>
    <span>{{ 'smart_reporting.filter.button' | translate }}</span>
  </div>

  <div
    class="smart-reporting__overlay"
    [hidden]="!filterOpen"
    [formGroup]="filterForm"
  >
    <p>{{ 'smart_reporting.filter.title' | translate }}</p>
    <ul>
      <li class="own">
        <span
          class="icon"
          [inlineSVG]="'/assets/icons/markers/default.svg'"
        ></span>
        <span>{{ 'smart_reporting.filter.own' | translate }}</span>
        <div>
          <input
            type="checkbox"
            formControlName="own"
            id="smart-reporting-own"
          />
          <label for="smart-reporting-own"></label>
        </div>
      </li>
      <li class="regular">
        <span
          class="icon"
          [inlineSVG]="'/assets/icons/markers/default.svg'"
        ></span>
        <span>{{ 'smart_reporting.filter.regular' | translate }}</span>
        <div>
          <input
            type="checkbox"
            formControlName="regular"
            id="smart-reporting-regular"
          />
          <label for="smart-reporting-regular"></label>
        </div>
      </li>
      <li class="subscribed">
        <span
          class="icon"
          [inlineSVG]="'/assets/icons/markers/default.svg'"
        ></span>
        <span>{{ 'smart_reporting.filter.subscribed' | translate }}</span>
        <div>
          <input
            type="checkbox"
            formControlName="subscribed"
            id="smart-reporting-subscribed"
          />
          <label for="smart-reporting-subscribed"></label>
        </div>
      </li>
    </ul>
    <button type="button" (click)="saveFilter()">
      {{ 'smart_reporting.filter.save' | translate }}
    </button>
  </div>
</div>
