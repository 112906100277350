import { Component, OnInit } from '@angular/core';
import { DeviceService } from '../../services/device.service';
import { Address } from 'src/app/interfaces/address';
import { ApiInit } from 'src/app/utils/api-init';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-address-list',
  templateUrl: './list.page.html',
})
export class AddressListPage implements OnInit {
  addresses: Address[] = [];
  isSettings: boolean = false;

  constructor(
    private apiInit: ApiInit,
    private router: Router,
    private route: ActivatedRoute,
    private deviceService: DeviceService
  ) {
    if (this.router.url.includes('settings')) this.isSettings = true;
  }

  ngOnInit() {}

  ionViewWillEnter() {
    this.apiInit.watch(() => {
      this.loadAddresses();
    });
  }

  async loadAddresses() {
    this.addresses = await this.deviceService.listAddresses();
  }

  async delete(address: Address, index, event) {
    event.stopPropagation();
    this.addresses.splice(index, 1);
    await this.deviceService.deleteAddress(address);
  }

  clear() {
    this.addresses.forEach((address) => {
      this.deviceService.deleteAddress(address);
    });
    this.addresses = [];
  }

  add() {
    this.router.navigate(['..', 'create'], { relativeTo: this.route });
  }

  next() {
    this.router.navigate(['onboarding', 'suggestion', 'list']);
  }
}
