import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { LiveStream } from 'src/app/interfaces/live-stream.interface';
import * as Hammer from 'hammerjs';
import Hls from 'hls.js';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PwaService } from 'src/app/services/pwa.service';

@Component({
  selector: 'app-live-stream',
  templateUrl: './live-stream.component.html',
})
export class LiveStreamComponent implements OnInit {
  @ViewChild('holder', { static: true }) public holder: ElementRef;
  @ViewChild('video', { static: true }) public video: ElementRef;
  @ViewChild('iframe', { static: true }) public iframe: ElementRef;
  @Input() public liveStreams: LiveStream[];

  public currentLiveStream: LiveStream;
  public currentIndex = 0;

  public showError: boolean = false;
  public loading: boolean = false;
  public iframeUrl: SafeResourceUrl;

  constructor(
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private pwaService: PwaService
  ) {}

  public ngOnInit(): void {
    const videoElement: HTMLVideoElement = this.video.nativeElement;

    const fullScreenListener = () => {
      setTimeout(() => videoElement.play(), 500);
    };

    this.showError =
      videoElement.canPlayType('application/x-mpegURL') === '' &&
      !Hls.isSupported();
  }

  start() {
    if (!this.currentLiveStream) {
      this.currentLiveStream = this.liveStreams[this.currentIndex];

      this.loading = true;

      const videoElement = this.video.nativeElement;

      if (this.currentLiveStream.iframe) {
        //this.checkIframe(); to-do: check this better
      } else if (Hls.isSupported()) {
        const hls = new Hls();

        if (!this.currentLiveStream.iframe) {
          hls.loadSource(this.currentLiveStream.url);
        }

        hls.attachMedia(videoElement);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          videoElement.play();
          this.loading = false;
          this.cd.detectChanges();
        });
      } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
        if (!this.currentLiveStream.iframe) {
          videoElement.src = this.currentLiveStream.url;
        }

        videoElement.addEventListener('loadedmetadata', () => {
          videoElement.play();
          this.loading = false;
          this.cd.detectChanges();
        });
      }
    }
  }
}
