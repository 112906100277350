import { Injectable } from '@angular/core';
import { PwaService } from './pwa.service';
import { subscribe } from 'on-screen-keyboard-detector';
import { Keyboard } from '@capacitor/keyboard';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class KeyboardService {
  public pwaInitialized = false;
  private pwaShowListeners: (() => void)[] = [];
  private pwaHideListeners: (() => void)[] = [];

  constructor(private pwaService: PwaService, private platform: Platform) {}

  public hideAccessoryBar() {
    if (!this.pwaService.isPwa() && this.platform.is('ios'))
      Keyboard.setAccessoryBarVisible({ isVisible: false });
  }

  public showAccessoryBar() {
    if (!this.pwaService.isPwa() && this.platform.is('ios'))
      Keyboard.setAccessoryBarVisible({ isVisible: true });
  }

  public hideKeyboard() {
    if (!this.pwaService.isPwa()) Keyboard.hide();
  }

  public registerWillShowListener(listener: () => void): void {
    if (!this.pwaService.isPwa()) {
      Keyboard.addListener('keyboardWillShow', listener);
    } else {
      this.pwaShowListeners.push(listener);
      this.initializePwa();
    }
  }

  public registerWillHideListener(listener: () => void): void {
    if (!this.pwaService.isPwa()) {
      Keyboard.addListener('keyboardWillHide', listener);
    } else {
      this.pwaHideListeners.push(listener);
      this.initializePwa();
    }
  }

  public registerDidHideListener(listener: () => void): void {
    if (!this.pwaService.isPwa()) {
      Keyboard.addListener('keyboardDidHide', listener);
    } else {
      this.pwaHideListeners.push(listener);
      this.initializePwa();
    }
  }

  private initializePwa() {
    if (this.pwaInitialized) return;

    const isMobile = window.screen.width <= 1100;
    if (isMobile) {
      subscribe((visibility) => {
        if (visibility === 'hidden') {
          this.pwaHideListeners.forEach((it) => it());
        } else {
          this.pwaShowListeners.forEach((it) => it());
        }
      });
    }

    this.pwaInitialized = false;
  }
}
