import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { MapComponent } from 'ngx-mapbox-gl';
import * as mapboxgl from 'mapbox-gl';
import { Project } from 'src/app/interfaces/project';
import { MapboxService } from 'src/app/services/mapbox.service';

@Component({
  selector: 'app-card-report',
  templateUrl: './card-report.component.html',
})
export class CardReportComponent implements AfterViewInit {
  @ViewChild(MapComponent) mapComponent: MapComponent;
  @ViewChild('holder', { static: true }) public holder: ElementRef;
  public width: number;
  public height: number;
  public loading = true;

  @Input('project') set projectSetter(project: Project) {
    this.project = project;
    this.location = [project.locationLat, project.locationLong];
  }
  @Input() language: string;

  public project: Project;
  public location: number[];
  public mapError: boolean = false;
  private projectMarker: mapboxgl.Marker;
  @Output() openLink = new EventEmitter<void>();

  constructor(private mapboxService: MapboxService) {}

  ngOnInit() {
    if (!this.mapboxService.isWebGLSupported()) {
      this.mapError = true;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.width = this.holder.nativeElement.clientWidth;
      this.height = this.holder.nativeElement.clientWidth / 1.6;
    }, 100);
  }

  public initMap() {
    this.mapComponent.mapInstance.resize();
    setTimeout(() => {
      this.loading = false;

      this.addProjectMarker();

      this.mapboxService.setLocation(this.mapComponent, [
        this.project.locationLat,
        this.project.locationLong,
      ]);
    }, 100);
  }

  public open() {
    this.openLink.next();
  }

  private addProjectMarker() {
    const wrapper = document.createElement('div');
    const el = document.createElement('div');
    el.innerHTML = `${
      this.project.customer.logo
        ? `<div class="projects-map__logo"><img src="${this.project.customer.logoThumbnails.small}"></div>`
        : ''
    }
            <div class="projects-map__text">${this.project.name}</div>`;
    el.className = 'projects-map__marker';
    el.style.transform = 'translateZ(1px)';
    wrapper.append(el);

    this.projectMarker = new mapboxgl.Marker(wrapper)
      .setLngLat([this.project.locationLong, this.project.locationLat])
      .addTo(this.mapComponent.mapInstance);
  }
}
