import { Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Project } from 'src/app/interfaces/project';
import { Target } from 'src/app/interfaces/target';
import { ProjectService } from 'src/app/services/project.service';
import { TargetService } from 'src/app/services/target.service';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';

@Injectable({
  providedIn: 'root',
})
export class TargetPickerService {
  private starDone = new Subject<number>();
  public starDone$ = this.starDone.asObservable();

  constructor(
    private targetService: TargetService,
    private projectService: ProjectService,
    private translateService: TranslateService,
    private actionSheetCtrl: ActionSheetController,
    private app: CurrentWhiteLabelApplication
  ) {}

  private async fetchTargets(project: Project): Promise<Target[]> {
    return new Promise((resolve, reject) => {
      this.targetService.getAllTargets(project).subscribe(
        (targets: Target[]) => {
          resolve(targets);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async handleAction(project: Project, target: Target) {
    try {
      if (this.app.isProjectApp()) {
        await this.projectService.updateTargetGroup(project, target);
        localStorage.setItem('projectAppModal', '1');
      } else {
        await this.projectService.starProject(project, target);
      }
    } catch (error) {
    } finally {
      this.starDone.next(project.id);
    }
  }

  async presentTargets(project: Project) {
    const targets = await this.fetchTargets(project);
    let buttons = [];
    targets.forEach((target) => {
      buttons.push({
        text: target.title,
        cssClass: 'targetBtn',
        role: 'confirm',
        handler: () => {
          this.handleAction(project, target);
        },
      });
    });

    if (!this.app.isProjectApp()) {
      buttons.push({
        text: this.translateService.instant('search.actionsheet.cancel'),
        role: 'cancel',
        cssClass: 'cancelBtn',
        handler: () => {
          this.starDone.next(project.id);
        },
      });
    }

    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translateService.instant('project.star.title'),
      buttons: buttons,
      backdropDismiss: this.app.isProjectApp() ? false : true,
      keyboardClose: this.app.isProjectApp() ? false : true,
    });

    document
      .querySelectorAll('.action-sheet-button')
      .forEach((button: HTMLElement) => {
        if (button.firstElementChild.textContent.length >= 38) {
          (button.firstElementChild as HTMLElement).style.justifyContent =
            'left';
        }
      });

    actionSheet.onDidDismiss().then((value) => {
      if (value.role !== 'confirm' && this.app.isProjectApp()) {
        this.presentTargets(project);
      }
    });

    await actionSheet.present();

    const actionSheetWidth = document.querySelector(
      '.action-sheet-group'
    ).clientWidth;
    document
      .querySelectorAll('.action-sheet-button')
      .forEach((button: HTMLElement) => {
        if (button.scrollWidth > button.offsetWidth) {
          const animationDuration = Math.round(
            (2.5 * button.scrollWidth) / button.offsetWidth
          );
          button.classList.add('marquee');
          (
            button.firstElementChild as HTMLElement
          ).style.animationDuration = `${animationDuration}s`;
          (button.firstElementChild as HTMLElement).style.setProperty(
            '--action-sheet-width',
            `${actionSheetWidth}px`
          );
        }
      });
  }
}
