<ion-content class="app survey">
  <ng-container *ngIf="ready">
    <div class="survey" *ngIf="survey && !invalid; else invalidContent">
      <ng-container *ngIf="!warning">
        <div class="survey__header">
          <div class="survey__header__inner">
            <div>
              <p>{{project?.name}}</p>
              <span>{{project?.customer.name}}</span>
            </div>
            <div (click)="closeSurvey()" class="survey__close">
              <span [inlineSVG]="'/assets/icons/close.svg'"></span>
            </div>
          </div>
        </div>

        <div class="survey__progress">
          <div
            class="survey__progress__inner"
            [ngStyle]="{'width': ((currentStep / survey.surveyQuestions.length) * 100) + '%'}"
          >
            <span></span>
          </div>
          <span
            >{{ 'survey.percentage' | translate: {percentage: (((currentStep /
            survey.surveyQuestions.length) * 100) || 0) | number: '1.0-0'}
            }}</span
          >
        </div>

        <ng-container *ngIf="!surveyStarted">
          <div class="survey__content">
            <div class="survey__text" [innerHTML]="survey.description"></div>
          </div>

          <div class="survey__footer">
            <div class="survey__start" (click)="startSurvey()">
              <span>{{ 'survey.start' | translate }}</span>
              <span
                class="icon"
                [inlineSVG]="'/assets/icons/arrow-right--white.svg'"
              ></span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="surveyStarted">
          <ng-container
            *ngFor="let question of survey.surveyQuestions; let i = index"
          >
            <div
              *ngIf="currentStep == i"
              class="survey__content"
              [ngClass]="getCurrentClass(question.type)"
            >
              <h1>{{question.title}}</h1>
              <div *ngIf="question.description" class="survey__text">
                <p>{{question.description}}</p>
              </div>
              <ng-container
                [formGroup]="question.answerForm"
                *ngIf="question.type != QuestionType.DESCRIPTION"
              >
                <app-form-group [form]="question.answerForm" errorPath="value">
                  <div
                    class="survey__textarea"
                    *ngIf="question.type == QuestionType.OPEN"
                  >
                    <textarea
                      formControlName="value"
                      [placeholder]="'survey.input.placeholder' | translate"
                    ></textarea>
                  </div>
                  <div *ngIf="question.type == QuestionType.RANGE">
                    <ul class="survey__scale" *ngIf="!question.withEmoji">
                      <li *ngFor="let number of question['rangeNumbers']">
                        <input
                          formControlName="value"
                          [value]="number"
                          type="radio"
                          id="scale{{number}}"
                        />
                        <label for="scale{{number}}">{{number}}</label>
                      </li>
                    </ul>
                    <ul class="survey__emojis" *ngIf="question.withEmoji">
                      <li *ngFor="let number of question['rangeNumbers']">
                        <input
                          formControlName="value"
                          [value]="number"
                          type="radio"
                          id="emoji{{number}}"
                        />
                        <label
                          for="emoji{{number}}"
                          [inlineSVG]="'assets/icons/emoji/smiley'+getEmojiForRangeNumber(number, question)+'.svg'"
                        ></label>
                      </li>
                    </ul>
                    <div class="survey__skip">
                      <input
                        formControlName="value"
                        [value]="0"
                        type="radio"
                        id="scale0"
                      />
                      <label for="scale0"
                        >{{ 'survey.range.no-opinion' | translate }}</label
                      >
                    </div>
                  </div>
                </app-form-group>
                <app-form-group
                  [form]="question.answerForm"
                  errorPath="questionOption"
                >
                  <ul
                    class="survey__radio"
                    *ngIf="question.type == QuestionType.MULTIPLE_CHOICE"
                  >
                    <li
                      *ngFor="let option of question.surveyQuestionOptions; let j = index"
                    >
                      <input
                        formControlName="questionOption"
                        type="radio"
                        [value]="option.id"
                        id="multiple{{j}}"
                      />
                      <label for="multiple{{j}}">{{option.title}}</label>
                      <div
                        *ngIf="option.action == 'goTo:additional' && question.answerForm.get('questionOption').value == option.id"
                        class="survey__textarea"
                      >
                        <textarea formControlName="value"></textarea>
                      </div>
                    </li>
                  </ul>
                  <ul
                    class="survey__checkbox"
                    *ngIf="question.type == QuestionType.CHECKBOX"
                  >
                    <li
                      *ngFor="let option of question.surveyQuestionOptions; let j = index"
                    >
                      <input type="hidden" formControlName="questionOption" />
                      <input
                        (change)="checkboxChanged(question, option, $event)"
                        [checked]="getIsChecked(question, option, j)"
                        type="checkbox"
                        [value]="option.id"
                        id="checkbox{{j}}"
                      />
                      <label for="checkbox{{j}}">{{option.title}}</label>
                      <div
                        *ngIf="option.action == 'goTo:additional' && question.answerForm.get('questionOptions').value.indexOf(option.id.toString()) > -1"
                        class="survey__textarea"
                      >
                        <textarea formControlName="value"></textarea>
                      </div>
                    </li>
                  </ul>
                </app-form-group>
              </ng-container>
            </div>
          </ng-container>

          <div
            class="survey__content closing"
            *ngIf="currentStep == survey.surveyQuestions.length"
          >
            <span inlineSVG="assets/icons/thumb_up.svg"></span>
            <h1>{{ 'survey.close.title' | translate }}</h1>
            <div (click)="closeSurvey()" class="survey__finish">
              {{ 'survey.close.button' | translate }}
            </div>
          </div>

          <div class="survey__footer">
            <div
              class="survey__previous"
              (click)="previous()"
              *ngIf="currentStep > 0 && currentStep != survey.surveyQuestions.length"
            >
              <span
                class="icon"
                [inlineSVG]="'/assets/icons/arrow-left.svg'"
              ></span>
              <span>{{ 'survey.previous' | translate }}</span>
            </div>
            <div
              *ngIf="currentStep < survey.surveyQuestions.length"
              class="survey__next"
              (click)="next()"
            >
              <span>
                <ng-container
                  *ngIf="currentStep >= 0 && currentStep + 1 < survey.surveyQuestions.length"
                >
                  {{ 'survey.next' | translate }}
                </ng-container>
                <ng-container
                  *ngIf="currentStep + 1 == survey.surveyQuestions.length"
                >
                  {{ 'survey.send' | translate }}
                </ng-container>
              </span>
              <span
                class="icon"
                [inlineSVG]="'/assets/icons/arrow-right--white.svg'"
              ></span>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="warning">
        <div class="survey__warning">
          <h2>{{ 'survey.close.confirm.title' | translate }}</h2>
          <p
            [innerHTML]="'survey.close.confirm.description' | translate: {percentage: (((currentStep / survey.surveyQuestions.length) * 100) || 0) | number: '1.0-0'}"
          ></p>
          <div class="buttons">
            <button
              class="button button--m button--flat bg--white"
              (click)="cancelClose()"
            >
              {{ 'survey.close.cancel.button' | translate }}
            </button>
            <button
              class="button button--m button--flat bg--red"
              (click)="confirmClose()"
            >
              {{ 'survey.close.confirm.button' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-template #invalidContent>
      <div class="survey__content closing">
        <span *ngIf="alreadySent" inlineSVG="assets/icons/thumb_up.svg"></span>
        <h1>
          <span *ngIf="alreadySent"
            >{{ 'survey.close.title' | translate }}</span
          >
          <span *ngIf="!alreadySent"
            >{{ 'survey.close.title.inactive' | translate }}</span
          >
        </h1>
        <div (click)="closeSurvey()" class="survey__finish">
          {{ 'survey.close.button' | translate }}
        </div>
      </div>
    </ng-template>
  </ng-container>

  <app-loader [full]="true" [loading]="!ready"></app-loader>
</ion-content>
