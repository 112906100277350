import { HttpParams } from '@angular/common/http';
import { Hydra } from '../interfaces/hydra';
import { IonContent, IonInfiniteScroll } from '@ionic/angular';
import * as isAsyncFunction from 'is-async-function';

export class Paginator<T> {
  public static DEFAULT_PER_PAGE = 7;

  public items: T[] = [];

  private method: (options: HttpParams) => Promise<Hydra<T>>;
  private itemHandler: (value: T[]) => any;
  private doneCallback: () => void;
  private baseUrlParams: HttpParams;
  private infiniteScroll: IonInfiniteScroll;
  private page: number = 0;
  private perPage: number = Paginator.DEFAULT_PER_PAGE;

  private total: number;
  private totalPages: number;
  private loading = true;
  private started = false;
  private initialized = false;
  private content: IonContent;

  private error: boolean = false;

  public init(
    method: (options: HttpParams) => Promise<Hydra<T>>,
    perPage: number = Paginator.DEFAULT_PER_PAGE,
    baseUrlParams: HttpParams = new HttpParams(),
    infiniteScroll?: IonInfiniteScroll,
    itemHandler?: (value: T[]) => void,
    doneCallback?: () => void,
    content?: IonContent
  ): void {
    this.method = method;
    this.perPage = perPage;
    this.baseUrlParams = baseUrlParams;
    this.itemHandler = itemHandler;
    this.doneCallback = doneCallback;
    this.content = content;

    if (infiniteScroll) {
      this.infiniteScroll = infiniteScroll;

      infiniteScroll.ionInfinite.subscribe((infinite) => {
        this.loadInfiniteScrollingContent();
      });
    }

    this.initialized = true;
  }

  public async nextPage(): Promise<void> {
    this.page++;
    this.started = true;

    await this.loadPage();
  }

  public async previousPage(): Promise<void> {
    this.page--;

    await this.loadPage();
  }

  public setBaseUrlParams(params: HttpParams): void {
    this.baseUrlParams = params;
  }

  public isLoading(): boolean {
    return this.loading;
  }

  public hasStarted(): boolean {
    return this.started;
  }

  public reset(): void {
    this.items = [];
    this.page = 0;
    this.total = null;
    this.totalPages = undefined;
    this.started = false;
    this.loading = true;

    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = false;
    }
  }

  public hasError(): boolean {
    return this.error;
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  public getPage(): number {
    return this.page;
  }

  public async loadPage() {
    if (this.totalPages === undefined || this.page <= this.totalPages) {
      this.loading = true;
      this.error = false;

      try {
        const response: Hydra<T> = await this.method(this.getUrlSearchParams());

        if (!this.total) {
          this.total = response.totalItems;
          this.totalPages = Math.ceil(this.total / this.perPage);
        }

        let data;
        if (this.items) {
          data = this.items.concat(response.member);
        } else {
          data = response.member;
        }

        if (this.itemHandler) {
          if (isAsyncFunction(this.itemHandler)) {
            await this.itemHandler(data);
          } else {
            this.itemHandler(data);
          }
        }

        if (this.isReady(response) && this.infiniteScroll) {
          this.infiniteScroll.disabled = true;
        }

        this.items = data;

        if (this.doneCallback) {
          this.doneCallback();
        }

        this.checkExtraLoad();
      } catch (error) {
        this.error = true;
        console.warn(error);
      } finally {
        this.loading = false;
      }
    } else {
      if (this.infiniteScroll) {
        this.infiniteScroll.disabled = true;
      }
    }
  }

  public isReady(response: Hydra<T>) {
    return this.page >= this.totalPages;
  }

  public async loadInfiniteScrollingContent(): Promise<void> {
    if (!this.isLoading() && this.started) {
      await this.nextPage();
    }

    if (this.infiniteScroll) {
      this.infiniteScroll.complete();
    }
  }

  private getUrlSearchParams(): HttpParams {
    this.baseUrlParams = this.baseUrlParams.set('page', this.page.toString());
    this.baseUrlParams = this.baseUrlParams.set(
      'perPage',
      this.perPage.toString()
    );

    return this.baseUrlParams;
  }

  private checkExtraLoad() {
    // checks whether the viewport was not entirely loaded
    if (this.content) {
      setTimeout(async () => {
        const element = await this.content.getScrollElement();
        const hasVerticalScrollbar =
          element.scrollHeight - 80 > element.clientHeight;

        if (!hasVerticalScrollbar) {
          this.loadInfiniteScrollingContent();
        }
      }, 100);
    }
  }
}
