import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { Project } from '../interfaces/project';
import { Hydra } from '../interfaces/hydra';
import { Phase } from '../interfaces/phase';

@Injectable({ providedIn: 'root' })
export class PhaseService {
  constructor(private apiService: ApiService) {}

  public fetch(project: Project): Promise<Phase[]> {
    let params = new HttpParams();

    params = params.set('order[startDate]', 'asc');

    return this.apiService
      .identifiedGet(
        '/api/v2/projects/' + project.slug + '/project-phases',
        params
      )
      .toPromise()
      .then((response: Hydra<Phase>) => response['hydra:member'])
      .then((list) => {
        list.forEach((phase: Phase) => {
          phase.startDate = moment(phase.startDate).toDate();
          phase.endDate = phase.endDate ? moment(phase.endDate).toDate() : null;

          phase.announcements = phase.announcements.sort(
            (a, b) =>
              (moment(a.startDate).toDate() as any) -
              (moment(b.startDate).toDate() as any)
          );
        });

        return list;
      });
  }

  public getPhase(id: number): Promise<Phase> {
    return this.apiService
      .identifiedGet('/api/v2/project-phases/' + id)
      .toPromise();
  }
}
