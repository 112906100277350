import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { Directory, Filesystem } from '@capacitor/filesystem';

@Injectable({ providedIn: 'root' })
export class FileService {
  private static STORAGE_REMOVALS_KEY = 'scheduledRemovals';

  constructor(private storage: StorageService) {}

  /**
   * Removes all scheduled files
   * @returns {Promise<void>}
   */
  public async removeScheduledFiles() {
    const files: string[] = await this.getScheduledRemovals();

    for (const file of files) {
      try {
        this.removeFile(file);
      } catch (error) {
        // file probably does not exist anymore, continue
        console.log(error);
      }
    }

    this.storage.set(FileService.STORAGE_REMOVALS_KEY, []);
  }

  /**
   * Schedule a removal
   * @param file
   * @returns {Promise<void>}
   */
  public async scheduleRemoval(file: string) {
    const removals: string[] = await this.getScheduledRemovals();
    removals.push(file);

    this.storage.set(FileService.STORAGE_REMOVALS_KEY, removals);
  }

  /**
   * Gets all scheduled removals
   * @returns {Promise<any>}
   */
  private async getScheduledRemovals(): Promise<string[]> {
    let value = await this.storage.get(FileService.STORAGE_REMOVALS_KEY);

    if (value == null) {
      value = [];
    }

    return value;
  }

  /**
   * Remove a file by its full path
   * @param path
   * @returns {Promise<void>}
   */
  private async removeFile(path: string) {
    try {
      await Filesystem.deleteFile({
        path: path,
      });
    } catch (error) {
      console.log(error);
    }
  }
}
