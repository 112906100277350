import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ProjectOpinionPage } from './project-opinion.page';
import { ComponentsModule } from 'src/app/components/components.module';
import { TranslateModule } from '@ngx-translate/core';

import { InlineSVGModule } from 'ng-inline-svg-2';
import { ConfettiService } from 'src/app/services/conffeti.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    TranslateModule.forChild(),
    IonicModule,
    InlineSVGModule,
  ],
  declarations: [ProjectOpinionPage],
  exports: [ProjectOpinionPage],
  providers: [ConfettiService],
})
export class ProjectOpinionPageModule {}
