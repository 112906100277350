import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from './date.pipe';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateType',
})
export class DateTypePipe implements PipeTransform {
  /**
   * @param datePipe
   */
  constructor(private datePipe: DatePipe, private translateService: TranslateService) {}

  /**
   * @param date
   * @param parameters, first is type: can be Q1, Q2, Q3, Q4, MONTH, YEAR or EXACT_DATE
   */
  transform(date: string | Date, ...parameters: any[]): any {
    const type = parameters[0];
    const short: boolean = parameters[1] && parameters[1] === 'short';
    const full: boolean = parameters[1] && parameters[1] === 'full';
    const withTime: boolean = !!parameters[2];

    switch (type) {
      case 'Q1':
      case 'Q2':
      case 'Q3':
      case 'Q4':
        return type + ' ' + this.datePipe.transform(date, 'yy');
      case 'MONTH':
        return this.datePipe.transform(
          date,
          short ? 'MMM' : full ? 'MMMM y' : 'MMM yy'
        );
      case 'YEAR':
        return this.datePipe.transform(date, 'y');
      case 'EXACT_DATE':
        return this.datePipe.transform(date, 'longDate');
      case 'EVENT':
        if(moment(date).isSame(moment(), 'day')) 
          return `${this.translateService.instant('today')} ${moment(date).format('LT')}`;
        else if(moment(date).isSame(moment().add(1, 'days'), 'day'))
          return `${this.translateService.instant('tomorrow')} ${moment(date).format('LT')}`;
        else {
          const lang = this.translateService.getBrowserLang();
          let format = lang == 'en' ? 'ddd, MMM D' : 'ddd D MMM';
          if(!moment(date).isSame(moment(), 'year')) format += ', yyyy';
          format += ' LT';
          return moment(date).format(format);
        }
    }
  }
}
