import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Customer } from 'src/app/interfaces/customer';

@Component({
  selector: 'app-card-organization',
  templateUrl: './card-organization.component.html',
})
export class CardOrganizationComponent {
  @Input() customer: Customer;
  @Input() language: string;
  @Output() openLink = new EventEmitter<void>();

  open() {
    this.openLink.next();
  }
}
