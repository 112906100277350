import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ProjectUpdatePage } from './project-update.page';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'src/app/components/components.module';
import { RouterModule } from '@angular/router';
import { ContentTextPipe } from 'src/app/pipes/content-text.pipe';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    InlineSVGModule,
    ComponentsModule,
    RouterModule,
    TranslateModule.forChild(),
  ],
  declarations: [ProjectUpdatePage],
  providers: [ContentTextPipe],
})
export class ProjectUpdatePageModule {}
