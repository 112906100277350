<!-- Livestream -->
<app-card
  [className]="
    liveStreamCardHighlighted ? 'card--summary highlighted' : 'card--summary'
  "
  *ngIf="project.liveStreams.length > 0"
>
  <div class="card--summary__inner livestream">
    <div class="card--summary__head">
      <div>
        <h2>{{ 'project.home.livestream.title' | translate }}</h2>
        <span>{{ 'project.home.livestream.badge' | translate }}</span>
      </div>
    </div>

    <div class="card--summary__description">
      <p
        [appTranslateText]="project.liveStreams[0].name"
        [html]="project.liveStreams[0].name"
        [language]="project.language"
      ></p>
    </div>

    <div class="card--summary__livestream">
      <div
        class="card--summary__livestream__overlay"
        [inlineSVG]="'/assets/icons/play.svg'"
        (click)="startLive()"
        *ngIf="!isWatchingLive"
      ></div>
      <app-live-stream
        [liveStreams]="project.liveStreams"
        #stream
      ></app-live-stream>
    </div>
  </div>
</app-card>

<!-- About -->
<app-card [className]="'card--summary'">
  <div class="card--summary__inner about">
    <div class="card--summary__head">
      <div>
        <h2>{{ 'project.home.about.title' | translate }}</h2>
      </div>
    </div>
    <div class="card__content">
      <div
        class="card--summary__description"
        [appTranslateText]="project.description | truncate : 300"
        [html]="project.description | truncate : 300"
        [language]="project.language"
      ></div>
    </div>
    <a class="card--summary__link" (click)="goToTab('about-us')">
      {{ 'project.home.about.showall' | translate : { project: project.name } }}
    </a>
  </div>
</app-card>

<!--Progress card-->
<!--<app-card [className]="'card--summary'">
    <div class="card--summary__inner progress">
        <div class="card--summary__head">
            <div>
                <h2>{{ 'project.home.progress.title' | translate }}</h2>
            </div>
        </div>
        <div class="card--summary__description">
            <p>{{ 'project.home.progress.description' | translate:{
                current: '2',
                next: '3',
                date: 'April 24, 2023'
            } }}</p>
        </div>
        <div class="card--summary__progress">
            <ul>-->
<!-- @Elan: [ Add class "active" for past/current phases ]-->
<!--<li *ngFor="let phase of phases; let index = index" [ngClass]=""></li>
</ul>
<span>
{{ 'project.home.progress.state' | translate:{current: '2', length: phases.length} }}
</span>
</div>
<a class="card--summary__link" (click)="goToTab('planning')">
{{ 'project.home.progress.showall' | translate:{'project': project.name} }}
</a>
</div>
</app-card>-->

<!-- Phases-->
<app-card [className]="'card--summary'" *ngIf="project.planning">
  <div class="card--summary__inner phases">
    <div class="card--summary__head">
      <div>
        <h2>{{ 'project.home.phases.title' | translate }}</h2>
      </div>
    </div>
    <app-translation-button
      *ngIf="shouldShowTranslation"
      (translate)="translatePhases()"
    ></app-translation-button>
    <div class="card--summary__title">
      <span class="icon" [inlineSVG]="'/assets/icons/rocket.svg'"></span>
      <h3>
        {{ 'project.home.phases.started' | translate }}
        {{ project.startDate | date : 'MMMM YYYY' }}
      </h3>
    </div>
    <ul class="card--summary__phases">
      <li
        *ngFor="let phase of phases; let index = index"
        [ngClass]="getPhaseTimeline(phase)"
      >
        <span
          class="icon"
          [inlineSVG]="
            getPhaseTimeline(phase) === 'current'
              ? '/assets/icons/arrow-right--thin.svg'
              : '/assets/icons/arrow-right-down.svg'
          "
        ></span>
        <span class="text">
          {{ phase.name }}
        </span>
        <span *ngIf="getPhaseTimeline(phase) === 'current'"
          >({{ 'project.home.phases.current' | translate }})</span
        >
      </li>
    </ul>
    <div class="card--summary__title" *ngIf="projectHasEnded">
      <span class="icon" [inlineSVG]="'/assets/icons/flag-planning.svg'"></span>
      <h3>
        {{ 'project.home.phases.ended' | translate }}
        {{ project.endDate | date : 'MMMM YYYY' }}
      </h3>
    </div>
    <a class="card--summary__link" (click)="goToTab('planning')">
      {{
        'project.home.phases.showall' | translate : { project: project.name }
      }}
    </a>
  </div>
</app-card>

<!-- Latest update -->
<app-card [className]="'card--summary'" *ngIf="latestUpdate">
  <div class="card--summary__inner update">
    <div class="card--summary__head">
      <div>
        <h2>{{ 'project.home.update.title' | translate }}</h2>
      </div>
    </div>
    <div class="card__content">
      <div
        class="card--summary__description"
        [appTranslateText]="latestUpdate.content | truncate : 300"
        [language]="project.language"
        [html]="latestUpdate.content | truncate : 300"
      ></div>
    </div>
    <a class="card--summary__link" (click)="goToTab('updates')">
      {{
        'project.home.update.showall' | translate : { project: project.name }
      }}
    </a>
  </div>
</app-card>

<!-- Child projects -->
<app-card
  [className]="'card--summary'"
  *ngIf="hasChildProjects && !isProjectApp"
>
  <div class="card--summary__inner children">
    <div class="card--summary__head">
      <div>
        <h2>{{ 'project.home.childprojects.title' | translate }}</h2>
      </div>
    </div>
    <ul class="card--summary__children project__children">
      <li
        *ngFor="let childProject of project.childrenProjects"
        (click)="openChildProject(childProject)"
        class="card--child"
      >
        <div class="card--child__image">
          <img
            *ngIf="childProject.logoThumbnails"
            appImgFallback
            [src]="childProject.logoThumbnails.small"
          />
          <img
            *ngIf="!childProject.logoThumbnails"
            appImgFallback
            [src]="childProject.customer.logoThumbnails?.small"
          />
        </div>
        <div class="card--child__content">
          <h3>{{ childProject.name }}</h3>
          <span>{{ childProject.participants }}</span>
          <span>{{
            (project.amountOfFollowers == 1
              ? 'card.followers.singular'
              : 'card.followers.plural'
            ) | translate : { followers: project.amountOfFollowers }
          }}</span>
        </div>
        <div
          class="card--child__icon"
          [inlineSVG]="'/assets/icons/arrow-right.svg'"
        ></div>
      </li>
    </ul>
    <a class="card--summary__link" (click)="goToTab('child-projects')">
      {{
        'project.home.childprojects.showall'
          | translate : { project: project.name }
      }}
    </a>
  </div>
</app-card>

<!-- Feedback monitor -->
<app-card
  [className]="'card--summary'"
  [type]="CardType.RATING"
  [project]="project"
  *ngIf="!project.activeSurvey && project.ratingThemes.length > 0"
></app-card>

<!-- Active survey -->
<app-card
  [className]="'card--summary'"
  [type]="CardType.SURVEY"
  [project]="project"
  [survey]="project.activeSurvey"
  *ngIf="project.activeSurvey"
  [language]="project.language"
></app-card>

<!-- Contact -->
<app-card
  [className]="'card--summary'"
  [type]="CardType.CONTACT"
  [project]="project"
  [withLink]="true"
  (switchTab)="goToTab('contact')"
></app-card>

<!-- Organization -->
<app-card
  [className]="'card--summary'"
  [type]="CardType.ORGANIZATION"
  [project]="project"
  *ngIf="project"
></app-card>
