import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Project } from '../../../interfaces/project';
import { ProjectService } from '../../../services/project.service';
import { ApiInit } from '../../../utils/api-init';
import { Update } from 'src/app/interfaces/update';
import { UpdateService } from 'src/app/services/update.service';
import { HttpParams } from '@angular/common/http';
import { WidgetService } from 'src/app/services/widget.service';
import { ProjectAboutUsComponent } from '../tabs/project-about-us/project-about-us.component';

@Component({
  selector: 'app-project-embed',
  templateUrl: './project-embed.page.html',
})
export class ProjectEmbedPage implements OnInit {
  project: Project;
  updates: Update[];
  header: string;
  theme: string;
  timeline: string;
  info: string;
  loading: boolean = true;
  showBackdrop: boolean = true;

  constructor(
    private projectService: ProjectService,
    private updateService: UpdateService,
    private route: ActivatedRoute,
    private apiInit: ApiInit,
    private widgetService: WidgetService,
    private sanitizer: DomSanitizer,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.apiInit.watch(async () => {
      this.projectService
        .getProject(this.route.snapshot.params.slug)
        .then(async (result: Project) => {
          this.project = result;
          this.widgetService.init(this.project);
          const params = new HttpParams().set('perPage', '21');
          this.updates = (
            await this.updateService.getAllUpdates(this.project, params)
          ).member;
          this.loading = false;
        })
        .catch(() => {});
    });
    this.header = this.route.snapshot.queryParams.header;
    this.theme = this.route.snapshot.queryParams.theme;
    this.timeline = this.route.snapshot.queryParams.timeline;
    this.info = this.route.snapshot.queryParams.info;
  }

  async showInfo() {
    const modal = await this.modalCtrl.create({
      component: ProjectAboutUsComponent,
      componentProps: {
        project: this.project,
        isModal: true,
      },
      cssClass: 'widget',
    });
    return await modal.present();
  }
}
