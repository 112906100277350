<ion-content class="app">
  <app-loader [full]="true" [loading]="!update || !project"></app-loader>
  <div class="app__content no-padding" *ngIf="update && project">
    <div class="update">
      <div class="update__top">
        <div class="update__back">
          <ion-back-button
            mode="md"
            [defaultHref]="defaultHref"
          ></ion-back-button>
        </div>
        <div></div>
      </div>

      <div class="update__header" (click)="openProject()">
        <div class="update__image">
          <img
            *ngIf="project.logoThumbnails"
            appImgFallback
            [src]="project.logoThumbnails.small"
            width="40"
            height="40"
            alt="{{ project.name }}"
          />
          <img
            *ngIf="!project.logoThumbnails"
            appImgFallback
            [src]="project.customer.logoThumbnails?.small"
            width="40"
            height="40"
            alt="{{ project.name }}"
          />
        </div>
        <div class="update__info">
          <h1>{{ project.name }}</h1>
          <span>{{ project.participants }}</span>
          <span>
            {{ update.publicationDate | date:'longDate' }} • {{
            update.publicationDate | date : 'shortTime' }}
          </span>
        </div>
        <div class="update__share" (click)="share($event)" *ngIf="canShare">
          <span [inlineSVG]="'/assets/icons/card/share.svg'"></span>
        </div>
      </div>

      <div class="update__media" *ngIf="update.attachments.length">
        <app-media-slider [attachments]="update.attachments"></app-media-slider>
      </div>
      <div class="update__content">
        <div class="update__content__note" *ngIf="!update.starredBy">
          {{ 'feed.card.area' | translate }}
        </div>
        <div
          [appTranslateText]="update.content"
          [language]="project.language"
          [html]="update.content | contentText"
        ></div>
      </div>

      <div class="update__events" *ngIf="update.events?.length">
        <app-events
          [events]="update.events"
          [update]="update"
          [language]="project.language"
        ></app-events>
      </div>

      <div class="update__bottom">
        <app-social-buttons
          [project]="project"
          [update]="update"
          [likedType]="likedType"
        ></app-social-buttons>
        <app-poll
          *ngIf="update.poll"
          [update]="update"
          [poll]="update.poll"
          [project]="project"
        ></app-poll>
      </div>
    </div>

    <div class="project__button__wrapper">
      <button
        class="project__button__wrapper__button"
        *ngIf="source !== 'Project Detail'"
        (click)="openProject()"
      >
        {{ 'project.show' | translate }}
      </button>
    </div>
  </div>
</ion-content>
