<ion-content class="app">
  <div class="app__header" *ngIf="isSettings">
    <span class="back-button">
      <ion-back-button defaultHref="settings" mode="md"></ion-back-button>
    </span>
    <span>{{ 'page.settings.addresses' | translate }}</span>
  </div>
  <div class="app__content address" [class.with-header]="isSettings">
    <div class="address__header" *ngIf="!isSettings">
      <a (click)="skip()">{{ 'onboarding.step.skip' | translate }}</a>
    </div>

    <div class="address__map" [class.fullscreen]="fullScreen">
      <app-loader [loading]="loading"></app-loader>
      <mgl-map
        *ngIf="!mapError"
        [hidden]="loading"
        [style]="'mapbox://styles/mapbox/streets-v11'"
        [attributionControl]="false"
        [minZoom]="2"
        (mapLoad)="mapLoaded($event)"
      >
        <mgl-control
          mglAttribution
          [compact]="true"
          position="bottom-left"
        ></mgl-control>
        <mgl-control
          *ngIf="isDesktop"
          mglNavigation
          [showZoom]="true"
          [showCompass]="false"
          position="bottom-right"
        >
        </mgl-control>
      </mgl-map>

      <button
        *ngIf="!loading"
        class="button button-fullscreen"
        (click)="toggleFullscreen()"
      >
        <span [inlineSVG]="'assets/icons/fullscreen.svg'"></span>
      </button>
    </div>

    <div class="address__content map" [class.fullscreen]="fullScreen">
      <div class="address__content__top">
        <h2>{{ 'address.create.title' | translate }}</h2>
        <p>{{ 'address.create.desc' | translate }}</p>
        <button
          class="follow"
          [disabled]="zoomError || loading"
          (click)="getAddress()"
        >
          {{ 'address.create.follow' | translate }}
        </button>
        <span *ngIf="zoomError">
          {{ 'address.create.zoom-error' | translate }}
        </span>
      </div>
    </div>
  </div>
</ion-content>
