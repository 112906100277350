import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ProjectSurveyPage } from './project-survey.page';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'src/app/components/components.module';
import { ConfettiService } from 'src/app/services/conffeti.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    InlineSVGModule,
    ComponentsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
  ],
  declarations: [ProjectSurveyPage],
  providers: [ConfettiService],
})
export class ProjectSurveyPageModule {}
