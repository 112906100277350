import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Phase } from 'src/app/interfaces/phase';
import { Project } from 'src/app/interfaces/project';
import { PhaseService } from 'src/app/services/phase.service';
import { ProjectService } from 'src/app/services/project.service';
import { ApiInit } from 'src/app/utils/api-init';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';

@Component({
  selector: 'app-project-planning',
  templateUrl: './project-planning.page.html',
})
export class ProjectPlanningPage implements OnInit {
  phase: Phase;
  project: Project;
  defaultHref: string = '/';

  constructor(
    private router: Router,
    private apiInit: ApiInit,
    private route: ActivatedRoute,
    private phaseService: PhaseService,
    private projectService: ProjectService,
    private app: CurrentWhiteLabelApplication
  ) {
    if (this.router.getCurrentNavigation()?.extras?.state?.phase) {
      this.phase = this.router.getCurrentNavigation().extras.state.phase;
      this.project = this.router.getCurrentNavigation().extras.state.project;
    } else {
      this.loadPhase();
    }
  }

  async loadPhase() {
    this.apiInit.watch(async () => {
      try {
        this.phase = await this.phaseService.getPhase(
          this.route.snapshot.params.id
        );
        this.project = await this.projectService.getProject(
          this.route.snapshot.params.slug
        );
      } catch (error) {
        // ignore
      }
      this.defaultHref = this.app.isProjectApp
        ? '/project'
        : '/projects/' + this.project.slug;
    });
  }

  ngOnInit() {}
}
