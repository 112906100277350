<div class="card--summary__inner">
  <div
    class="card--summary__description"
    [appTranslateText]="project.description | truncate : 300"
    [language]="language"
    [html]="project.description | truncate : 300"
  ></div>
</div>
<div class="card--summary__map" #holder *ngIf="!mapError">
  <mgl-map
    (mapLoad)="initMap()"
    [style]="'mapbox://styles/mapbox/streets-v11'"
    [zoom]="[16]"
    [maxZoom]="50"
    [center]="[location[1], location[0]]"
    [interactive]="false"
  >
  </mgl-map>
</div>
<div class="card--summary__inner">
  <a class="card--summary__button" (click)="open()">
    <span>{{ 'card.report.action' | translate }}</span>
    <span
      class="icon"
      [inlineSVG]="'/assets/icons/card/arrow-right.svg'"
    ></span>
  </a>
</div>
