import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { LiveStreamComponent } from 'src/app/components/live-stream/live-stream.component';
import { Phase } from 'src/app/interfaces/phase';
import { Update } from 'src/app/interfaces/update';
import { Project } from 'src/app/interfaces/project';
import { CardType } from 'src/app/enums/card-type';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleHelper } from 'src/app/utils/module-helper';
import { Module } from 'src/app/enums/module';
import { TranslationService } from 'src/app/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { AppTranslationButtonComponent } from 'src/app/components/translation-button/translation-button.component';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';
import * as moment from 'moment';

@Component({
  selector: 'app-project-home',
  templateUrl: './project-home.component.html',
})
export class ProjectHomeComponent implements OnInit, OnChanges {
  @Input() project: Project;
  @Input() phases: Phase[];
  @Input() latestUpdate: Update;
  @Input() startLivestream: boolean;
  @Output() startedLivestream: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() switchTab: EventEmitter<string> = new EventEmitter<string>();

  isWatchingLive: boolean = false;
  hasChildProjects: boolean = false;
  isProjectApp: boolean = false;

  public shouldShowTranslation: boolean = false;
  private phasesTranslated: boolean = false;
  private originalPhaseNames: string[] = [];
  public projectHasEnded: boolean = false;

  @ViewChild(LiveStreamComponent) public liveStream: LiveStreamComponent;
  @ViewChild(AppTranslationButtonComponent)
  private phaseTranslationButton: AppTranslationButtonComponent;

  public liveStreamCardHighlighted = false;

  CardType = CardType;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private translateService: TranslateService,
    private app: CurrentWhiteLabelApplication
  ) {}

  ngOnInit() {
    this.projectHasEnded = moment().isAfter(this.project.endDate);
    if (
      this.project.language &&
      this.project.language !=
        this.translateService.getBrowserLang().split('-')[0]
    ) {
      this.shouldShowTranslation = true;
    }
    this.isProjectApp = this.app.isProjectApp();
  }

  ngOnChanges() {
    if (this.startLivestream) {
      this.liveStreamCardHighlighted = true;
      setTimeout(() => {
        this.liveStreamCardHighlighted = false;
      }, 1500);
      this.startLive();
      setTimeout(() => {
        this.startedLivestream.emit(true);
      }, 100);
    }

    this.hasChildProjects =
      this.project.isParentProject &&
      this.project.childrenProjects.length > 0 &&
      ModuleHelper.hasModule(this.project, Module.CHILD_PROJECTS);
  }

  getPhaseTimeline(phase: Phase): string {
    let className = 'after';
    if (moment().isAfter(phase.endDate)) className = 'before';
    else if (moment().isBetween(phase.startDate, phase.endDate))
      className = 'current';
    return className;
  }

  openChildProject(project) {
    this.router.navigate(['projects', project.slug], {
      state: { previousProject: this.router.url },
    });
  }

  goToTab(event) {
    this.switchTab.next(event);
  }

  startLive() {
    this.isWatchingLive = true;
    setTimeout(() => this.liveStream.start(), 200);
  }

  stopLive() {
    this.isWatchingLive = false;
  }

  public translatePhases() {
    if (this.phasesTranslated) {
      this.phases.forEach(async (p: Phase, index: number) => {
        p.name = this.originalPhaseNames[index];
      });
      this.phasesTranslated = false;
      this.originalPhaseNames = [];
      this.phaseTranslationButton.translated = false;
      return;
    }

    this.phases.forEach(async (p: Phase) => {
      try {
        const translation = await this.translationService.getTranslation(
          p.name,
          this.translateService.getBrowserLang(),
          this.project.language
        );
        this.originalPhaseNames.push(p.name);
        p.name = translation.text;
      } catch (error) {
        console.log(error);
      }
    });

    this.phaseTranslationButton.translated = true;
    this.phasesTranslated = true;
  }
}
