<button *ngIf="isModal" class="close" (click)="dismissModal()">
  <span inlineSVG="/assets/icons/close.svg"></span>
</button>

<app-card [className]="'card--summary'">
  <div class="card--summary__inner about">
    <div class="card--summary__head">
      <div>
        <h2>{{ 'project.home.about.title' | translate }}</h2>
      </div>
    </div>
    <div class="card__content">
      <div
        class="card--summary__description"
        [appTranslateText]="project.description"
        [html]="project.description"
        [language]="project.language"
      ></div>
      <div class="card--summary__image" *ngIf="project.customer.logoThumbnails">
        <img
          [src]="project.customer.logoThumbnails.medium"
          alt="Project Image"
        />
      </div>
    </div>
  </div>
</app-card>

<app-card
  [className]="'card--summary'"
  *ngFor="let category of documentCategories"
>
  <div class="card--summary__inner documents">
    <div class="card--summary__head">
      <div>
        <h2>{{ category.title }}</h2>
      </div>
    </div>
    <div class="card__content">
      <div
        class="card--summary__description"
        [appTranslateText]="category.content"
        [html]="category.content"
        [language]="project.language"
      ></div>
    </div>
    <ul class="card--summary__documents">
      <li
        *ngFor="let document of category.documents"
        class="document"
        (click)="openDocument(document)"
      >
        <div class="document__image">
          <img
            *ngIf="isImage(document) || isPdf(document)"
            [src]="document.filePathThumbnails?.small"
          />
          <img
            *ngIf="isVideo(document)"
            [src]="document.thumbnailPathThumbnails?.small"
          />
          <img *ngIf="isYoutube(document)" src="/assets/icons/youtube.svg" />
        </div>
        <div class="document__content">
          <span>{{ document.title }}</span>
        </div>
        <button class="document__download">
          <span [inlineSVG]="'/assets/icons/download.svg'"></span>
        </button>
      </li>
    </ul>
  </div>
</app-card>

<app-card [className]="'card--summary'" *ngIf="!mapError" #mapContainer>
  <div class="card--summary__inner map">
    <div class="card--summary__head">
      <div>
        <h2>{{ 'project.home.about.map.title' | translate }}</h2>
      </div>
    </div>
    <div class="card--summary__map">
      <mgl-map
        #map
        *ngIf="mapShouldLoad"
        (mapLoad)="initMap()"
        [style]="'mapbox://styles/mapbox/streets-v11'"
        [style.height]="260 + 'px'"
        [style.width]="100 + '%'"
        [zoom]="[17]"
        [center]="[project.locationLong, project.locationLat]"
      >
        <mgl-control mglNavigation [showCompass]="false"></mgl-control>
      </mgl-map>
    </div>
  </div>
</app-card>

<app-card
  [className]="'card--summary'"
  [type]="CardType.ORGANIZATION"
  [project]="project"
></app-card>
