import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { CardType } from 'src/app/enums/card-type';
import { Project } from 'src/app/interfaces/project';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-project-child-projects',
  templateUrl: './project-child-projects.component.html',
})
export class ProjectChildProjectsComponent implements OnInit {
  @Input() childProjects: Project[];
  CardType = CardType;

  constructor(private deviceService: DeviceService) {}

  async ngOnInit() {
    try {
      let projectIds = this.childProjects.map(({ id }) => id);
      if (projectIds.length === 0) {
        return;
      }
      const params = new HttpParams().set('project_ids', projectIds.join(','));

      const response = await this.deviceService.getData(params);
      this.childProjects = this.childProjects.map((project) => {
        project.starred = response.projects[project.id].starred;
        project.distance = response.projects[project.id].distance;
        return project;
      });
    } catch (error) {}
  }
}
