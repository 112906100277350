import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TicketMessage } from 'src/app/interfaces/ticket-message';
import { Paginator } from 'src/app/utils/paginator';
import { HttpParams } from '@angular/common/http';
import { ToastService } from 'src/app/services/toast.service';
import { IonContent, IonInfiniteScroll, ToastController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadService } from 'src/app/services/upload.service';
import { ApiInit } from 'src/app/utils/api-init';
import { TicketService } from 'src/app/services/ticket.service';
import { UploadedFile } from 'src/app/interfaces/uploaded-file';
import { Ticket } from 'src/app/interfaces/ticket';
import { Base64Helper } from 'src/app/utils/base64-helper';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { RouteNavParams } from 'src/app/utils/route-nav-params';
import { ActivatedRoute, Router } from '@angular/router';
import { PwaService } from 'src/app/services/pwa.service';
import { FileInputComponent } from 'src/app/components/file-input/file-input.component';
import { Filesystem } from '@capacitor/filesystem';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from 'src/app/services/translation.service';
import { AppTranslationButtonComponent } from 'src/app/components/translation-button/translation-button.component';
import { SettingsService } from 'src/app/services/settings.service';
import { PushService } from '../../services/push.service';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';
import { AnalyticsType } from 'src/app/enums/analytics-type';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.page.html',
})
export class ConversationPage implements OnInit {
  private static REFRESH_INTERVAL = 15000;
  private static SCROLL_DOWN_THRESHOLD = 250;

  public ticket: Ticket;
  public paginator: Paginator<TicketMessage> = new Paginator<TicketMessage>();
  public form: FormGroup;
  public feedbackControl: FormControl;
  public uploadedFile: UploadedFile;
  public askPush = false;
  public pwa = false;
  @ViewChild('content', { static: true }) public scroll: IonContent;
  @ViewChild('attachment') public attachment: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild(AppTranslationButtonComponent)
  private translationButton: AppTranslationButtonComponent;

  @ViewChild(IonInfiniteScroll, { static: true }) private infiniteScroll;
  private _timer: number;
  fullScreen: boolean = false;
  showForm: boolean = false;
  loading: boolean = false;
  defaultHref: string = '/';
  public shouldShowTranslation: boolean = false;
  private messagesTranslated: boolean = false;
  private originalMessages: string[] = [];

  constructor(
    private ticketService: TicketService,
    private apiInit: ApiInit,
    private formBuilder: FormBuilder,
    private uploadService: UploadService,
    private sanitizer: DomSanitizer,
    private toastService: ToastService,
    private changeDetectorRef: ChangeDetectorRef,
    private navParams: RouteNavParams,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private pwaService: PwaService,
    private translate: TranslateService,
    private translationService: TranslationService,
    private toastCtrl: ToastController,
    private pushService: PushService,
    private app: CurrentWhiteLabelApplication
  ) {
    this.pwa = this.pwaService.isPwa();

    if (!this.navParams.get('ticket')) {
      this.activatedRoute.params.subscribe(async (params) => {
        if (params.id && !this.navParams.get('ticket')) {
          this.load(params.id);
        }
      });
    }

    this.ticketService.ticketCreated.subscribe((ticket) => {
      //to-do: insert message here, necessary if we change the tabs to only load once
    });

    this.createForm();
    this.createFeedbackControl();
  }

  toggleFullScreen() {
    this.fullScreen = true;
  }

  toggleForm() {
    this.showForm = true;
  }

  /**
   * Called upon view entrance
   */
  async ngOnInit() {
    if (this.navParams.get('ticket')) {
      this.ticket = this.navParams.get('ticket');
    }

    // override paginator determination of finished loading
    this.paginator.isReady = (hydra) => {
      return hydra.totalItems === hydra.member.length;
    };

    this.pushService.showTip();

    this.paginator.init(
      (params) => {
        return new Promise((resolve, reject) => {
          this.apiInit.watch(async () => {
            if (
              this.ticket.project.language &&
              this.ticket.project.language !=
                this.translate.getBrowserLang().split('-')[0]
            ) {
              this.shouldShowTranslation = true;
            }

            try {
              const filtered = this.paginator.items.filter(
                (item) => !item.loading
              );
              const lastDate: Date =
                filtered.length === 0
                  ? null
                  : filtered[filtered.length - 1].createdAt;

              resolve(
                await this.ticketService.getMessages(
                  this.ticket,
                  this.paginator.getPage() === 1 || true ? 'all' : 'before',
                  lastDate,
                  50,
                  'asc'
                )
              );
            } catch (error) {
              console.error(error);

              reject(error);
            }
          });
        });
      },
      Paginator.DEFAULT_PER_PAGE,
      new HttpParams(),
      this.infiniteScroll,
      null,
      async () => {
        if (this.paginator.getPage() === 1) {
          this.scrollToBottom();
        } else {
          const scroll = await this.scroll.getScrollElement();

          // store the position right before updating the UI
          const bottom = scroll.scrollHeight - scroll.scrollTop;

          this.scrollFromBottom(bottom);
        }

        this.changeDetectorRef.detectChanges();
      }
    );

    if (this.ticket != null) {
      // method will be re-called when we fetched the ticket meta-data
      this.startLoadingMessages();
      this.setFeedback();
    }
  }

  setFeedback() {
    // set provided feedback value
    this.feedbackControl.patchValue(this.ticket.feedback, { emitEvent: false });
  }

  startLoadingMessages() {
    if (this.paginator.isInitialized()) {
      this.setupRefreshTimer();

      if (this.paginator.items.length === 0 && !this.paginator.hasStarted()) {
        this.paginator.nextPage();
      }
    }
  }

  ionViewDidLeave() {
    clearInterval(this._timer);
  }

  /**
   * Send a message
   */
  async send() {
    if (this.form.valid) {
      this.loading = true;
      const data = this.form.value;
      const ticket: TicketMessage = {
        content: data.message,
        animate: true,
        createdAt: new Date(),
        loading: true,
        projectUser: null,
      };

      this.paginator.items = <any>this.paginator.items.concat(<any>[ticket]);

      this.form.reset();

      let attachment = null;

      if (this.uploadedFile != null) {
        // first upload the concept
        const attachment = this.uploadedFile.data;
        ticket.attachmentPreview = this.uploadedFile.base64;

        this.uploadedFile = null;

        const uploadResult: any = await this.uploadService.upload(
          'ticket-message-attachment',
          attachment
        );

        ticket.attachment = uploadResult.file;
      }

      const response: any = await this.ticketService.replyTicket(
        this.ticket,
        ticket
      );

      ticket.id = response.id;
      ticket.loading = false;
      this.showForm = false;
      this.loading = false;

      this.scrollToBottom();

      this.analyticsService.logEvent(
        AnalyticsType.CONTACT_MESSAGE,
        {
          name: this.ticket.name,
          subject: this.ticket.subject,
          attached_media: ticket.attachment ? ticket.attachment : '',
          action: 'reply',
        },
        this.ticket.project
      );
    }
  }

  /**
   * Normal event change doesn't work
   * @param $event
   */
  public async changeAttachment() {
    if (this.pwa) {
      return;
    }

    try {
      const file = await this.uploadService.selectUpload();

      if (file != null) {
        this.uploadedFile = {
          data: file,
          base64: null,
        };

        const result = await Filesystem.readFile({ path: file });
        this.uploadedFile.base64 = Base64Helper.formatBase64JpegImage(
          result.data
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Clear attachment
   */
  public clearAttachment() {
    this.uploadedFile = null;
    if (this.fileInput) this.fileInput.nativeElement.value = '';
  }

  /**
   * @param message
   */
  public getAttachment(message: TicketMessage) {
    let url;

    if (message.attachmentPreview != null) {
      url = message.attachmentPreview;
    } else {
      url = message.attachmentThumbnails.medium;
    }

    return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
  }

  /**
   * Initialize the refresh timer
   */
  private setupRefreshTimer() {
    this._timer = <any>setInterval(async () => {
      if (!this.paginator.items || this.paginator.items.length === 0) {
        return;
      }

      const messages = this.paginator.items.filter(
        (item) => item.projectUser === null
      );
      const lastDate: Date = messages[messages.length - 1].createdAt;

      this.fetchStatus();

      let items = [];
      try {
        items = (
          await this.ticketService.getMessages(
            this.ticket,
            'after',
            lastDate,
            10,
            'asc'
          )
        ).member;
      } catch (error) {
        return;
      }

      const newItems = items.filter((item) => {
        return (
          this.paginator.items.filter((previous) => previous.id == item.id)
            .length === 0
        );
      });

      this.paginator.items = this.paginator.items.concat(newItems);

      const element = await this.scroll.getScrollElement();
      const distanceToBottom =
        element.scrollHeight - element.scrollTop - element.clientHeight;

      if (
        items.length > 0 &&
        distanceToBottom >= ConversationPage.SCROLL_DOWN_THRESHOLD &&
        newItems.length > 0
      ) {
        this.showToast(newItems.length);
      } else if (
        distanceToBottom < ConversationPage.SCROLL_DOWN_THRESHOLD &&
        newItems.length > 0
      ) {
        this.scrollToBottom();
      }
    }, ConversationPage.REFRESH_INTERVAL);
  }

  public async showToast(length: number) {
    const toast = await this.toastCtrl.create({
      message:
        length == 1
          ? this.translate.instant('conversation.new.singular')
          : this.translate.instant('conversation.new.plural'),
      duration: ToastService.DEFAULT_DURATION,
      buttons: [
        {
          text: this.translate.instant('conversation.new.show'),
          handler: () => {
            this.scrollToBottom();
          },
        },
      ],
    });

    toast.present();
  }

  /**
   * Check if ticket was closed
   * @returns {Promise<void>}
   */
  private async fetchStatus() {
    try {
      const statusData = await this.ticketService.fetchStatus(this.ticket);
      this.ticket.status = statusData.status;
      this.ticket.requestedFeedback = statusData.requestedFeedback;
    } catch (error) {
      // ignore
    }
  }

  private createFeedbackControl() {
    this.feedbackControl = this.formBuilder.control({}, Validators.required);
    this.feedbackControl.valueChanges.subscribe(async (value: number) => {
      if (value != null) {
        try {
          await this.ticketService.provideFeedback(this.ticket, value);

          this.ticket.feedback = value;
        } catch (error) {
          console.error(error);

          this.toastService.show('conversation.feedback.failed');
        }
      }
    });
  }

  private createForm() {
    this.form = this.formBuilder.group({
      message: ['', Validators.required],
    });
  }

  private scrollToBottom() {
    this.scrollFromBottom(0);
  }

  private scrollFromBottom(amount: number) {
    setTimeout(async () => {
      const element = await this.scroll.getScrollElement();

      element.scrollTop = element.scrollHeight - amount;
    });
  }

  private async load(id: number) {
    this.apiInit.watch(async () => {
      this.ticket = await this.ticketService.getTicket(id);

      this.defaultHref = this.app.isProjectApp()
        ? '/project'
        : '/projects/' + this.ticket.project.slug;

      this.startLoadingMessages(); // manually re-call entrance of view
      this.setFeedback();

      this.changeDetectorRef.detectChanges();
    });
  }

  public async nativeInputChange($event) {
    const target = $event.target;
    const files = target.files;

    if (files.length > 0) {
      const file: File = files[0];

      if (FileInputComponent.mimeTypes.includes(file.type)) {
        this.uploadedFile = {
          data: file as any,
          base64: await this.readFile(file),
        };
      }
    }
  }

  public readFile(file: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => resolve(null);
    });
  }

  get mimeTypesAsString() {
    return FileInputComponent.mimeTypes.join(',');
  }

  public async translateMessages() {
    if (this.messagesTranslated) {
      this.paginator.items.forEach(async (m: TicketMessage, index: number) => {
        m.content = this.originalMessages[index];
      });
      this.messagesTranslated = false;
      this.originalMessages = [];
      this.translationButton.translated = false;
      return;
    }

    this.paginator.items.forEach(async (m: TicketMessage) => {
      try {
        const translation = await this.translationService.getTranslation(
          m.content,
          this.translate.getBrowserLang(),
          this.ticket.project.language
        );
        this.originalMessages.push(m.content);
        m.content = translation.text;
      } catch (error) {
        console.log(error);
      }
    });

    this.translationButton.translated = true;
    this.messagesTranslated = true;
  }
}
