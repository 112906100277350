import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { ProjectEmbedPage } from './pages/project/project-embed/project-embed.page';
import { AboutAppPage } from './pages/settings/about-app/about-app.page';
import { ProfilePage } from './pages/settings/profile/profile.page';
import { AllowLoginGuard } from './guards/allow-login.guard';
import { PermissionsPage } from './pages/settings/permissions/permissions.page';
import { PreferencesPage } from './pages/settings/preferences/preferences.page';
import { LoginPage } from './pages/login/login.page';
import { AddressListPage } from './pages/address/list.page';
import { AddressCreatePage } from './pages/address/create.page';
import { WhitelabelGuard } from './guards/white-label.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'projects/:slug',
    loadChildren: () =>
      import('./pages/project/project.module').then((m) => m.ProjectPageModule),
    canActivate: [WhitelabelGuard],
  },
  {
    path: 'organizations/:slug',
    loadChildren: () =>
      import('./pages/organization/organization.module').then(
        (m) => m.OrganizationPageModule
      ),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./pages/notifications/notifications.module').then(
        (m) => m.NotificationsPageModule
      ),
  },
  {
    path: 'login',
    component: LoginPage,
    canActivate: [AllowLoginGuard],
  },
  {
    path: 'about-app',
    component: AboutAppPage,
  },
  {
    path: 'messages/:id',
    loadChildren: () =>
      import('./pages/message/message.module').then((m) => m.MessagePageModule),
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./pages/onboarding/onboarding.module').then(
        (m) => m.OnboardingPageModule
      ),
  },
  {
    path: 'settings/profile',
    component: ProfilePage,
    canActivate: [AllowLoginGuard],
  },
  {
    path: 'settings/permissions',
    component: PermissionsPage,
  },
  {
    path: 'settings/preferences',
    component: PreferencesPage,
  },
  {
    path: 'settings/address/list',
    component: AddressListPage,
  },
  {
    path: 'settings/address/create',
    component: AddressCreatePage,
  },
  {
    path: 'settings/address/:id/edit',
    component: AddressCreatePage
  },
  {
    path: 'device/loading',
    loadChildren: () =>
      import('./pages/device-user-data/device-user-data.module').then(
        (m) => m.DeviceUserDataModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'deeplink',
    loadChildren: () =>
      import('./pages/deeplink-wrapper/deeplink-wrapper.module').then(
        (m) => m.DeeplinkWrapperPageModule
      ),
  },
  {
    path: 'embed/:slug',
    component: ProjectEmbedPage,
  },
  {
    path: 'v2/:tab/projects/:slug/:project-tab/:id',
    redirectTo: '/projects/:slug/:project-tab/:id',
    pathMatch: 'prefix',
  },
  {
    path: 'v2/:tab/projects/:slug',
    redirectTo: '/projects/:slug',
    pathMatch: 'prefix',
  },
  {
    path: ':tab/projects/:slug',
    redirectTo: '/projects/:slug',
    pathMatch: 'prefix',
  },
  {
    path: 'v2',
    redirectTo: '/feed',
    pathMatch: 'prefix',
  },
  {
    path: '404',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(
        (m) => m.NotFoundPageModule
      ),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'ignore',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
