import { EventEmitter, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { Hydra } from 'src/app/interfaces/hydra';
import { Subscription } from 'rxjs';
import { Participation } from '../interfaces/participation';
import { ProjectService } from 'src/app/services/project.service';
import { Project } from 'src/app/interfaces/project';
import { ParticipationCounts } from '../interfaces/participation-counts';

@Injectable({ providedIn: 'root' })
export class ParticipationService {
  private currentRequest: Subscription = null;
  public lastViewDataUpdated$ = new EventEmitter();

  constructor(
    private apiService: ApiService,
    private projectService: ProjectService
  ) {}

  public getOpenItems(
    urlParams?: HttpParams,
    cancelPending: boolean = false
  ): Promise<Hydra<Participation>> {
    return new Promise((resolve, reject) => {
      const params = urlParams ? urlParams : new HttpParams();

      if (cancelPending && this.currentRequest != null) {
        this.currentRequest.unsubscribe();
      }

      const observable = this.apiService.identifiedGet(
        '/api/v3/participation/open/list',
        params
      );
      this.currentRequest = observable.subscribe(
        (response) => {
          const responseData = response;
          let obj = <Hydra<Participation>>{};

          obj.member = <Participation[]>responseData['hydra:member'];

          obj.totalItems = responseData['hydra:totalItems'];

          resolve(obj);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  public async getOpenCount(): Promise<number> {
    try {
      const n = await this.apiService
        .identifiedGet('/api/v3/participation/open/count', null)
        .toPromise();
      return n.count;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getOpenCounts(): Promise<ParticipationCounts> {
    return this.apiService
      .identifiedGet('/api/v3/participation/open/counts', null)
      .toPromise();
  }

  public async updateLastViewData(project: Project) {
    await this.apiService
      .identifiedPut(
        `/api/v3/projects/${project.slug}/participation/view-data`,
        null
      )
      .toPromise();
    this.lastViewDataUpdated$.emit();
  }
}
