import { EventEmitter, Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { Project } from '../interfaces/project';
import { Hydra } from '../interfaces/hydra';
import { Update } from '../interfaces/update';
import { UpdateLikeType } from '../enums/update-like-type';
import { CacheService } from './cache.service';
import { SettingsService } from './settings.service';
import { ProjectService } from './project.service';
import { Customer } from '../interfaces/customer';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UpdateService {
  public updateViewData$ = new EventEmitter<Project>();
  public updateLiked$ = new EventEmitter<Update>();
  private static UPDATE_IRI_PREFIX = '/api/updates/';

  constructor(
    private apiService: ApiService,
    private cacheService: CacheService,
    private projectService: ProjectService
  ) {}

  public async filterUpdatesByTargetGroup(
    updates: Update[],
    project: Project = null
  ): Promise<Update[]> {
    let targetSlug = project
      ? await this.projectService.getCurrentTarget(project)
      : null;

    return updates.filter((update) => {
      if (!update.visibleToTargetsOnly) {
        return true;
      }
      return update.targets.some((target) => {
        const result =
          target.slug === targetSlug ??
          this.projectService.getCurrentTarget(update.project);
        return result;
      });
    });
  }

  public async getAllUpdates(
    project: Project,
    urlParams: HttpParams
  ): Promise<Hydra<Update>> {
    let params = urlParams ? urlParams : new HttpParams();

    params = params.set('order[publicationDate]', 'desc');

    let token = sessionStorage.getItem('preview_token');
    if (token) {
      params = params.set('preview', token);
      params = params.set('cache-token', Date.now().toString());
    }

    let url = '/api/v3/projects/' + project.slug + '/updates';

    return this.apiService
      .identifiedGet(url, params)
      .toPromise()
      .then((response: Hydra<Update>) => {
        let obj = <Hydra<Update>>{};

        obj.member = <Update[]>response['hydra:member'].map((object) => {
          object.publicationDate = moment(object.publicationDate).toDate();

          return object;
        });

        obj.totalItems = response['hydra:totalItems'];

        return obj;
      });
  }

  public async getAllUpdatesForCustomer(
    customer: Customer,
    urlParams: HttpParams
  ): Promise<Hydra<Update>> {
    let params = urlParams ? urlParams : new HttpParams();

    params = params.set('order[publicationDate]', 'desc');

    let url = '/api/v3/customers/' + customer.id + '/updates';

    return lastValueFrom(this.apiService.identifiedGet(url, params)).then(
      (response: Hydra<Update>) => {
        let obj = <Hydra<Update>>{};

        obj.member = <Update[]>response['hydra:member'].map((object) => {
          object.publicationDate = moment(object.publicationDate).toDate();

          return object;
        });

        obj.totalItems = response['hydra:totalItems'];

        return obj;
      }
    );
  }

  public likeUpdate(update: Update, type: UpdateLikeType): Promise<any> {
    const data = {
      update: UpdateService.UPDATE_IRI_PREFIX + update.id,
      type,
    };

    return this.apiService
      .identifiedPost('/api/v2/updates/like', data)
      .toPromise();
  }

  public unlikeUpdate(update: Update): Promise<any> {
    const data = {
      update: UpdateService.UPDATE_IRI_PREFIX + update.id,
    };

    return this.apiService
      .identifiedPost('/api/v2/updates/unlike', data)
      .toPromise();
  }

  public async updateLastViewData(project: Project) {
    await this.apiService
      .identifiedPut(`/api/v2/projects/${project.slug}/updates/view-data`, null)
      .toPromise();

    project.updatesLastReadAt = moment().toDate();

    this.updateViewData$.emit(project);
  }

  public async getUpdateById(id: number): Promise<Update> {
    return await this.apiService
      .identifiedGet(`/api/v3/updates/${id}`)
      .toPromise();
  }

  public getUpdateCategories(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const url = '/api/v4/update-categories';

      this.cacheService.get(url, 604800).then(async (cacheEntry) => {
        if (cacheEntry != null && !cacheEntry.expired) {
          resolve(cacheEntry.value);
        } else {
          try {
            const categories = await this.apiService
              .identifiedGet(url)
              .toPromise();
            this.cacheService.store(url, categories);
            resolve(categories);
          } catch (error) {
            reject(error);
          }
        }
      });
    });
  }

  public async getUpdateReactions(update: Update): Promise<any> {
    return await this.apiService
      .identifiedGet(`/api/v2/update-likes/update/${update.id}`)
      .toPromise();
  }

  public async getUpdateReactionsForUpdates(updates: Update[]): Promise<any> {
    const params = new HttpParams().set(
      'update_ids',
      updates.map((u) => u.id).join(',')
    );
    return await this.apiService
      .identifiedGet('/api/v2/update-likes-updates', params)
      .toPromise();
  }
}
