import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouteNavParams {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  public get(key: string) {
    const state = this.router.getCurrentNavigation()
      ? this.router.getCurrentNavigation().extras.state || {}
      : {};
    const params = this.activatedRoute.children.reduce((prev, item) => {
      return { ...prev, ...item.snapshot.params };
    }, this.activatedRoute.snapshot.params || {});
    const queryParams = this.activatedRoute.children.reduce((prev, item) => {
      return { ...prev, ...item.snapshot.queryParams };
    }, this.activatedRoute.snapshot.queryParams || {});

    return state[key]
      ? state[key]
      : queryParams[key]
      ? queryParams[key]
      : params[key];
  }
}
