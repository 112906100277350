import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import { CurrentWhiteLabelApplication } from '../utils/current-white-label-application';
import { Injectable } from '@angular/core';
import { WhiteLabelApplicationService } from '../services/white-label-application.service';
import { filter, first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AllowLoginGuard implements CanActivate {
  constructor(
    private navCtrl: NavController,
    private app: CurrentWhiteLabelApplication,
    private whiteLabelApplicationService: WhiteLabelApplicationService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.whiteLabelApplicationService.waitUntilReady();

    return this.app.isLoginEnabled();
  }
}
