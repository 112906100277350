import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from '../../components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { SmartReportingMapPage } from './pages/map/map.page';
import { SmartReportingCreatePage } from './pages/create/create.page';
import { SmartReportingMapComponent } from './components/map/map.component';
import { SmartReportingDetailPage } from './pages/detail/detail.page';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { PipesModule } from '../../pipes/pipes.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    NgxMapboxGLModule,
    TranslateModule.forChild(),
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    InlineSVGModule,
    PipesModule,
  ],
  declarations: [
    SmartReportingMapPage,
    SmartReportingCreatePage,
    SmartReportingMapComponent,
    SmartReportingDetailPage,
  ],
  exports: [
    SmartReportingMapPage,
    SmartReportingCreatePage,
    SmartReportingMapComponent,
    SmartReportingDetailPage,
  ],
})
export class SmartReportingModule {}
