import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { AnalyticsType } from 'src/app/enums/analytics-type';
import { CardType } from 'src/app/enums/card-type';
import { Project } from 'src/app/interfaces/project';
import { Ticket } from 'src/app/interfaces/ticket';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ErrorService } from 'src/app/services/error.service';
import { LocationService } from 'src/app/services/location.service';
import { TicketService } from 'src/app/services/ticket.service';
import { UploadService } from 'src/app/services/upload.service';

@Component({
  selector: 'app-project-contact',
  templateUrl: './project-contact.component.html',
})
export class ProjectContactComponent implements OnInit {
  CardType = CardType;
  contactForm: FormGroup;
  loading: boolean = false;
  uploadedFile: string | File;
  chatEnabled: boolean = true;
  @Input() project: Project;
  @Output() switchTab: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private uploadService: UploadService,
    private locationService: LocationService,
    private ticketService: TicketService,
    private analyticsService: AnalyticsService
  ) {
    this.createContactForm();
  }

  ngOnInit() {
    if (this.project) {
      this.chatEnabled = this.project.chatEnabled;
    }
  }

  createContactForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(250)]],
      subject: ['', [Validators.required, Validators.maxLength(250)]],
      message: ['', [Validators.required, Validators.maxLength(10000)]],
      attachment: [''],
    });
  }

  public setUploadedFile(file: string | File) {
    this.uploadedFile = file;
  }

  async send() {
    this.errorService.markFormGroupTouchedAndDirty(this.contactForm);

    if (this.contactForm.valid) {
      this.loading = true;

      try {
        const data = this.contactForm.value;
        let attachment = null;

        if (this.uploadedFile != null) {
          const uploadResult: any = await this.uploadService.upload(
            'ticket-message-attachment',
            this.uploadedFile
          );

          attachment = uploadResult.file;
        }

        let location: GeolocationPosition | null = null;

        try {
          location = await this.locationService.getCurrentPosition();
        } catch (error) {
          // no location available, but continue
        }

        const ticket: Ticket = {
          project: this.project,
          messages: [
            {
              content: data.message,
              attachment,
            },
          ],
          name: data.name,
          subject: data.subject,
          locationLat: location === null ? null : location.coords.latitude,
          locationLong: location === null ? null : location.coords.longitude,
        };

        this.ticketService.saveName(data.name);

        const result = await this.ticketService.createTicket(ticket);

        ticket.lastInteractionDate = new Date();
        ticket.id = result.id;

        this.contactForm.reset();

        this.log(data);

        this.ticketService.ticketCreated.emit(ticket);
        this.switchTab.next('conversations');
      } catch (error) {
        this.errorService.parseErrorsToForm(this.contactForm, error, {
          attachmentFile: 'attachment',
        });
      } finally {
        this.loading = false;
      }
    }
  }

  private log(formValue: any) {
    this.analyticsService.logEvent(
      AnalyticsType.CONTACT_MESSAGE,
      {
        name: formValue.name,
        subject: formValue.subject,
        attached_media: formValue.attachment,
        action: 'create',
      },
      this.project
    );
  }
}
