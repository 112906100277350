import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CurrentWhiteLabelApplication } from '../../utils/current-white-label-application';

@Component({
  selector: 'app-update-app',
  templateUrl: './update-app.page.html',
})
export class UpdateAppPage implements OnInit {
  update: any;
  backButtonAction: Subscription;

  constructor(
    private modalCtrl: ModalController,
    private currentApp: CurrentWhiteLabelApplication,
    private platform: Platform
  ) {
    this.backButtonAction = platform.backButton.subscribe(() => {
      if (this.update.type == 'IMMEDIATE') {
        App.exitApp();
      }
    });
  }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss();
  }

  goToUpdate() {
    if (this.platform.is('ios')) {
      window.open(
        'https://apps.apple.com/app/' + this.currentApp.getIOSAppStoreId(),
        '_system'
      );
    } else if (this.platform.is('android')) {
      window.open(
        'https://play.google.com/store/apps/details?id=' +
          this.currentApp.getBundleIdentifer(),
        '_system'
      );
    }
  }
}
