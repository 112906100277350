<app-card
  [className]="'card--summary'"
  *ngIf="project.ratingThemes.length > 0"
  [language]="project.language"
  [project]="project"
  [type]="CardType.RATING"
>
</app-card>

<app-card
  [className]="'card--summary'"
  *ngIf="survey"
  [survey]="survey"
  [project]="project"
  [language]="project.language"
  [type]="CardType.SURVEY"
>
</app-card>

<div class="empty-list" *ngIf="!survey && project.ratingThemes.length === 0">
  <span inlineSVG="/assets/icons/message.svg"></span>
  <h2>{{ 'project.tab.participate' | translate }}</h2>
  <p>{{ 'participate.empty.desc' | translate }}</p>
</div>
