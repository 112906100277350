import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-translation-button',
  templateUrl: 'translation-button.component.html',
})
export class AppTranslationButtonComponent implements OnInit {
  @Output() translate = new EventEmitter();
  loading: boolean;
  translated: boolean;
  titles: any;

  constructor(private translationService: TranslationService) {}

  async ngOnInit() {
    this.translationService.translateSubject.subscribe(
      (titles) => (this.titles = titles)
    );
  }

  doTranslate(event) {
    this.translate.emit();
    event.stopPropagation();
  }
}
