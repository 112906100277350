import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe as AngularDatePipe } from '@angular/common';
import { CurrentWhiteLabelApplication } from '../utils/current-white-label-application';
import { TranslationService } from '../services/translation.service';

@Pipe({
  name: 'appDate',
})
export class DatePipe implements PipeTransform {
  constructor(
    private datePipe: AngularDatePipe,
    private translationService: TranslationService
  ) {}

  transform(
    value: any,
    format?: string,
    timezone?: string,
    locale?: string
  ): string | null {
    return this.datePipe.transform(
      value,
      format,
      timezone,
      this.translationService.getAppLanguage()
    );
  }
}
