<div class="live-stream" #holder>
  <div class="live-stream__error" [hidden]="!showError">
    {{ 'live-stream.not_available' | translate }}
  </div>

  <!--<div class="live-stream__loading" [hidden]="!loading || showError">
        <app-loader [loading]="true"></app-loader>
    </div>-->

  <video
    class="live-stream__video"
    tabindex="0"
    #video
    playsinline
    muted
    autoplay
  ></video>

  <!--<iframe class="live-stream__video live-stream__video--frame" tabindex="0" #iframe [hidden]="showError || loading || !currentLiveStream?.iframe" allowfullscreen>
    </iframe>-->
</div>
