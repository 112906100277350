import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular';

import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

if (environment.production) {
  if (Capacitor.isNativePlatform()) {
    App.getInfo().then((appInfo) => {
      setupSentry(appInfo.id + '@' + appInfo.version);
    });
  } else {
    setupSentry('pwa@latest');
  }

  enableProdMode();
}

function setupSentry(release) {
  const ignoredErrors = [
    /^Error: Invalid LngLat object: (NaN, NaN)$/,
    /^TypeError: Cannot read properties of null (reading '3')$/,
    /^TypeError: null is not an object (evaluating 'r[3]')$/,
  ];
  Sentry.init(
    {
      dsn: environment.sentryDsn,
      environment: environment.environment,
      release: release,
      ignoreErrors: ignoredErrors,
    },
    SentryAngular.init
  );
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
