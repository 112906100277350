import { Component, Input, OnInit } from '@angular/core';
import { Event } from 'src/app/interfaces/event';
import * as moment from 'moment';
import { EventTimeline } from 'src/app/enums/event-timeline';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
})
export class EventComponent implements OnInit {
  @Input() event: Event;
  @Input() standalone: boolean = false;
  @Input() language: string;
  EventTimeline = EventTimeline;
  timeDiffInHours: number;

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
    if(this.event.timeline == EventTimeline.CURRENT) this.timeDiffInHours = moment(this.event.endDate).diff(moment(), 'hours');
  }

  handleClick(event: Event) {
    if (event.updates?.length) {
      this.router.navigate([
        'projects',
        event.updates[0].project.slug,
        'update',
        event.updates[0].id,
      ]);
    }
  }
}
