import { Injectable } from '@angular/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { Platform } from '@ionic/angular';
import { Attachment } from 'src/app/interfaces/attachment';
import { FileService } from 'src/app/services/file.service';
import { Attachments } from 'src/app/utils/attachments';
import { Base64Helper } from 'src/app/utils/base64-helper';
import { v4 as uuidv4 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class AttachmentService {
  private sharing: boolean = false;

  constructor(private platform: Platform, private fileService: FileService) {}

  public async shareAttachment(attachment: Attachment) {
    if (this.sharing) {
      return;
    }
    this.sharing = true;

    if (Attachments.isYoutubeVideo(attachment)) {
      this.share('https://youtu.be/' + attachment.videoId);
      this.sharing = false;
      return;
    }

    let path = Attachments.isPdf(attachment)
      ? attachment.filePathThumbnails.full
      : attachment.filePathThumbnails.large;

    if (!attachment.downloadedPath) {
      try {
        attachment.downloadedPath = await this.downloadAttachment(path);
      } catch (error) {
        console.warn('Downloading attachment failed.', error);
      }
    }

    if (attachment.downloadedPath) {
      path = attachment.downloadedPath;
    }

    this.share(path);
    this.sharing = false;
  }

  private share(url: string) {
    Share.share({ url: url }).catch((e) => {
      // share cancelled
    });
  }

  private async downloadAttachment(url: string): Promise<string> {
    const uri = await Filesystem.getUri({
      directory: Directory.Cache,
      path: '',
    });

    const ios = this.platform.is('ios');

    let path = uri.uri.slice(0, ios ? uri.uri.length - 1 : uri.uri.length);
    const uuid = uuidv4();

    const extensionParts = url.split('.');
    const extension = extensionParts[extensionParts.length - 1].split('?')[0];

    if (path[path.length - 1] !== '/') {
      path += '/';
    }

    const fileName = uuid + '.' + extension;
    const fullUrl = path + fileName;

    const response = await fetch(url);
    const blob = await response.blob();
    const base64Data = (await Base64Helper.convertBlobToBase64(blob)) as string;

    const data = await Filesystem.writeFile({
      path: fullUrl,
      data: base64Data,
      directory: Directory.Cache,
      recursive: true,
    });

    this.fileService.scheduleRemoval(data.uri);
    return data.uri;
  }
}
