import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
})
export class LoginPage implements OnInit {
  appIcon: string;
  sent: boolean = false;
  failed: boolean = false;
  loginForm: FormGroup;
  loading: boolean = false;

  constructor(
    private app: CurrentWhiteLabelApplication,
    private fb: FormBuilder,
    private loginService: LoginService
  ) {
    this.appIcon = this.app.getIconThumbnails()?.small;
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  login() {
    if (this.loginForm.valid) {
      this.loading = true;
      this.loginService
        .sendMagicLink(this.loginForm.value)
        .then(() => {
          this.sent = true;
        })
        .catch(() => {
          this.failed = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
