<label
  class="file-input__media medium-primary"
  (click)="changeAttachment()"
  tabindex="0"
  role="button"
>
  <div
    inlineSVG="assets/icons/image.svg"
    class="inline-svg file-input__image"
    [hidden]="uploadedFile != null"
    [attr.aria-label]="'file-input.file' | translate"
  ></div>
  {{
    (uploadedFile != null
      ? 'file-input.attachment.chosen'
      : 'file-input.attachment.placeholder'
    ) | translate
  }}

  <div
    inlineSVG="assets/icons/close.svg"
    class="inline-svg file-input__clear"
    [hidden]="uploadedFile == null"
    [attr.aria-label]="'file-input.file.remove' | translate"
    tabindex="0"
    role="button"
    (click)="clearAttachment($event)"
  ></div>

  <input
    type="file"
    #file
    class="file-input__file"
    (change)="nativeInputChange($event)"
    [accept]="mimeTypesAsString"
    *ngIf="pwa"
  />
</label>
