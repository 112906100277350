import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ProjectHomeComponent } from './tabs/project-home/project-home.component';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ProjectAboutUsComponent } from './tabs/project-about-us/project-about-us.component';
import { ProjectContactComponent } from './tabs/project-contact/project-contact.component';
import { ProjectConversationsComponent } from './tabs/project-conversations/project-conversations.component';
import { ProjectParticipateComponent } from './tabs/project-participate/project-participate.component';
import { ProjectSmartReportingComponent } from './tabs/project-smart-reporting/project-smart-reporting.component';
import { ProjectPlanningComponent } from './tabs/project-planning/project-planning.component';
import { ProjectChildProjectsComponent } from './tabs/project-child-projects/project-child-projects.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { SmartReportingModule } from '../../modules/smart-reporting/smart-reporting.module';
import { ProjectOpinionPageModule } from './project-opinion/project-opinion.module';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { ProjectUpdatePageModule } from './project-update/project-update.module';
import { ProjectPlanningPageModule } from './project-planning/project-planning.module';
import { DateTypePipe } from 'src/app/pipes/date-type.pipe';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ProjectPageRoutingModule } from './project-routing.module';
import { ConversationPageModule } from '../../pages/conversation/conversation.module';
import { ProjectPage } from './project.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule.forChild(),
    ComponentsModule,
    NgxMapboxGLModule,
    TruncateModule,
    PipesModule,
    InlineSVGModule,
    SmartReportingModule,
    ProjectOpinionPageModule,
    ConversationPageModule,
    ProjectUpdatePageModule,
    ProjectPlanningPageModule,
    NgxSkeletonLoaderModule,
    ProjectPageRoutingModule,
  ],
  exports: [],
  declarations: [
    ProjectPage,
    ProjectHomeComponent,
    ProjectContactComponent,
    ProjectAboutUsComponent,
    ProjectConversationsComponent,
    ProjectParticipateComponent,
    ProjectSmartReportingComponent,
    ProjectPlanningComponent,
    ProjectChildProjectsComponent,
  ],
  providers: [DateTypePipe],
})
export class ProjectPageModule {}
