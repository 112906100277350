import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthString',
})
export class MonthStringPipe implements PipeTransform {
  transform(text: string): any {
    return text.replace('.', '');
  }
}
