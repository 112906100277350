import { Component, Input, OnInit } from '@angular/core';
import { Project } from 'src/app/interfaces/project';

@Component({
  selector: 'app-project-smart-reporting',
  templateUrl: './project-smart-reporting.component.html',
})
export class ProjectSmartReportingComponent implements OnInit {
  @Input() project: Project;

  constructor() {}

  ngOnInit() {}
}
