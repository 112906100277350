import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Project } from 'src/app/interfaces/project';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-card-child-project',
  templateUrl: './card-child-project.component.html',
})
export class CardChildProjectComponent implements OnInit {
  @Input() project: Project;
  withDistance: boolean;
  @Output() openLink = new EventEmitter<void>();

  constructor(private locationService: LocationService) {}

  async ngOnInit() {
    try {
      const location = await this.locationService.getCurrentPosition();
      this.withDistance = true;
    } catch (error) {
      this.withDistance = false;
    }
  }

  open() {
    this.openLink.next();
  }
}
