import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CurrentWhiteLabelApplication } from '../utils/current-white-label-application';
import { Injectable } from '@angular/core';
import { WhiteLabelApplicationService } from '../services/white-label-application.service';

@Injectable({ providedIn: 'root' })
export class WhitelabelGuard implements CanActivate {
  constructor(
    private app: CurrentWhiteLabelApplication,
    private whiteLabelApplicationService: WhiteLabelApplicationService,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.whiteLabelApplicationService.waitUntilReady();

    if (this.app.isProjectApp()) {
      if (route.params.slug !== this.app.getProjectAppSlug()) {
        this.router.navigate(['project'], { replaceUrl: true });
        return false;
      } else {
        let isOnProjectPage =
          window.location.href.split(this.app.getProjectAppSlug())[1] === '';
        if (isOnProjectPage) {
          this.router.navigate(['project'], { replaceUrl: true });
          return false;
        }
      }
    }

    return true;
  }
}
