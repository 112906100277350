export const environment = {
  production: true,
  environment: 'production',
  baseURL: 'https://api.debouwapp.nl',
  whiteLabelApplicationKey: '94d19d7d-46ff-4e1f-ab76-334977b3af7a',
  googleMapsApiKey: 'AIzaSyA3eY1RJzKmPmZYG9C2lFi_LLsyZhRPbuI', // has to be static! is used in app.module.ts
  mapboxToken:
    'pk.eyJ1IjoiZnJhbmtlbmh1aXplbiIsImEiOiJja2Fuc3YyamMwN2NrMnFwbzFhcDNiczNlIn0.Jb8kwbiJiBix0OjjvDY2KQ',
  serviceWorker: 'firebase-messaging-sw-prod.js',
  sentryDsn:
    'https://d89b9de3b32c430fd06b43355c0eed62@o4505843960315904.ingest.sentry.io/4505844907245568',
  firebase: {
    apiKey: 'AIzaSyDvpBHW2kcrJ2iatznS2ugk-qTjVzVLo5U',
    authDomain: 'bouwapp-182408.firebaseapp.com',
    databaseURL: 'https://bouwapp-182408.firebaseio.com',
    projectId: 'bouwapp-182408',
    storageBucket: 'bouwapp-182408.appspot.com',
    messagingSenderId: '419899581938',
    appId: '1:419899581938:web:d25238f2dbdcb3eded376b',
  },
  vapidKey:
    'BAWJ_4qksP9PqqfszOJrOWVQnZCQ94HekTKwm6QgTVkCl2mVLLtrBL9vc3iEEcwdQB9nJC7_ZgiAR7cYJ-kEPzI',
  clientId: '22327816a1a6a49d078944e566a30c57',
  insightsEndpoint: 'https://insights.debouwapp.nl',
};
