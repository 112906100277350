import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Survey } from 'src/app/interfaces/survey';
import { SurveyQuestionAnswer } from 'src/app/interfaces/survey-question-answer';
import { SurveyService } from 'src/app/services/survey.service';

@Component({
  selector: 'app-card-survey',
  templateUrl: './card-survey.component.html',
})
export class CardSurveyComponent implements OnInit {
  @Input() survey: Survey;
  @Input() language: string;
  @Output() openLink = new EventEmitter<void>();
  loading: boolean = true;
  canShowSurvey: boolean = true;
  subscription: Subscription;
  @Input() withPadding: boolean = false;

  constructor(private surveyService: SurveyService) {
    this.subscription = this.surveyService.surveySubject.subscribe(() => {
      this.checkSurvey();
    });
  }

  ngOnInit() {
    this.checkSurvey();
  }

  open() {
    this.openLink.next();
  }

  async checkSurvey() {
    const response = await this.surveyService.getAnswersByDevice(
      this.survey.id
    );
    if (response.length > 0) this.canShowSurvey = false;
    this.loading = false;
  }
}
