import { Component } from '@angular/core';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';

@Component({
  selector: 'app-about-app',
  templateUrl: './about-app.page.html',
})
export class AboutAppPage {
  language: string;
  aboutText: string;

  constructor(private app: CurrentWhiteLabelApplication) {
    this.language = this.app.getLanguage();
    this.aboutText = this.app.getCompanyText() ?? '';
  }
}
