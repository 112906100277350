import { Component, Input, OnInit } from '@angular/core';
import { CardType } from 'src/app/enums/card-type';
import { Project } from 'src/app/interfaces/project';
import { Survey } from 'src/app/interfaces/survey';
import { ParticipationService } from 'src/app/services/participation.service';

@Component({
  selector: 'app-project-participate',
  templateUrl: './project-participate.component.html',
})
export class ProjectParticipateComponent implements OnInit {
  @Input() project: Project;
  survey: Survey;
  CardType = CardType;

  constructor(private participationService: ParticipationService) {}

  ngOnInit() {
    if (this.project.activeSurvey) this.survey = this.project.activeSurvey;
    this.participationService.updateLastViewData(this.project);
  }
}
