<ion-content
  [scrollEvents]="true"
  (ionScroll)="handleScroll($event)"
  (ionScrollEnd)="onScrollEnd()"
  class="app project swipe__parent-scroll"
>
  <div [ngClass]="isProjectApp ? 'swipe-content' : null">
    <div>
      <app-loader [loading]="!projectLoaded" [full]="true"></app-loader>
      <ng-container *ngIf="projectLoaded">
        <div class="project__header">
          <div class="project__header__cover">
            <div
              [style.background-image]="project.coverImageThumbnails ? 'url(' + project.coverImageThumbnails.medium + ')' : 'none'"
            ></div>
            <div [hidden]="isProjectApp">
              <span class="back-button">
                <ion-back-button defaultHref="/" mode="md"></ion-back-button>
              </span>
            </div>
          </div>

          <div class="project__header__info">
            <div class="project__image">
              <img
                *ngIf="project.logoThumbnails"
                appImgFallback
                [src]="project.logoThumbnails.small"
                width="48"
                height="48"
                alt="{{ project.name }}"
              />
              <img
                *ngIf="!project.logoThumbnails"
                appImgFallback
                [src]="project.customer.logoThumbnails?.small"
                width="48"
                height="48"
                alt="{{ project.name }}"
              />
            </div>
            <div class="project__info">
              <span
                class="location"
                *ngIf="project.hasParentProject"
                (click)="goToParent()"
              >
                <span
                  >{{ 'project.home.has-parent' | translate:{project:
                  project.parentProject.name} }}</span
                >
                <span
                  class="icon"
                  [inlineSVG]="'/assets/icons/external.svg'"
                ></span>
              </span>
              <h1>{{ project.name }}</h1>
              <span>{{ project.participants }}</span>
              <ul>
                <li>
                  {{ 'project.home.started' | translate }} {{ project.startDate
                  | date:'longDate' }}
                </li>
                <li *ngIf="deviceDataLoaded">
                  {{ (project.amountOfFollowers == 1 ?
                  'project.home.followers.singular' :
                  'project.home.followers.plural') | translate:{'followers':
                  project.amountOfFollowers} }}
                </li>
              </ul>
            </div>
          </div>

          <ul class="project__header__action">
            <li *ngIf="!isProjectApp">
              <ng-container *ngIf="deviceDataLoaded">
                <button
                  [disabled]="loading"
                  (click)="toggleFollowProject()"
                  class="project__follow"
                  *ngIf="!project.starred"
                >
                  <span
                    class="icon"
                    [inlineSVG]="'/assets/icons/plus.svg'"
                  ></span>
                  <span>{{ 'project.home.follow' | translate }}</span>
                  <app-loader
                    color="light"
                    name="dots"
                    [loading]="loading"
                  ></app-loader>
                </button>
                <button
                  [disabled]="loading"
                  (click)="toggleFollowProject()"
                  class="project__unfollow"
                  *ngIf="project.starred"
                >
                  <span>{{ 'project.home.unfollow' | translate }}</span>
                  <app-loader
                    color="dark"
                    name="dots"
                    [loading]="loading"
                  ></app-loader>
                </button>
              </ng-container>
              <ngx-skeleton-loader
                *ngIf="!deviceDataLoaded"
                [theme]="{height: '3.2rem', width: '160px', display:'block', marginBottom:'0'}"
              ></ngx-skeleton-loader>
            </li>
            <li
              class="project__live"
              *ngIf="project.liveStreams.length > 0"
              (click)="goToLivestream()"
            >
              <span class="icon" [inlineSVG]="'/assets/icons/live.svg'"></span>
              <span>{{ 'project.home.live' | translate }}</span>
            </li>
            <li
              *ngIf="canShare"
              (click)="share()"
              class="project__share"
              [inlineSVG]="'/assets/icons/card/share.svg'"
            ></li>
          </ul>

          <div #tabs class="tabs" [class.sticky]="sticky" *ngIf="pages">
            <ion-segment
              scrollable
              (ionChange)="segmentChanged($event)"
              [value]="currentPage"
            >
              <ng-container *ngFor="let page of pages">
                <ion-segment-button
                  [class]="page.value"
                  [value]="page.value"
                  *ngIf="page.enabled"
                >
                  <span *ngIf="page.unread" class="notification-dot"></span>
                  {{ page.title | translate }}
                </ion-segment-button>
              </ng-container>
            </ion-segment>
          </div>
        </div>

        <div class="project__content">
          <app-project-home
            [hidden]="currentPage !== ProjectTabs.HOME"
            [project]="project"
            [phases]="phases"
            [latestUpdate]="latestUpdate"
            [startLivestream]="startLivestream"
            (startedLivestream)="startedLivestream($event)"
            (switchTab)="switchTab($event)"
          >
          </app-project-home>
          <app-update-list
            *ngIf="!isProjectApp"
            [hidden]="currentPage !== ProjectTabs.UPDATES"
            [updates]="updates"
            [totalUpdates]="totalUpdates"
            [project]="project"
            [updateDeviceData]="updateDeviceData"
          >
          </app-update-list>
          <app-project-participate
            *ngIf="!isProjectApp"
            [hidden]="currentPage !== ProjectTabs.PARTICIPATE"
            [project]="project"
          >
          </app-project-participate>
          <app-project-contact
            [hidden]="currentPage !== ProjectTabs.CONTACT"
            [project]="project"
            (switchTab)="switchTab($event)"
          >
          </app-project-contact>
          <app-project-smart-reporting
            *ngIf="currentPage === ProjectTabs.SMART_REPORTING"
            [project]="project"
          >
          </app-project-smart-reporting>
          <app-project-conversations
            [active]="currentPage === ProjectTabs.CONVERSATIONS"
            [hidden]="currentPage !== ProjectTabs.CONVERSATIONS"
            [project]="project"
          >
          </app-project-conversations>
          <app-project-planning
            [hidden]="currentPage !== ProjectTabs.PLANNING"
            [project]="project"
            [phases]="phases"
            [updates]="updates"
            (switchTab)="switchTab($event)"
          >
          </app-project-planning>
          <app-project-about-us
            [hidden]="currentPage !== ProjectTabs.ABOUT_US"
            [project]="project"
          >
          </app-project-about-us>
          <app-project-child-projects
            *ngIf="!isProjectApp"
            [hidden]="currentPage !== ProjectTabs.CHILD_PROJECTS"
            [project]="project"
            [childProjects]="project.childrenProjects"
          >
          </app-project-child-projects>
        </div>
      </ng-container>
    </div>
  </div>
</ion-content>
