import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { MapComponent } from 'ngx-mapbox-gl';
import { DeviceService } from '../../services/device.service';
import { Address } from 'src/app/interfaces/address';
import { MapboxService } from 'src/app/services/mapbox.service';
import { LocationService } from 'src/app/services/location.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { ToastService } from 'src/app/services/toast.service';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { TranslateService } from '@ngx-translate/core';
import { AreaHelper } from 'src/app/utils/area-helper';

@Component({
  selector: 'app-address-create',
  templateUrl: './create.page.html',
})
export class AddressCreatePage implements OnInit {
  @ViewChild(MapComponent) map: MapComponent;
  @ViewChild('geocoder') geocoderWrapper: ElementRef;
  marker: mapboxgl.Marker;
  loading: boolean = true;
  fullScreen: boolean = false;
  isSettings: boolean = false;
  isKeyboardOpen: boolean = false;
  appLanguage: string;
  geocoder: MapboxGeocoder;
  mapError: boolean = false;
  zoomError: boolean = false;
  radius: number;
  isDesktop: boolean;
  address: Address;
  edit: boolean = false;
  touched: boolean = false;

  constructor(
    private mapboxService: MapboxService,
    private locationService: LocationService,
    private navCtrl: NavController,
    private router: Router,
    private deviceService: DeviceService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private platform: Platform,
    private route: ActivatedRoute
  ) {
    if (this.router.url.includes('settings')) this.isSettings = true;
  }

  ngOnInit() {
    this.isDesktop = this.platform.is('desktop');

    if (!this.mapboxService.isWebGLSupported()) {
      this.mapError = true;
      if (!this.isSettings) {
        this.skip();
      }
    }
  }

  skip() {
    this.router.navigate(['onboarding', 'suggestion', 'list'], {
      replaceUrl: true,
    });
  }

  async mapLoaded(event) {
    const id = this.route.snapshot.paramMap.get('id');
    let location;
    if(id) {
      this.address = await this.deviceService.getAddress(parseInt(id));
      location = new mapboxgl.LngLat(
        this.address.locationLong,
        this.address.locationLat
      );
    } else {
      location = await this.getLocation();
    }
    
    const zoom = this.address ? AreaHelper.calculateZoomLevel(this.address.locationLat, this.address.radius / 2) : 14;

    this.map.mapInstance.setZoom(zoom).setCenter(location);
    this.map.mapInstance.on('click', (event) => {
      this.map.mapInstance.jumpTo({
        center: event.lngLat,
        zoom:
          this.map.mapInstance.getZoom() > 11
            ? this.map.mapInstance.getZoom()
            : 11,
      });
      this.updateCircle(event.lngLat.toArray());
    });

    this.map.mapInstance.on('zoom', () =>
      this.updateCircle(this.map.mapInstance.getCenter().toArray())
    );
    this.map.mapInstance.on('move', () =>
      this.updateCircle(this.map.mapInstance.getCenter().toArray())
    );
    
    this.loading = false;
    setTimeout(() => {
      this.map.mapInstance.resize();
    });

    setTimeout(() => {
      //occasionally it won't work the first time
      this.map.mapInstance.resize();
    }, 500);
  }

  updateCircle(center: number[]) {
    if (this.map.mapInstance.getZoom() < 8) {
      this.ngZone.run(() => {
        this.zoomError = true;
      });
      if (this.map.mapInstance.getLayer('overlay-layer'))
        this.map.mapInstance.removeLayer('overlay-layer');
      if (this.map.mapInstance.getLayer('circle-outline'))
        this.map.mapInstance.removeLayer('circle-outline');
      if (this.map.mapInstance.getSource('overlay-source'))
        this.map.mapInstance.removeSource('overlay-source');
      return;
    } else {
      if (this.zoomError) {
        this.ngZone.run(() => {
          this.zoomError = false;
        });
      }
    }

    const mapWidth = this.map.mapContainer.nativeElement.clientWidth;
    const mapHeight = this.map.mapContainer.nativeElement.clientHeight;
    const useValue = mapHeight < mapWidth ? mapHeight : mapWidth;
    const radiusInPx = (useValue * 0.9) / 4;
    let radiusInMeters = this.address && !this.touched ? this.address.radius : AreaHelper.pixelsToMeters(
      radiusInPx,
      center,
      this.map.mapInstance.getZoom()
    );
    if (radiusInMeters < 100) radiusInMeters = 100;
    this.radius = radiusInMeters;
    const overlay = AreaHelper.createGeoJSONOverlay(center, radiusInMeters);

    if (this.map.mapInstance.getSource('overlay-source')) {
      (
        this.map.mapInstance.getSource(
          'overlay-source'
        ) as mapboxgl.GeoJSONSource
      ).setData(overlay);
    } else {
      this.map.mapInstance.addSource('overlay-source', {
        type: 'geojson',
        data: overlay,
      });

      this.map.mapInstance.addLayer({
        id: 'overlay-layer',
        type: 'fill',
        source: 'overlay-source',
        paint: {
          'fill-color': 'black',
          'fill-opacity': 0.5,
        },
      });

      this.map.mapInstance.addLayer({
        id: 'circle-outline',
        type: 'line',
        source: 'overlay-source',
        paint: {
          'line-color':
            document.documentElement.style.getPropertyValue('--appTint'),
          'line-width': 3,
        },
      });
    }

    this.touched = true;
  }

  async getAddress() {
    const lngLat = this.map.mapInstance.getCenter();
    const result: any = await this.mapboxService.reverseGeocode(lngLat);
    if (result.features.length) {
      let feature = result.features[0];
      let formattedAddress: string;

      if (this.radius < 2500) {
        formattedAddress =
          feature.context?.find((x) => x.id.includes('locality'))?.text ||
          feature.context?.find((x) => x.id.includes('neighborhood'))?.text;
      }
      let place = result.features.find((x) => x.id.includes('place'))?.text;
      if (place && formattedAddress) formattedAddress += ', ' + place;
      else if (place) formattedAddress = place;
      else
        formattedAddress = this.translateService.instant(
          'address.create.unknown'
        );

      let address: Address = {
        formattedAddress: formattedAddress,
        name: formattedAddress,
        locationLat: feature.center[1],
        locationLong: feature.center[0],
        radius: Math.round(this.radius / 100) * 100,
      };

      if(this.address) {
        await this.deviceService.updateAddress(this.address.id, address);
      } else {
        await this.deviceService.saveAddress(address);
      }

      this.ngZone.run(() => {
        this.navCtrl.setDirection('back');
        if (this.isSettings)
          this.router.navigate(['settings', 'address', 'list']);
        else
          this.router.navigate(['onboarding', 'address', 'list'], {
            replaceUrl: true,
          });
      });
    } else {
      this.toastService.show('address.create.address-error');
    }
  }

  async getLocation(): Promise<mapboxgl.LngLat> {
    try {
      const location = await this.locationService.getCurrentPosition();
      const coordinates = new mapboxgl.LngLat(
        location.coords.longitude,
        location.coords.latitude
      );
      return coordinates;
    } catch (error) {
      // No location
      this.toastService.show('address.create.location-error');
      return new mapboxgl.LngLat(4.8359897, 52.3546448);
    }
  }

  toggleFullscreen() {
    this.fullScreen = !this.fullScreen;
    setTimeout(() => {
      this.map.mapInstance.resize();
    });
  }
}
