import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-form-group',
  templateUrl: 'form-group.component.html',
})
export class FormGroupComponent implements OnInit {
  @Input() public form: AbstractControl;
  @Input() public errorPath: string;

  public control: AbstractControl;

  /**
   * @returns {void}
   */
  ngOnInit(): void {
    this.loadFormControl();
  }

  /**
   * @returns {void}
   */
  loadFormControl(): void {
    if (this.errorPath == null) {
      return;
    }

    const pathParts = this.errorPath.split('.');
    let control: AbstractControl = this.form;

    for (const pathPart of pathParts) {
      control = control.get(pathPart);
    }

    this.control = control;
  }

  /**
   * @returns {boolean}
   */
  hasInvalidState(): boolean {
    if (this.control == null) {
      return false;
    }

    return (
      this.control.errors != null && this.control.touched && this.control.dirty
    );
  }
}
