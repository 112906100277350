import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './loader/loader.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../pipes/pipes.module';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ContentTextPipe } from '../pipes/content-text.pipe';
import { FileInputComponent } from './file-input/file-input.component';
import { TimeAgoPipe } from '../pipes/time-ago.pipe';
import { TranslateTextDirective } from '../directives/translate-text.directive';
import { AppTranslationButtonComponent } from './translation-button/translation-button.component';
import { FeedItemComponent } from '../components/feed-item/feed-item.component';
import { PollComponent } from '../components/poll/poll.component';
import { SocialButtonsComponent } from '../components/social-button/social-buttons.component';
import { SearchItemComponent } from '../components/search-item/search-item.component';
import { PermissionsPage } from '../pages/settings/permissions/permissions.page';
import { CardComponent } from './cards/card/card.component';
import { CardUpdateComponent } from '../components/cards/card/card-update/card-update.component';
import { CardContactComponent } from '../components/cards/card/card-contact/card-contact.component';
import { CardRatingComponent } from '../components/cards/card/card-rating/card-rating.component';
import { CardSurveyComponent } from '../components/cards/card/card-survey/card-survey.component';
import { CardChildProjectComponent } from '../components/cards/card/card-child-project/card-child-project.component';
import { CardReportComponent } from '../components/cards/card/card-report/card-report.component';
import { CardProjectSuggestionComponent } from '../components/cards/card/card-project-suggestion/card-project-suggestion.component';
import { LiveStreamComponent } from '../components/live-stream/live-stream.component';
import { SmileyInputComponent } from '../components/smiley-input/smiley-input.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { MediaSliderComponent } from '../components/media-slider/media-slider.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ImgFallBackDirective } from '../directives/img-fallback.directive';
import { ProjectSuggestionSliderComponent } from '../components/project-suggestion-slider/project-suggestion-slider.component';
import { SearchMapComponent } from '../components/search-map/search-map.component';
import { ReadMorePipe } from '../pipes/read-more.pipe';
import { MediaSliderModalComponent } from '../components/media-slider-modal/media-slider-modal.component';
import { ProfilePage } from '../pages/settings/profile/profile.page';
import { LoginPage } from '../pages/login/login.page';
import { AboutAppPage } from '../pages/settings/about-app/about-app.page';
import { SearchEmptyComponent } from '../components/search-empty/search-empty.component';
import { PreferencesPage } from '../pages/settings/preferences/preferences.page';
import { FormGroupComponent } from './form-group/form-group.component';
import { CountdownModule } from 'ngx-countdown';
import { EventsComponent } from './events/events.component';
import { CardEventsComponent } from './cards/card/card-events/card-events.component';
import { UpdateListComponent } from './update-list/update-list.component';
import { CardOrganizationComponent } from './cards/card/card-organization/card-organization.component';
import { EventComponent } from './events/event.component';
import { CountdownPipe } from '../pipes/countdown.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forChild(),
    PipesModule,
    CountdownModule,
    TruncateModule,
    InlineSVGModule.forRoot(),
    NgxSkeletonLoaderModule.forRoot(),
    NgxMapboxGLModule,
  ],
  declarations: [
    LoaderComponent,
    FeedItemComponent,
    SearchItemComponent,
    SearchEmptyComponent,
    MediaSliderModalComponent,
    SearchMapComponent,
    CardComponent,
    CardUpdateComponent,
    CardEventsComponent,
    CardContactComponent,
    CardRatingComponent,
    CardSurveyComponent,
    CardChildProjectComponent,
    CardReportComponent,
    CardProjectSuggestionComponent,
    CardOrganizationComponent,
    ProjectSuggestionSliderComponent,
    LiveStreamComponent,
    PollComponent,
    EventComponent,
    EventsComponent,
    SocialButtonsComponent,
    FileInputComponent,
    TimeAgoPipe,
    CountdownPipe,
    ReadMorePipe,
    SmileyInputComponent,
    TranslateTextDirective,
    ImgFallBackDirective,
    AppTranslationButtonComponent,
    PermissionsPage,
    ProfilePage,
    LoginPage,
    AboutAppPage,
    MediaSliderComponent,
    PreferencesPage,
    FormGroupComponent,
    UpdateListComponent,
  ],
  exports: [
    LoaderComponent,
    FeedItemComponent,
    SearchItemComponent,
    SearchEmptyComponent,
    MediaSliderModalComponent,
    CardComponent,
    CardUpdateComponent,
    CardEventsComponent,
    CardContactComponent,
    CardRatingComponent,
    CardSurveyComponent,
    CardChildProjectComponent,
    CardReportComponent,
    CardProjectSuggestionComponent,
    CardOrganizationComponent,
    ProjectSuggestionSliderComponent,
    LiveStreamComponent,
    PollComponent,
    EventComponent,
    EventsComponent,
    SocialButtonsComponent,
    FileInputComponent,
    TimeAgoPipe,
    CountdownPipe,
    ReadMorePipe,
    SmileyInputComponent,
    TranslateTextDirective,
    ImgFallBackDirective,
    AppTranslationButtonComponent,
    PermissionsPage,
    ProfilePage,
    LoginPage,
    AboutAppPage,
    SearchMapComponent,
    MediaSliderComponent,
    PreferencesPage,
    FormGroupComponent,
    UpdateListComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ContentTextPipe],
})
export class ComponentsModule {}
