import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ConversationPage } from './conversation.page';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'src/app/components/components.module';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    TranslateModule.forChild(),
    ComponentsModule,
    ReactiveFormsModule,
    InlineSVGModule,
    PipesModule,
  ],
  declarations: [ConversationPage],
})
export class ConversationPageModule {}
