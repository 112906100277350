import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { Drivers } from '@ionic/storage';

import {
  IonicModule,
  IonicRouteStrategy,
  ModalController,
} from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { customTranslateLoader } from './utils/custom-translate-loader';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ApiService } from './services/api.service';
import { ApiInit } from './utils/api-init';
import { StorageService } from './services/storage.service';
import { CurrentWhiteLabelApplication } from './utils/current-white-label-application';
import { ProjectService } from './services/project.service';
import { LocationService } from './services/location.service';
import { ImageService } from './services/image.service';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import localeEn from '@angular/common/locales/en';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import { SettingsService } from './services/settings.service';
import { UpdateService } from './services/update.service';
import { environment } from '../environments/environment';
import { ToastService } from './services/toast.service';
import { NetworkService } from './services/network.service';
import { CacheService } from './services/cache.service';
import { StatisticsService } from './services/statistics.service';
import { TargetService } from './services/target.service';
import { RatingService } from './services/rating.service';
import { LiveStreamService } from './services/live-stream.service';
import { UploadService } from './services/upload.service';
import { ErrorService } from './services/error.service';
import { FileService } from './services/file.service';
import { DeepLinkService } from './services/deep-link.service';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ProjectDocumentService } from './services/project-document.service';
import { PollService } from './services/poll.service';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { ComponentsModule } from './components/components.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { IonicStorageModule } from '@ionic/storage-angular';
import { UpdateAppPageModule } from './pages/update-app/update-app.module';
import { Interceptor } from './interceptors/interceptor';
import * as SentryAngular from '@sentry/angular';
import { FeedService } from './services/feed.service';
import { TranslationService } from './services/translation.service';
import { ProjectPageModule } from './pages/project/project.module';
import { ProjectSurveyPageModule } from './pages/project/project-survey/project-survey.module';
import { NotificationService } from './services/notification.service';
import { DeviceService } from './services/device.service';
import { OnboardingService } from './services/onboarding.service';
import { LoginService } from './services/login.service';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { PushService } from './services/push.service';
import { ProjectEmbedPageModule } from './pages/project/project-embed/project-embed.module';
import { RouteReuseStrategy } from '@angular/router';
import { EventService } from './services/event.service';
import { NgxSmartBannerModule } from '@netcreaties/ngx-smart-banner';

registerLocaleData(localeNl, 'nl');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDa, 'da');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
    }),
    HttpClientModule,
    AppRoutingModule,
    IonicStorageModule.forRoot({
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.LocalStorage],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: customTranslateLoader,
        deps: [HttpClient],
      },
    }),
    UpdateAppPageModule,
    ProjectPageModule,
    ProjectEmbedPageModule,
    ProjectSurveyPageModule,
    InlineSVGModule.forRoot(),
    NgxSmartBannerModule,
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapboxToken,
    }),
    ComponentsModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [TranslationService],
      useFactory: (translation: TranslationService) => {
        return translation.getAppLanguage();
      },
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    ApiService,
    LoginService,
    ApiInit,
    StorageService,
    CurrentWhiteLabelApplication,
    ProjectService,
    FeedService,
    NotificationService,
    DeviceService,
    OnboardingService,
    ImageService,
    LocationService,
    SettingsService,
    UpdateService,
    EventService,
    ToastService,
    NetworkService,
    CacheService,
    StatisticsService,
    TargetService,
    RatingService,
    LiveStreamService,
    UploadService,
    ErrorService,
    FileService,
    DeepLinkService,
    PushService,
    PollService,
    ProjectDocumentService,
    ModalController,
    {
      provide: ErrorHandler,
      useValue: SentryAngular.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
