<div
  (click)="openFilter()"
  *ngIf="categories && (updates.length > 0 || activeCategory) && !isEmbedded"
  class="project__content__filter"
>
  <span inlineSVG="/assets/icons/filter.svg"></span>
  <span>{{ 'feed.filter' | translate }}</span>
</div>
<ng-container *ngIf="ready">
  <app-card
    (itemVisible)="onItemVisible($event)"
    [type]="update.ratingMonitorPush ? CardType.RATING : CardType.UPDATE"
    *ngFor="let update of updates"
    [dateAdded]="update.publicationDate"
    [update]="update"
    [project]="project ?? update.project"
    [category]="categories[update.category]"
    [withHeader]="true"
    [withNavigation]="false"
    [withFollowers]="false"
    [language]="update.project?.language"
    [className]="'card--update'"
    [likedType]="updateDeviceData[update.id]?.likedType"
  >
  </app-card>
  <ion-infinite-scroll
    [hidden]="updates?.length == totalUpdates"
    (ionInfinite)="loadUpdates($event)"
  >
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ng-container>

<div class="empty-list" *ngIf="ready && updates.length === 0">
  <span inlineSVG="/assets/icons/notifications.svg"></span>
  <h2>{{ 'project.tab.updates' | translate }}</h2>
  <p>{{ 'updates.empty.desc' | translate }}</p>
</div>
