import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonContent } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiInit } from 'src/app/utils/api-init';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { RouteNavParams } from 'src/app/utils/route-nav-params';
import { ActivatedRoute } from '@angular/router';
import { SmartReportingNotice } from 'src/app/interfaces/smart-reporting-notice';
import { SmartReportingNoticeResponse } from 'src/app/interfaces/smart-reporting-notice-response';
import { SmartReportingService } from 'src/app/services/smart-reporting.service';
import { Project } from 'src/app/interfaces/project';
import { ProjectService } from 'src/app/services/project.service';
import { ToastService } from 'src/app/services/toast.service';
import { SmartReportingNoticeStatus } from 'src/app/enums/smart-reporting-notice-status';
import { TranslationService } from 'src/app/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { PushService } from 'src/app/services/push.service';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';
import { AnalyticsType } from 'src/app/enums/analytics-type';

@Component({
  selector: 'app-smart-reporting-detail-page',
  templateUrl: './detail.page.html',
})
export class SmartReportingDetailPage implements OnInit {
  private static REFRESH_INTERVAL = 15000;
  private static SCROLL_DOWN_THRESHOLD = 30;

  public notice: SmartReportingNotice;
  public responses: SmartReportingNoticeResponse[] = [];
  public project: Project;
  public form: FormGroup;
  public feedbackForm: FormGroup;
  public feedbackSuccess = false;
  public askPush = false;
  @ViewChild('content', { static: true }) public scroll: IonContent;

  private _timer: number;
  fullScreen: boolean = false;
  showForm: boolean = false;
  loading: boolean = false;
  private respondingTo: SmartReportingNoticeResponse;
  defaultHref: string = '/';
  private translated: boolean = false;
  private originalMessages: string[] = [];

  SmartReportingNoticeStatus = SmartReportingNoticeStatus;

  constructor(
    private smartReportingService: SmartReportingService,
    private apiInit: ApiInit,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private toastService: ToastService,
    private changeDetectorRef: ChangeDetectorRef,
    private navParams: RouteNavParams,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private translationService: TranslationService,
    private translateService: TranslateService,
    private projectService: ProjectService,
    private pushService: PushService,
    private app: CurrentWhiteLabelApplication
  ) {
    if (!this.navParams.get('notice')) {
      this.activatedRoute.params.subscribe(async (params) => {
        if (params.id && !this.navParams.get('notice')) {
          this.load(params.id);
        }
      });
    }

    this.createForm();
    this.createFeedbackForm();
  }

  toggleFullScreen() {
    this.fullScreen = true;
  }

  toggleForm(respondingTo: SmartReportingNoticeResponse) {
    this.respondingTo = respondingTo;
    this.showForm = true;
  }

  /**
   * Called upon view entrance
   */
  async ngOnInit() {
    if (this.navParams.get('notice')) {
      this.notice = this.navParams.get('notice');
      try {
        this.project = await this.projectService.getProject(
          this.notice.project.slug
        );
      } catch (error) {
        // ignore
      }
    }

    this.pushService.showTip();

    if (this.notice != null) {
      this.updateResponses();
      this.setupRefreshTimer();
      this.setFeedback();
    }
  }

  setFeedback() {
    this.feedbackForm.patchValue(
      {
        feedbackSolution: this.notice.feedbackSolution,
        feedbackProcess: this.notice.feedbackProcess,
      },
      { emitEvent: false }
    );
  }

  ionViewDidLeave() {
    clearInterval(this._timer);
  }

  /**
   * Send a response
   */
  async send() {
    if (this.form.valid) {
      try {
        this.loading = true;
        const data = this.form.value;
        const noticeResponse: SmartReportingNoticeResponse = {
          ...data,
          notice: this.notice['@id'],
          responseTo: this.respondingTo['@id'],
          isResponse: true,
        };

        this.form.reset();

        const response: SmartReportingNoticeResponse =
          await this.smartReportingService.postResponse(noticeResponse);
        response.createdAt = new Date();

        this.notice.responses
          .find((n) => n.id === this.respondingTo.id)
          .responses.push(response);
        this.updateResponses();
        this.respondingTo.responses.push(response);
        this.scrollToBottom();

        this.showForm = false;
        this.loading = false;

        this.analyticsService.logEvent(
          AnalyticsType.SMART_REPORTING,
          {
            name: this.notice.name,
            theme: this.notice.theme,
            action: 'reply',
          },
          this.project
        );
      } catch (error) {
        console.error(error);
        this.toastService.show('smart_reporting.detail.notice.responses.error');
      }
    }
  }

  private setupRefreshTimer() {
    this._timer = <any>setInterval(async () => {
      try {
        this.notice = await this.smartReportingService.details(this.notice.id);
      } catch {
        return;
      }

      const element = await this.scroll.getScrollElement();

      if (
        this.notice.responses.length > 0 &&
        element.scrollTop <= SmartReportingDetailPage.SCROLL_DOWN_THRESHOLD
      ) {
        this.scrollToBottom();
      }
    }, SmartReportingDetailPage.REFRESH_INTERVAL);
  }

  private createFeedbackForm() {
    this.feedbackForm = this.formBuilder.group({
      feedbackSolution: [null, Validators.required],
      feedbackProcess: [null, Validators.required],
    });

    this.feedbackForm.valueChanges.subscribe(async (value) => {
      if (value != null && value.feedbackProcess && value.feedbackSolution) {
        try {
          await this.smartReportingService.provideFeedback(this.notice, value);

          this.notice.feedbackProcess = value.feedbackProcess;
          this.notice.feedbackSolution = value.feedbackSolution;
        } catch (error) {
          console.error(error);

          this.toastService.show('conversation.feedback.failed');
        }
      }
    });
  }

  get canFeedback() {
    return (
      this.notice.status === SmartReportingNoticeStatus.CLOSED &&
      this.notice.own
    );
  }

  private createForm() {
    this.form = this.formBuilder.group({
      message: ['', Validators.required],
    });
  }

  private scrollToBottom() {
    this.scrollFromBottom(0);
  }

  private scrollFromBottom(amount: number) {
    setTimeout(async () => {
      const element = await this.scroll.getScrollElement();

      element.scrollTop = element.scrollHeight - amount;
    });
  }

  private async load(id: number) {
    this.apiInit.watch(async () => {
      try {
        this.notice = await this.smartReportingService.details(id);
        this.project = await this.projectService.getProject(
          this.notice.project.slug
        );
        this.updateResponses();
        this.setFeedback();
      } catch (error) {
        //ignore
      }

      this.defaultHref = this.app.isProjectApp()
        ? '/project'
        : '/projects/' + this.project.slug;

      this.setupRefreshTimer();

      this.changeDetectorRef.detectChanges();
    });
  }

  public async toggleSubscription() {
    if (this.loading) {
      return;
    }

    this.notice.subscribed = !this.notice.subscribed;

    try {
      if (!this.notice.subscribed) {
        await this.smartReportingService.unsubscribe(this.notice);
        this.notice.amountOfSubscribers--;
      } else {
        await this.smartReportingService.subscribe(this.notice);
        this.notice.amountOfSubscribers++;
      }

      this.smartReportingService.requestRedraw.emit(this.notice);
    } catch (error) {
      this.notice.subscribed = !this.notice.subscribed;
      console.log(error);

      this.toastService.show('smart_reporting.detail.subscription.failed');
    }
  }

  private updateResponses() {
    this.notice.responses
      .filter((it) => !it.isResponse)
      .reduce(
        (previousValue, currentValue) => [
          ...previousValue,
          currentValue,
          ...currentValue.responses,
        ],
        []
      )
      .map((response, index) => {
        let existingResponse = this.responses.find(
          (it) => it.id === response.id
        );
        if (!existingResponse) {
          this.responses.splice(index, 0, response);
        }
      });
  }

  public async textTranslated(event) {
    if (this.translated) {
      this.responses.forEach(
        async (r: SmartReportingNoticeResponse, index: number) => {
          r.message = this.originalMessages[index];
        }
      );
      this.translated = false;
      this.originalMessages = [];
      return;
    }

    this.responses.forEach(async (r: SmartReportingNoticeResponse) => {
      try {
        const translation = await this.translationService.getTranslation(
          r.message,
          this.translateService.getBrowserLang(),
          this.project.language
        );
        this.originalMessages.push(r.message);
        r.message = translation.text;
      } catch (error) {
        console.log(error);
      }
    });

    this.translated = true;
  }
}
