<ion-content *ngIf="project" class="app smart-reporting-detail">
  <div class="smart-reporting-detail__inner">
    <div class="smart-reporting-detail__head">
      <div class="back-button">
        <ion-back-button mode="md"></ion-back-button>
      </div>
      <div>
        <!-- Translation component here-->
      </div>
      <div *ngIf="step <= 3">
        <div class="smart-reporting-detail__head__text">
          <h1>{{ 'smart-reporting-detail.create' | translate }}</h1>
          <span>{{ project.name }}</span>
        </div>

        <div class="smart-reporting-detail__head__steps">
          <div
            [class.active]="step >= 1"
            [style.opacity]="step > 1 ? 0.5 : 1"
            (click)="goTo(1)"
            role="button"
            tabindex="0"
          >
            1
          </div>
          <div
            [class.active]="step >= 2"
            [style.opacity]="step > 2 ? 0.5 : 1"
            (click)="goTo(2)"
            role="button"
            tabindex="0"
          >
            2
          </div>
          <div
            [class.active]="step >= 3"
            (click)="goTo(3)"
            role="button"
            tabindex="0"
          >
            3
          </div>
        </div>
      </div>
    </div>

    <form [formGroup]="form">
      <div
        class="smart-reporting-detail__step"
        *ngIf="step === 1"
        formGroupName="step1"
      >
        <p>{{ 'smart_reporting.create.description' | translate }}</p>

        <div class="smart-reporting-detail__form location">
          <app-form-group [form]="form" errorPath="zip">
            <label for="zip">
              {{ 'smart_reporting.create.form.zip'| translate }}
            </label>
            <input formControlName="zip" id="zip" />
          </app-form-group>
          <app-form-group [form]="form" errorPath="city">
            <label for="city">
              {{ 'smart_reporting.create.form.city'| translate }}
            </label>
            <input formControlName="city" id="city" />
          </app-form-group>
          <app-form-group [form]="form" errorPath="street">
            <label for="street">
              {{ 'smart_reporting.create.form.street'| translate }}
            </label>
            <input formControlName="street" id="street" />
          </app-form-group>
          <app-form-group [form]="form" errorPath="houseNumber">
            <label for="houseNumber">
              {{ 'smart_reporting.create.form.house_number'| translate }}
            </label>
            <input formControlName="houseNumber" id="houseNumber" />
          </app-form-group>
        </div>

        <div class="smart-reporting-detail__form__notice" *ngIf="errorLocation">
          {{ 'smart_reporting.create.form.error_location'| translate }}
        </div>

        <app-smart-reporting-map
          [hasFilter]="false"
          [hasProjectMarker]="false"
          [project]="project"
          [userLocation]="userLocation"
          [userAccuracy]="userAccuracy"
          (mapLocationSelected)="determineFormFromLocation($event)"
          class="smart-reporting-detail__map"
          zoomPosition="top-right"
        ></app-smart-reporting-map>

        <button
          type="button"
          *ngIf="markerLocation && !errorLocation && showNext"
          (click)="next()"
          class="smart-reporting-detail__button smart-reporting-detail__button--fixed"
        >
          {{ 'smart_reporting.create.next' | translate }}
        </button>
      </div>

      <div
        class="smart-reporting-detail__step noDescription"
        *ngIf="step === 2 || step === 3"
      >
        <app-loader *ngIf="themes === null" [loading]="true"></app-loader>

        <div class="smart-reporting-detail__form">
          <ng-container *ngIf="themes !== null">
            <div formGroupName="step2" *ngIf="step === 2">
              <app-form-group [form]="form" errorPath="step2.theme">
                <label for="theme">
                  {{ 'smart_reporting.create.form.theme'| translate }}
                  <span class="required">*</span>
                </label>
                <select formControlName="theme" id="theme">
                  <option [ngValue]="null">
                    {{ 'smart_reporting.create.form.theme.placeholder'|
                    translate }}
                  </option>
                  <option *ngFor="let theme of themes" [ngValue]="theme">
                    {{ theme.title }}
                  </option>
                </select>
              </app-form-group>
              <app-form-group [form]="form" errorPath="step2.description">
                <label for="description">
                  {{ 'smart_reporting.create.form.description'| translate }}
                  <span class="required">*</span>
                </label>
                <textarea
                  rows="3"
                  formControlName="description"
                  id="description"
                ></textarea>
              </app-form-group>
              <app-form-group [form]="form" errorPath="step2.attachment">
                <label>
                  {{ 'smart_reporting.create.form.attachment'| translate }}
                </label>
                <app-file-input
                  (fileChanged)="setUploadedFile($event)"
                ></app-file-input>
              </app-form-group>
            </div>

            <div formGroupName="step3" *ngIf="step === 3">
              <app-form-group [form]="form" errorPath="step3.name">
                <label for="name">
                  {{ 'smart_reporting.create.form.name'| translate }}
                  <span class="required">*</span>
                </label>
                <input formControlName="name" id="name" />
              </app-form-group>
              <app-form-group [form]="form" errorPath="step3.phoneNumber">
                <label for="phoneNumber">
                  {{ 'smart_reporting.create.form.phoneNumber'| translate }}
                </label>
                <input
                  type="tel"
                  formControlName="phoneNumber"
                  id="phoneNumber"
                  inputmode="tel"
                />
              </app-form-group>
              <app-form-group [form]="form" errorPath="step3.email">
                <label for="email">
                  {{ 'smart_reporting.create.form.email'| translate }}
                  <span class="required">*</span>
                </label>
                <input
                  type="email"
                  formControlName="email"
                  id="email"
                  inputmode="email"
                />
              </app-form-group>
            </div>

            <button
              class="smart-reporting-detail__button"
              (click)="next()"
              [attr.disabled]="loading ? true : null"
            >
              <span [hidden]="loading"
                >{{ 'smart_reporting.create.form.submit' | translate }}</span
              >
              <app-loader color="light" [loading]="loading"></app-loader>
            </button>
          </ng-container>
        </div>
      </div>

      <div class="smart-reporting-detail__success" *ngIf="step === 4">
        <h1>{{'smart_reporting.create.success.title'|translate}}</h1>
        <p
          [innerHTML]="
                    (responseTime === 1 ? 'smart_reporting.create.success.info.day' : 'smart_reporting.create.success.info.days') | translate:{days: responseTime}
                    "
        ></p>

        <span [inlineSVG]="'/assets/illustrations/confetti.svg'"></span>

        <button class="smart-reporting-detail__button" (click)="close()">
          {{ 'smart_reporting.create.success.close'|translate }}
        </button>
      </div>
    </form>
  </div>
</ion-content>
