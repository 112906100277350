import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Project } from 'src/app/interfaces/project';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RatingResponse } from 'src/app/interfaces/rating-response';
import { RatingService } from 'src/app/services/rating.service';
import { LocationService } from 'src/app/services/location.service';
import * as moment from 'moment';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ApiInit } from 'src/app/utils/api-init';
import { ProjectService } from 'src/app/services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfettiService } from 'src/app/services/conffeti.service';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';
import { AnalyticsType } from 'src/app/enums/analytics-type';

@Component({
  selector: 'app-project-opinion',
  templateUrl: './project-opinion.page.html',
})
export class ProjectOpinionPage implements OnInit {
  public project: Project;
  public form: FormGroup;
  public canSubmit: boolean = false;
  public loading: boolean = false;
  public error: boolean = false;
  public sent: boolean = false;
  public invalid: boolean = false;
  public responses: RatingResponse[];
  public ratingId: number;

  // check if it is the first time the user fills a survey
  public firstTime: boolean = true;
  public repetitive: boolean = false; // second time a user has filled in a survey, a different message needs to be shown

  // when the user has already filled in a survey, it will be shown per-category, so keep track of the index
  public currentIndex: number = 0;
  defaultHref: string = '/';

  constructor(
    private fb: FormBuilder,
    private apiInit: ApiInit,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private ratingService: RatingService,
    private locationService: LocationService,
    private changeDetectorRef: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private confettiService: ConfettiService,
    private app: CurrentWhiteLabelApplication
  ) {
    this.route.params.subscribe((params) => {
      if (params.slug) {
        this.fetchProject(params.slug);
      } else {
        if (this.router.getCurrentNavigation()?.extras?.state) {
          this.fetchProject(
            this.router.getCurrentNavigation().extras.state.project.slug
          );
        }
      }
    });
  }

  public ngOnInit() {}

  private fetchProject(slug) {
    this.apiInit.watch(() => {
      this.projectService
        .getProject(slug)
        .then((result) => {
          this.project = result;
          this.defaultHref = this.app.isProjectApp()
            ? '/project'
            : '/projects/' + this.project.slug;
          this.createForm();
          this.loadResponses();
        })
        .catch(() => {});
    });
  }

  async submit() {
    if (this.form.valid) {
      try {
        this.loading = true;
        this.confettiService.canon();
        this.error = false;

        let location: GeolocationPosition | null = null;

        try {
          location = await this.locationService.getCurrentPosition();
        } catch (error) {
          // no location available, but continue
        }

        const ratingResponse: RatingResponse = {
          id: this.ratingId,
          project: this.project,
          items: this.form.get('themes').value,
          locationLat: location === null ? null : location.coords.latitude,
          locationLong: location === null ? null : location.coords.longitude,
        };

        if (this.ratingId) {
          await this.ratingService.putRate(ratingResponse, this.ratingId);
        } else {
          await this.ratingService.rate(ratingResponse);
        }

        this.ratingService.saved.next();

        this.sent = true;
        this.invalid = true;
        this.forceChange();

        ratingResponse.items.forEach((item) => {
          const theme: string = item.theme;
          const themeEntity = this.project.ratingThemes.find(
            (t) => t['@id'] === theme
          );

          this.analyticsService.logEvent(
            AnalyticsType.FEEDBACK_RATE,
            {
              question_name: themeEntity.title,
              rating: item.score,
              explanation: item.content ? item.content : '',
              question_id: item.id,
            },
            this.project
          );
        });
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    }
  }

  /**
   * Function to load any previous responses
   * @returns {Promise<void>}
   */
  async loadResponses() {
    this.responses = await this.ratingService.listByProject(this.project);
    this.firstTime = this.responses.length === 0;
    this.repetitive = this.responses.length > 1;

    if (this.firstTime) return;

    // a user can only rate again, when a rating has been pushed
    if (
      this.project.ratingPushedAt == null ||
      moment(this.responses[0].createdAt).isSameOrAfter(
        this.project.ratingPushedAt
      )
    ) {
      if (this.responses[0].items.length !== this.project.ratingThemes.length) {
        this.ratingId = this.responses[0].id;
        this.form
          .get('themes.0')
          .patchValue({ score: this.responses[0].items[0].score });
        return;
      }
    }
    this.sent = true;
    this.invalid = true;
  }

  /**
   * Go to the next page
   */
  next() {
    this.currentIndex += 1;
  }

  getThemeControl(index: number) {
    return (this.form.get('themes') as FormArray).controls[index];
  }

  /**
   * Create a form array
   */
  private createForm() {
    this.form = this.fb.group({
      themes: this.fb.array([]),
    });

    for (const theme of this.project.ratingThemes) {
      (<any>this.form.get('themes')).push(
        this.fb.group({
          score: [null, Validators.required],
          content: [null],
          theme: ['/api/rating-themes/' + theme.id],
        })
      );
    }

    this.form.valueChanges.subscribe(() => {
      this.canSubmit = this.form.valid;
      this.forceChange();
    });
  }

  private forceChange(): void {
    this.changeDetectorRef.detectChanges(); // fix for frozen UI after push
  }
}
